<template>
  <div>
    <basic-container>
      <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.resource.folder.title.indexHeadTitle')" @head-add="getNew" @head-romve="headRomve">
      </head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" v-model="searchForm"
                        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
      <avue-crud v-model="form"
                 class="folderBox"
                 :data-total="page.total"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @selection-change="selectionChange"
                 :option="option" :data="data" ref="crud"  @row-update="rowUpdate" @row-del="rowDel">
      </avue-crud>
    </basic-container>
  </div>
</template>


<script>

import {getList as getLazyList,remove,add,page} from "@/api/resource/folder";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import iconList from "@/config/iconList";
import {update} from "@/api/resource/oss";
import {mapGetters} from "vuex";

export default {
  data(){
    return{
      selectionList: [],
      searchColumns: [
        {
          label: "",
          prop: "folderName",
          span: 4,
          type:"input",
          search: true,
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.resource.folder.field.name"),
        },
        {
          label: "",
          prop: "folderCode",
          span: 4,
          type:"input",
          search: true,
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.resource.folder.field.code"),
        },

      ],
      data: [],
      option: {
        tip: false,
        columnBtn: false,
        addBtn: false,
        refreshBtn: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        menuWidth: 300,
        dialogClickModal: false,
        header:false,
        column: [
          {
            label: this.$t("cip.plat.resource.folder.field.name"),
            prop: 'folderName',
            width: 650,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.resource.folder.field.code"),
              prop: 'folderCode',
            align: "left",
            overHidden: true,
          },
        ]
      },

      loading: true,
      query: {},
      page:{
        current: 1,
        size: 20,
        total: 0
      },
    }
  },
  computed:{
    ...mapGetters(["userInfo", "permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.sms_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.sms_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-romve",
          type: "button",
          icon: ""
        });
      }
      return result
    },
  },
  mounted() {
    document.getElementsByClassName("avue-crud__menu").item(0).remove();
    this.onLoad( {});
    },
  methods:{
    rowDel(row, index, done) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.onLoad()
          // 数据回调进行刷新
          // done(row);
        });
    },
    rowUpdate(row, index, done, loading) {
      add(row).then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t('cip.cmn.msg.success.operateSuccess'),
        });
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    searchChange(params, done) {
      this.query = params;
      this.parentId = '0';
      this.onLoad( params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    onLoad( params = {}) {
      console.log(this.$refs.gridHeadLayout.searchForm.folderName)
      if(this.$refs.gridHeadLayout.searchForm.folderName || this.$refs.gridHeadLayout.searchForm.folderCode){
        page(this.page.current,this.page.size,params).then(res=>{
          this.data = res.data.data.records;
          this.loading = false;
          this.page.total = res.data.data.total
        })
      }else {
        getLazyList(Object.assign(params, this.query)).then(res => {
          this.data = res.data.data;
          this.loading = false;
        })
      }
    },
    searchReset() {
      this.query = {};
      this.parentId = 0;
      this.onLoad();
    },
    getNew() {
      console.log(1111)
      this.$router.push({
        path:'/folder/edit',
        query:{
          type:'add',
        }
      })
    },
    headRomve() {
      this.handleDelete()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .folderBox .el-button span {
  margin: 0 !important;
}
::v-deep .folderBox .cell .el-button {
  width: 24px;
}
::v-deep .folderBox .cell .el-button i {
  display: none;
}
</style>
