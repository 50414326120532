var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-container",
        [
          _c("avue-tree", {
            staticStyle: { "border-right": "1px solid #cccccc" },
            attrs: { option: _vm.treeOption, data: _vm.treeData },
            on: { "node-click": _vm.nodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.wfDesign.defaultValues.title.indexHeadTitle`
                  ),
                },
                on: {
                  "head-add": _vm.rowSave,
                  "head-delete": _vm.handleDelete,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.option,
                  "table-data": _vm.data,
                  "table-loading": _vm.loading,
                  "data-total": _vm.page.total,
                  "grid-row-btn": _vm.gridRowBtn,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "row-del": _vm.rowDel,
                  "row-edit": _vm.rowEdit,
                  "row-copy": _vm.handleCopy,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }