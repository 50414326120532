<template>
  <div class="SNOWS-common-layout">
    <div class="SNOWS-common-layout-center">
        <div class="SNOWS-common-head" style="text-align: right;display:flex;align-items:center;background-color:#fff;text-align: right;border-bottom: 1px solid rgb(204, 204, 204);">
          <el-col span="14" style="float: left;display:flex;align-items:center;height:28px">
            <avue-avatar shape="square" :style="{ backgroundColor: themeColor }" icon="el-icon-tickets"
                         size="small">
            </avue-avatar>
            <label class="headLabel" style="font-size: 14px;margin-left: 6px;">{{ '流程表单' }}</label>
          </el-col>
            <el-col>
          <el-badge>
            <el-button size="mini" @click="addForm(0,1)">{{ '新增' }}</el-button>
          </el-badge>
            </el-col>
        </div>

      <div>
        <el-row class="SNOWS-common-search-box" style="background-color: #FFFFFF;padding:14px 10px 0 10px;" :gutter="5">
        <el-form :model="query" @submit.native.prevent inline>
          <el-row>
            <el-col :span="4">
              <el-form-item>
                <el-input v-model="query.fullName" @keyup.enter.native="search()" placeholder="请输入表单名称查询" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <el-input v-model="query.enCode" @keyup.enter.native="search()" placeholder="请输入表单编码查询" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-button-group>
                  <el-button size="mini" type="primary" icon="el-icon-search" @click="search()"></el-button>
                  <el-button size="mini" icon="reset-refresh icon-refresh" @click="reset()"></el-button>
                </el-button-group>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
      </div>
      <div class="SNOWS-common-layout-main SNOWS-flex-main">
        <SNOWS-table v-loading="listLoading" :data="list">
          <el-table-column align="center" prop="fullName" label="表单名称"/>
          <el-table-column align="center" prop="enCode" label="表单编码"/>
          <el-table-column align="center" prop="formType" label="表单类型">
            <template slot-scope="scope">
              <span>{{ scope.row.formType == 2 ? "自定义表单" : (scope.row.flowType == 1 ? "功能表单" : "系统表单") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.enabledMark == 1 ? 'success' : 'info'" disable-transitions>
                {{ scope.row.enabledMark == 1 ? '已发布' : '未发布' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="creatorUserId" label="创建人" width="170"/>
          <el-table-column align="center" prop="creatorTime" label="创建时间" :formatter="snows.tableDateFormat"
                           width="170"/>
          <el-table-column align="center" label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <tableOpts @edit="addOrUpdateHandle(scope.row.id,scope.row.formType,0)"
                         @del="handleDel(scope.row.id)">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-button type="text" size="mini">{{ '更多' }}<i
                      class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="releaseForm(scope.row.id)">
                      发布表单
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="rollBackForm(scope.row.id)"
                                      v-if="scope.row.enabledMark">
                      回滚表单
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="copy(scope.row.id)">
                      复制表单
                    </el-dropdown-item>
<!--                    <el-dropdown-item @click.native="exportModel(scope.row.id)">
                      导出表单
                    </el-dropdown-item>-->
<!--                    <el-dropdown-item @click.native="preview(scope.row,'propertyJson')"
                                      v-if="scope.row.enabledMark == 1">
                      预览表单
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="preview(scope.row,'draftJson')">
                      预览草稿
                    </el-dropdown-item>-->
                  </el-dropdown-menu>
                </el-dropdown>
              </tableOpts>
            </template>
          </el-table-column>
        </SNOWS-table>
        <pagination :total="total" :page.sync="listQuery.currentPage"
                    :limit.sync="listQuery.pageSize" @pagination="initData"/>
      </div>
    </div>
    <Form v-if="formVisible" ref="Form" @close="closeForm"/>
    <preview v-if="previewVisible" ref="preview" @close="previewVisible=false"/>
    <previewDialog :visible.sync="previewDialogVisible" :id="currRow.id" type="flow"
                   @previewPc="previewPc" :dataSource="currRow.dataSource"/>
  </div>
</template>

<script>
import Form from './Form'
import preview from './Preview'
import previewDialog from '@/components/PreviewDialog'
import {getFormList, release, del, copyForm, exportData} from '@/api/workFlow/FormDesign'
import {mapGetters} from "vuex";

export default {
  name: 'workFlow-flowEngine',
  components: {Form, preview, previewDialog},
  data() {
    return {
      query: {
        keyword: '',
        fullName:'',
        enCode:''
      },
      downloadFormVisible: false,
      sort: 'flowForm',
      dialogVisible: false,
      previewVisible: false,
      previewDialogVisible: false,
      list: [],
      listQuery: {
        currentPage: 1,
        pageSize: 10,
        sort: 'desc',
        sidx: ''
      },
      total: 0,
      listLoading: false,
      formVisible: false,
      addVisible: false,
      currRow: {},
      categoryList: []
    }
  },
  created() {
    this.initData()
  },
  computed: {
    ...mapGetters(["themeColor"])
  },
  watch:{
    themeColor(val){
      this.themeColor = val
    }
  },
  methods: {
    search() {
      this.listQuery = {
        currentPage: 1,
        pageSize: 10
      }
      this.initData()
    },
    reset() {
      this.query.keyword = ''
      this.query.fullName = ''
      this.query.enCode = ''
      this.search()
    },
    initData() {
      this.listLoading = true
      let query = {
        ...this.listQuery,
        ...this.query
      }
      getFormList(this.listQuery.currentPage,this.listQuery.pageSize,query).then(res => {
        console.log(res)
        this.list = res.data.data.records
        this.total = res.data.data.total
        // this.total = res.data.pagination.total
        this.listLoading = false
      })
    },
    handleDel(id) {
      this.$confirm(this.$t('common.delTip'), this.$t('common.tipTitle'), {
        type: 'warning'
      }).then(() => {
        del(id).then(res => {
          this.$message({
            type: 'success',
            message: res.data.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    },
    copy(id) {
      this.$confirm('您确定要复制该功能表单, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        copyForm(id).then(res => {
          this.$message({
            type: 'success',
            message: res.data.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    },
    exportModel(id) {
      this.$confirm('您确定要导出该功能表单, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        exportData(id).then(res => {
          this.snows.downloadFile(res.data.url)
        })
      }).catch(() => {
      });
    },
    handleAdd(webType) {
      this.addOrUpdateHandle('', webType)
    },
    closeForm(isRefresh) {
      this.formVisible = false
      if (isRefresh) {
        this.initData()
      }
    },
    addForm(flowType, formType) {
      this.dialogVisible = false
      this.addOrUpdateHandle('', flowType, formType)
    },
    // 新增 / 修改
    addOrUpdateHandle(id, flowType, formType) {
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.Form.init(id, flowType, formType)
      })
    },
    preview(row, dataSource) {
      this.currRow = row
      this.currRow.dataSource = dataSource
      this.$nextTick(() => {
        this.previewDialogVisible = true
      })
    },
    previewPc() {
      let data = {
        enCode: this.currRow.enCode,
        fullName: this.currRow.fullName,
        formType: this.currRow.formType,
        formId: this.currRow.id,
        dataSource: this.currRow.dataSource,
      }
      this.previewVisible = true
      this.$nextTick(() => {
        this.$refs.preview.init(data)
      })
    },
    enabledMark(id, enabledMark) {
      let str = enabledMark === 1 ? "表单停用" : "表单启用"
      this.$confirm(str + '?', '提示', {
        type: 'warning'
      }).then(() => {
        EnabledMark(id).then(res => {
          this.$message({
            type: 'success',
            message: res.data.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    },
    releaseForm(id) {
      this.$confirm('发布表单会覆盖当前线上版本，是否继续？', '发布确认', {
        type: 'warning'
      }).then(() => {
        release(id, 1).then(res => {
          this.$message({
            type: 'success',
            message: res.data.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    },
    rollBackForm(id) {
      this.$confirm('此操作将当前编辑的表单内容回滚为已经发布的表单内容，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        release(id, 0).then(res => {
          this.$message({
            type: 'success',
            message: res.data.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.add-dialog {
  ::v-deep .el-dialog__header {
    display: none !important;
  }

  ::v-deep .el-dialog__body {
    padding: 40px 30px !important;
  }
}

.add-main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .add-main-part {
    border: 1px solid #dcdfe6;
    padding: 20px;
    border-radius: 4px;
  }

  .add-main-cap {
    line-height: 25px;
    font-size: 18px;
    color: #303133;
    margin-bottom: 14px;
    font-weight: 400;
  }

  .add-main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .add-main-left {
    width: 530px;

    .add-item {
      background: #f1f5ff;

      .add-icon {
        background: #ccd9ff;
        color: #537eff;
      }
    }
  }

  .add-item {
    width: 235px;
    height: 120px;
    background: #fef3e6;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding-left: 12px;

    .add-icon {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      background: #fce1bf;
      border-radius: 6px;
      color: #ea986c;
      flex-shrink: 0;
      font-size: 30px;
      line-height: 48px;
      text-align: center;
    }

    .add-txt {
      height: 48px;

      P {
        line-height: 28px;
      }

      .add-title {
        font-size: 18px;
        color: #606266;
      }

      .add-desc {
        color: #8d8989;
        font-size: 12px;
      }
    }
  }
}
</style>
