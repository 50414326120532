var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "avue-form",
        {
          ref: "resultsForm",
          attrs: { option: _vm.formOptions },
          model: {
            value: _vm.resultsForm,
            callback: function ($$v) {
              _vm.resultsForm = $$v
            },
            expression: "resultsForm",
          },
        },
        [
          _c(
            "template",
            { slot: "fileList" },
            [
              _c(
                "el-upload",
                {
                  class: { uploadhideAdd: _vm.pageDisabled },
                  attrs: {
                    disabled: _vm.pageDisabled,
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                    "list-type": "picture-card",
                    accept: ".jpg,.png,.mp4",
                    limit: 9,
                    placeholder: "请上传图片视频",
                    "file-list": _vm.resultsForm.fileList,
                    headers: _vm.headers,
                    "on-success": _vm.uploadSuccess,
                    "on-remove": _vm.handleRemove,
                    "on-preview": _vm.handlePreview,
                    "on-exceed": _vm.uploadExceed,
                    "on-change": _vm.uploadChange,
                    "before-upload": _vm.uploadBefore,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "\n          图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "signFileUrl" },
            [
              _c(
                "el-upload",
                {
                  class: {
                    uoloadSty: _vm.showImg,
                    disUoloadSty: _vm.noneUploadFile,
                  },
                  attrs: {
                    disabled: _vm.pageDisabled,
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                    accept: ".zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx",
                    limit: 1,
                    "file-list": _vm.signFileUrl,
                    headers: _vm.headers,
                    "on-success": (response, file, fileList) => {
                      return _vm.uploadFileSuccess(
                        response,
                        file,
                        fileList,
                        "signFileUrl"
                      )
                    },
                    "on-remove": (file, fileList) => {
                      return _vm.handleFileRemove(file, fileList, "signFileUrl")
                    },
                    "on-exceed": _vm.uploadExceed,
                    "on-preview": _vm.handleFilePreview,
                    "before-upload": _vm.handleBeforeUpload,
                  },
                },
                [
                  _c("el-button", { attrs: { size: "small" } }, [
                    _c("i", { staticClass: "el-icon-upload2" }),
                    _vm._v(" 上传文件"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "\n          支持扩展名: .zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "rateFileUrl" },
            [
              _c(
                "el-upload",
                {
                  class: {
                    uoloadSty: _vm.showImg,
                    disUoloadSty: _vm.noneUploadFile,
                  },
                  attrs: {
                    disabled: _vm.pageDisabled,
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                    accept: ".zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx",
                    limit: 1,
                    "file-list": _vm.rateFileUrl,
                    headers: _vm.headers,
                    "on-success": (response, file, fileList) => {
                      return _vm.uploadFileSuccess(
                        response,
                        file,
                        fileList,
                        "rateFileUrl"
                      )
                    },
                    "on-remove": (file, fileList) => {
                      return _vm.handleFileRemove(file, fileList, "rateFileUrl")
                    },
                    "on-exceed": _vm.uploadExceed,
                    "on-preview": _vm.handleFilePreview,
                    "before-upload": _vm.handleBeforeUpload,
                  },
                },
                [
                  _c("el-button", { attrs: { size: "small" } }, [
                    _c("i", { staticClass: "el-icon-upload2" }),
                    _vm._v(" 上传文件"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "\n          支持扩展名: .zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "预览",
                visible: _vm.dialogVisible,
                "append-to-body": "",
                top: "5vh",
                "custom-class": "customDialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _vm.uploadType == "img"
                ? _c("img", {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "object-fit": "contain",
                    },
                    attrs: { src: _vm.dialogImageUrl, alt: "" },
                  })
                : _vm._e(),
              _vm.uploadType == "video"
                ? _c("video", {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "object-fit": "contain",
                    },
                    attrs: {
                      autoplay: "",
                      src: _vm.dialogVideoUrl,
                      controls: "controls",
                    },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }