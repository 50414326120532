<template>
  <!-- 处置措施 -->
  <div style="position: relative">
    <div>
      <head-layout
        head-title=""
        :showIcon="false"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-delete="handleDelete"
      ></head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="currentChange"
        @page-size-change="sizeChange"
      >
        <template #customBtn="{ row }" v-if="!this.pageDisabled">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            >移除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <measuresDialog ref="measuresDialog"></measuresDialog>
  </div>
</template>
      
      <script>
import {
  erexercisetreatmentPage,
  erexercisetreatmentRemove,
} from "@/api/contingency/emergencyDrill";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import measuresDialog from "@/views/business/contingency/emergencyDrill/components/measuresDialog";
export default {
  components: {
    GridLayout,
    HeadLayout,
    measuresDialog,
  },
  data() {
    return {
      form: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableData: [],
      exeId: "", // 计划id
      pageDisabled: false, // 默认不禁用  当查看页面点击进入或者提交后则禁用
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (!this.pageDisabled) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "移除",
          emit: "head-delete",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOption() {
      let obj = {};
      obj.column = [
        {
          label: "序号",
          prop: "seqNum",
          align: "center",
          width: 200,
        },
        {
          label: "措施描述",
          prop: "treatmentDesc",
          align: "center",
          overHidden:true
        },
      ];
      if (!this.pageDisabled) {
        obj.selection = true;
        obj.menu = true;
      } else {
        obj.selection = false;
        obj.menu = false;
      }
      return obj;
    },
  },
  mounted() {},
  methods: {
    // 初始化该组件
    init(id,pageDisabled) {
      this.exeId = id;
      this.pageDisabled = pageDisabled;
      this.onLoad(this.page, { exeId: this.exeId });
    },
    //   新增
    handleAdd() {
      this.$refs.measuresDialog.init("add", { exeId: this.exeId });
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    // 全局删除
    handleDelete() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erexercisetreatmentRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page, { exeId: this.exeId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 编辑
    rowEdit(row) {
      this.$refs.measuresDialog.init("edit", { id: row.id });
    },
    // 单个删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erexercisetreatmentRemove(row.id);
        })
        .then(() => {
          this.onLoad(this.page, { exeId: this.exeId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 页码--第几页
    currentChange(val) {
      this.page.currentPage = val.currentPage;
      this.onLoad(this.page, { exeId: this.exeId });
    },
    // 页码--多少页
    sizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.onLoad(this.page, { exeId: this.exeId });
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      erexercisetreatmentPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {})
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
  },
};
</script>
      
      <style>
</style>
      