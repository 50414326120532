var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t(
            `cip.plat.message.sendRecord.title.addHeadTitle`
          ),
        },
        on: {
          "head-cancel": _vm.headCancel,
          "head-save": function ($event) {
            return _vm.headSave()
          },
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }