var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "host",
                    prop: "host",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t("cip.plat.message.channel.msg.host"),
                      "show-word-limit": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.host,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "host", $$v)
                      },
                      expression: "formData.host",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "user",
                    prop: "user",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t("cip.plat.message.channel.msg.user"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.user,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "user", $$v)
                      },
                      expression: "formData.user",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "from",
                    prop: "from",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t("cip.plat.message.channel.msg.from"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.from,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "from", $$v)
                      },
                      expression: "formData.from",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "pass",
                    prop: "pass",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t("cip.plat.message.channel.msg.pass"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.pass,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "pass", $$v)
                      },
                      expression: "formData.pass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }