var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: _vm.eventType === "audit" ? "审批通过" : "审批退回",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        "append-to-body": "",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            "label-width":
              _vm.dataForm.candidateList.length || _vm.branchList.length
                ? "130px"
                : "80px",
          },
        },
        [
          _vm.eventType === "audit"
            ? [
                _vm.branchList.length
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "分支选择",
                          prop: "branchList",
                          rules: [
                            {
                              required: true,
                              message: `分支不能为空`,
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: "请选择审批分支",
                              clearable: "",
                            },
                            on: { change: _vm.onBranchChange },
                            model: {
                              value: _vm.dataForm.branchList,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "branchList", $$v)
                              },
                              expression: "dataForm.branchList",
                            },
                          },
                          _vm._l(_vm.branchList, function (item) {
                            return _c("el-option", {
                              key: item.nodeId,
                              attrs: {
                                label: item.nodeName,
                                value: item.nodeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.dataForm.candidateList, function (item, i) {
                  return _c(
                    "el-form-item",
                    {
                      key: i,
                      attrs: {
                        label: item.nodeName + item.label,
                        prop: "candidateList." + i + ".value",
                        rules: item.rules,
                      },
                    },
                    [
                      item.hasCandidates
                        ? _c("candidate-user-select", {
                            attrs: {
                              multiple: "",
                              placeholder: "请选择" + item.label,
                              taskId: _vm.taskId,
                              formData: _vm.formData,
                              nodeId: item.nodeId,
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : _c("user-select", {
                            attrs: {
                              multiple: "",
                              placeholder: "请选择" + item.label,
                              title: "候选人员",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                    ],
                    1
                  )
                }),
              ]
            : _vm._e(),
          _vm.properties &&
          _vm.properties.rejectType &&
          _vm.eventType !== "audit" &&
          _vm.showReject
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "退回节点", prop: "rejectStep" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择退回节点",
                          disabled: _vm.properties.rejectStep !== "2",
                        },
                        model: {
                          value: _vm.dataForm.rejectStep,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "rejectStep", $$v)
                          },
                          expression: "dataForm.rejectStep",
                        },
                      },
                      _vm._l(_vm.rejectList, function (item) {
                        return _c("el-option", {
                          key: item.nodeCode,
                          attrs: { label: item.nodeName, value: item.nodeCode },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.properties && _vm.properties.isCustomCopy
            ? _c(
                "el-form-item",
                { attrs: { label: "抄送人员", prop: "copyIds" } },
                [
                  _c("user-select", {
                    attrs: { placeholder: "请选择", multiple: "" },
                    model: {
                      value: _vm.copyIds,
                      callback: function ($$v) {
                        _vm.copyIds = $$v
                      },
                      expression: "copyIds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.properties && _vm.properties.hasOpinion
            ? _c(
                "el-form-item",
                { attrs: { label: "审批意见", prop: "handleOpinion" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入审批意见",
                      type: "textarea",
                      rows: 4,
                    },
                    model: {
                      value: _vm.dataForm.handleOpinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "handleOpinion", $$v)
                      },
                      expression: "dataForm.handleOpinion",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.properties && _vm.properties.hasOpinion
            ? _c(
                "el-form-item",
                { attrs: { label: "审批附件", prop: "fileList" } },
                [
                  _c("SNOWS-UploadFz", {
                    attrs: { limit: 3 },
                    model: {
                      value: _vm.dataForm.fileList,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "fileList", $$v)
                      },
                      expression: "dataForm.fileList",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.properties && _vm.properties.hasSign
            ? _c(
                "el-form-item",
                { attrs: { label: "审批签名", required: "" } },
                [
                  _c("div", { staticClass: "sign-main" }, [
                    _vm.signImg
                      ? _c("img", {
                          staticClass: "sign-img",
                          attrs: { src: _vm.signImg, alt: "" },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "sign-style", on: { click: _vm.addSign } },
                      [
                        _c("i", {
                          staticClass: "icon-ym icon-ym-signature add-sign",
                        }),
                        !_vm.signImg
                          ? _c("span", { staticClass: "sign-title" }, [
                              _vm._v("手写签名"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.handleApproval()
                },
              },
            },
            [_vm._v("\n      确定\n    ")]
          ),
        ],
        1
      ),
      _vm.signVisible
        ? _c("SignImgDialog", {
            ref: "SignImg",
            attrs: { lineWidth: 3, userInfo: _vm.userInfo, isDefault: 1 },
            on: { close: _vm.signDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }