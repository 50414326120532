<template>
  <div style="position: relative">
    <avue-form :option="formOptions" ref="resultsForm" v-model="resultsForm">
      <template slot="fileList">
        <el-upload
          :disabled="pageDisabled"
          :action="'/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform'"
          list-type="picture-card"
          accept=".jpg,.png,.mp4"
          :limit="9"
          placeholder="请上传图片视频"
          :file-list="resultsForm.fileList"
          :headers="headers"
          :on-success="uploadSuccess"
          :on-remove="handleRemove"
          :on-preview="handlePreview"
          :on-exceed="uploadExceed"
          :on-change="uploadChange"
          :before-upload="uploadBefore"
          :class="{ uploadhideAdd: pageDisabled }"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" class="el-upload__tip">
            图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M
          </div>
        </el-upload>
      </template>
      <template slot="signFileUrl">
        <el-upload
          :disabled="pageDisabled"
          :class="{ uoloadSty: showImg, disUoloadSty: noneUploadFile }"
          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
          accept=".zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx"
          :limit="1"
          :file-list="signFileUrl"
          :headers="headers"
          :on-success="
            (response, file, fileList) => {
              return uploadFileSuccess(response, file, fileList, 'signFileUrl');
            }
          "
          :on-remove="
            (file, fileList) => {
              return handleFileRemove(file, fileList, 'signFileUrl');
            }
          "
          :on-exceed="uploadExceed"
          :on-preview="handleFilePreview"
          :before-upload="handleBeforeUpload"
        >
          <el-button size="small"
            ><i class="el-icon-upload2"></i> 上传文件</el-button
          >
          <div slot="tip" class="el-upload__tip">
            支持扩展名: .zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx
          </div>
        </el-upload>
      </template>
      <template slot="rateFileUrl">
        <el-upload
          :disabled="pageDisabled"
          :class="{ uoloadSty: showImg, disUoloadSty: noneUploadFile }"
          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
          accept=".zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx"
          :limit="1"
          :file-list="rateFileUrl"
          :headers="headers"
          :on-success="
            (response, file, fileList) => {
              return uploadFileSuccess(response, file, fileList, 'rateFileUrl');
            }
          "
          :on-remove="
            (file, fileList) => {
              return handleFileRemove(file, fileList, 'rateFileUrl');
            }
          "
          :on-exceed="uploadExceed"
          :on-preview="handleFilePreview"
          :before-upload="handleBeforeUpload"
        >
          <el-button size="small"
            ><i class="el-icon-upload2"></i> 上传文件</el-button
          >
          <div slot="tip" class="el-upload__tip">
            支持扩展名: .zip,.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx
          </div>
        </el-upload>
      </template>
    </avue-form>
    <el-dialog
      title="预览"
      :visible.sync="dialogVisible"
      append-to-body
      top="5vh"
      custom-class="customDialog"
      v-if="dialogVisible"
    >
      <img
        style="width: 100%; height: 100%; object-fit: contain"
        :src="dialogImageUrl"
        alt=""
        v-if="uploadType == 'img'"
      />
      <video
        style="width: 100%; height: 100%; object-fit: contain"
        autoplay
        :src="dialogVideoUrl"
        v-if="uploadType == 'video'"
        controls="controls"
      ></video>
    </el-dialog>
      <fileView ref="fileView" title="附件预览"></fileView>
  </div>
</template>
<script>
import { getToken } from "@/util/auth";
import { erexerciseresultGetDetail } from "@/api/contingency/emergencyDrill";
import website from "@/config/website";
export default {
  components: {},
  data() {
    return {
      resultsForm: {
        fileList: [],
      },
      dialogVisible: false,
      dialogImageUrl: "", // 预览图片路径
      dialogVideoUrl: "", // 预览视频路径
      uploadType: "img", // 默认是图片 img video 是视频的查看方式
      propsHttp: {
        url: "link",
        name: "name",
        res: "data",
      },
      exeId: "", // 演练id
      pageDisabled: false, // 默认不禁用  当查看页面点击进入或者提交后则禁用
      erStatus: "PREPARE", // 默认草稿
      signFileUrl: [],
      rateFileUrl: [],
      noneUploadFile: false
    };
  },
  computed: {
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "图片视频",
            prop: "fileList",
            span: 24,
            formslot: true,
            type: "upload",
            headers: this.headers,
            listType: "picture-card",
            accept: "image/png, image/jpg, video/mp4",
            limit: 9,
            propsHttp: {
              res: "data",
            },
            action:
              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
            tip: "图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M",
            placeholder: "请上传图片视频",
            rules: [
              {
                required: true,
                message: "请上传图片视频",
                trigger: "change",
              },
            ],
          },
          {
            label: "演练总结",
            prop: "exeSummary",
            span: 24,
            type: "textarea",
            disabled: this.pageDisabled,
            placeholder: "请输入演练总结描述",
            rules: [
              {
                required: true,
                message: "请输入演练总结描述",
                trigger: "blur",
              },
              {
                max: 250,
                message: "演练总结不能超过250个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "存在问题",
            prop: "problemDesc",
            span: 24,
            disabled: this.pageDisabled,
            type: "textarea",
            placeholder: "如存在，请输入存在问题描述",
            rules: [
              {
                max: 250,
                message: "存在问题不能超过250个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "修改措施",
            prop: "treatmentDesc",
            span: 24,
            disabled: this.pageDisabled,
            type: "textarea",
            placeholder: "请输入问题的修改措施",
            rules: [
              {
                max: 250,
                message: "修改措施不能超过250个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "人员签到表",
            prop: "signFileUrl",
            formSlot: true
          },
          {
            label: "演练评估表",
            prop: "rateFileUrl",
            formSlot: true
          },
        ],
      };
    },
  },
  methods: {
    // 初始化新增/编辑/查看页面
    init(id, pageDisabled, erStatus) {
      this.exeId = id;
      this.erStatus = erStatus;
      let type = this.$route.query.type;
      if (type == "view" || this.erStatus == "FINISHED") {
        // 如果是查看或者全程全部禁用
        this.pageDisabled = true; // 禁用
        this.noneUploadFile = true
      } else {
        this.pageDisabled = false;
      }
      if (this.erStatus == "FINISHED") {
        this.getDetails();
      }
    },
    // 获取详情
    getDetails() {
      erexerciseresultGetDetail(this.exeId).then((res) => {
        this.resultsForm = res.data.data;
        this.exeId = this.resultsForm.exeId;
        if (res.data.data.files) {
          let files = JSON.parse(res.data.data.files);
          this.setFileList(files);
        }
        if(res.data.data.signFileUrl){
          this.signFileUrl = JSON.parse(res.data.data.signFileUrl)
        }
        if(res.data.data.rateFileUrl){
          this.rateFileUrl = JSON.parse(res.data.data.rateFileUrl)
        }
      });
    },
    /**
     * 查看详情时附件回显
     **/
    setFileList(_fileList) {
      let _self = this;
      _self.resultsForm.fileList = [];
      for (let obj of _fileList) {
        //视频附件，获取第一帧画面作为 封面展示
        obj.url = obj.link;
        if (_self.isJudgeVideo(obj)) {
          //视频附件，获取第一帧画面作为 封面展示
          _self.getVideoCover(obj);
        }
        _self.resultsForm.fileList.push(obj);
      }
      // _self.resultsForm.fileList = _fileList; //fileList 为 Element file-list 参数值
    },
    // 判断文件是否为video
    isJudgeVideo(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "mp4" || fileName == "MP4") {
        //视频附件，获取第一帧画面作为 封面展示
        return true;
      } else {
        return false;
      }
    },
    /**
     * 获取视频第一帧作为回显封面
     */
    getVideoCover(file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      file.url = require("@/assets/images/videoLoading.gif"); //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      let _self = this;
      _self.resultsForm.fileList = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          return item.response.data;
        } else {
          return item;
        }
      });
      let data = response.data;
      data.url = response.data.link;
      if (_self.isJudgeVideo(data)) {
        //视频附件，获取第一帧画面作为 封面展示
        _self.getVideoCover(data);
      }
    },
    // 上传失败回调
    handleError() {},
    // 文件预览
    handlePreview(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "mp4" ||
        fileName == "MP4"
      ) {
        this.dialogVisible = true;
        // 如果是视频
        if (fileName == "mp4" || fileName == "MP4") {
          this.uploadType = "video";
          this.dialogVideoUrl = file.link;
        } else {
          this.uploadType = "img";
          this.dialogImageUrl = file.link;
        }
      }
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.resultsForm.fileList = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      if (fileList.length == 0) {
        this.$refs.resultsForm.validateField("fileList");
      }
    },
    // 文件上传超出限制回调
    uploadExceed(files, fileList) {
      this.$message.error("超出上传限制文件数量");
    },
    // 文件上传前的回调
    uploadBefore(file) {
      const isJPG = file.type === "image/jpg" || "image/png" || "video/mp4";
      let maxNum = file.type == "video/mp4" ? 20 : 5;
      const isLt2M = file.size / 1024 / 1024 < maxNum;

      if (!isJPG) {
        this.$message.error("图片仅支持*.jpg/*.png格式;视频仅支持*MP4格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不超过5M;视频大小不超过20M!");
      }
      return isJPG && isLt2M;
    },
    // 文件改变时候修改提示信息
    uploadChange(uploadFile, fileList) {
      if (fileList.length !== 0) {
        this.$refs.resultsForm.validateField("fileList");
      }
    },
    // 上传成功
    uploadFileSuccess(response, file, fileList, fieldName) {
      // fieldName 表单中向后端传递的字段名
      this[fieldName] = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          item.response.data.name = item.response.data.originalName
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    // 删除文件
    handleFileRemove(file, fileList, fieldName) {
      this[fieldName] = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    handleFilePreview(item) {
      this.$refs.fileView.showFile(item.link)
    },
  },
};
</script>
<style>
.customDialog > .el-dialog__body {
  height: 85vh;
}
</style>
<style scoped lang="scss">
::v-deep.uploadhideAdd .el-upload--picture-card {
  display: none;
}
.disUoloadSty {
  ::v-deep .el-upload--picture-card{
    display: none;
  }
  ::v-deep .el-upload__tip{
    display: none;
  }
  ::v-deep .el-upload--text{
    display: none;
  }
}
</style>
