<template>
  <div class="center">
    <el-container>
      <wf-category @node-click="nodeClick"
                   @list-change="findObject(option.column, 'categoryId').dicData = $event"></wf-category>
      <el-main>
        <!--列表label-->
        <head-layout
          head-title="模型历史"
          :head-btn-options="headBtnOptions"
          @head-delete="handleDelete"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>

        <grid-layout
          ref="gridLayOut"
          :table-options="option"
          :table-data="data"
          :table-loading="loading"
          :data-total="page.total"
          :grid-row-btn="gridRowBtn"
          :page="page"
          @row-delete="rowDelete"
          @row-view="handlePreview"
          @row-main="handleMain"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        ></grid-layout>
      </el-main>
    </el-container>

    <el-dialog :visible.sync="viewVisible"
               :title="$t(`cip.plat.wfDesign.model.title.viewIconHeadTitle`)"
               destroy-on-close
               append-to-body>
      <wf-design ref="bpmn"
                 style="height: 500px;"
                 :options="viewOption"></wf-design>
    </el-dialog>
  </div>
</template>

<script>
import {getList, remove, setMainVersion} from "@/api/plugin/workflow/model-history";

import {mapGetters} from "vuex";

import WfCategory from '../process/components/category.vue'

export default {
  components: {WfCategory},
  data() {
    return {
      formVisible: false,
      options: {},
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      searchColumns: [{
        label: this.$t(`cip.plat.wfDesign.model.field.modelKey`),
        prop: "modelKey",
        span: 4,
        maxlength: 20,
        minlength: 2,
        placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.wfDesign.model.field.modelKey`),
      },
        {
          label: this.$t(`cip.plat.wfDesign.model.field.name`),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.wfDesign.model.field.name`),
        }],
      headBtnOptions: [{
        label: '删除',
        emit: "head-delete",
      }],
      option: {
        menuWidth: 200,
        index: true,
        indexLabel: '序号',
        column: [
          {
            label: this.$t(`cip.plat.wfDesign.model.field.modelKey`),
            prop: "modelKey",
            overHidden: true,
            align: "center",
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.name`),
            prop: "name",
            overHidden: true,
            align: "center",
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.categoryId`),
            prop: 'categoryId',
            type: 'tree',
            align: "center",
            props: {
              label: 'name',
              value: 'id'
            },
            dicData: [],
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`) + this.$t(`cip.plat.wfDesign.model.field.categoryId`),
              trigger: "change"
            }],
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.description`),
            prop: "description",
            overHidden: true,
            align: "center",
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.version`),
            prop: "version",
            width: 80,
            align: "center",
          },
        ]
      },
      data: [],
      viewVisible: false,
      viewOption: {},
    };
  },
  watch: {
    '$route.params.id': {
      handler(val) {
        this.modelId = val
        this.onLoad(this.page, this.query)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["permission", "tag", "language"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    gridRowBtn() {
      let result = [];
      if (this.permission.wf_design_form_history_delete) {
        result.push({
          label: '删除',
          emit: "row-delete",
        });
      }
      if (this.permission.wf_design_form_history_view) {
        result.push({
          label: '查看',
          emit: "row-view",
        });
      }
      if (this.permission.wf_design_form_history_main) {
        result.push({
          label: '设为主版本',
          emit: "row-main",
        });
      }
      return result;
    }
  },
  methods: {
    nodeClick({id}) {
      this.categoryId = id
      this.searchChange(this.query)
    },
    gridHeadSearch(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    handleMain(row) {
      this.$confirm(this.$t(`cip.plat.wfDesign.model.msg.main`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        setMainVersion({id: row.id}).then(() => {
          this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
          this.$store.commit('DEL_TAG', this.tag)
          this.$router.push("/plugin/workflow/design/model")
        })
      })
    },
    handlePreview(row) {
      this.viewOption = {
        mode: 'view',
        xml: row.xml,
        lang: this.language,
      }
      this.viewVisible = true
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
        });
    },
    rowDelete(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      if (done) done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
      this.onLoad(this.page, this.query);
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      params.modelId = this.modelId

      if (this.categoryId) params['categoryId'] = this.categoryId
      else delete params['categoryId']

      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .bjs-powered-by {
  display: none;
}
</style>
