import { render, staticRenderFns } from "./SingleImg.vue?vue&type=template&id=7a15ae4a&scoped=true"
import script from "./SingleImg.vue?vue&type=script&lang=js"
export * from "./SingleImg.vue?vue&type=script&lang=js"
import style0 from "./SingleImg.vue?vue&type=style&index=0&id=7a15ae4a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a15ae4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a15ae4a')) {
      api.createRecord('7a15ae4a', component.options)
    } else {
      api.reload('7a15ae4a', component.options)
    }
    module.hot.accept("./SingleImg.vue?vue&type=template&id=7a15ae4a&scoped=true", function () {
      api.rerender('7a15ae4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SingleImg.vue"
export default component.exports