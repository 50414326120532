var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.plat.monitor.log.api.title.indexHeadTitle"),
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }