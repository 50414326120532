var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appIndexWindowShow
    ? _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          staticClass: "avue-dialog avue-dialog--top",
          attrs: {
            title:
              _vm.$t(`cip.index.atomIndex.title`) +
              _vm.$t(`cip.index.theme.list`),
            visible: _vm.appIndexWindowShow,
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.appIndexWindowShow = $event
            },
          },
        },
        [
          _c("AppIndexPage", {
            ref: "appIndexPage",
            attrs: { dataFormExt: _vm.dataFormExt },
            on: {
              "update:dataFormExt": function ($event) {
                _vm.dataFormExt = $event
              },
              "update:data-form-ext": function ($event) {
                _vm.dataFormExt = $event
              },
            },
          }),
          _c(
            "div",
            { staticClass: "avue-dialog__footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.appIndexWindowShow = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirm },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("cip.cmn.btn.defBtn")) + "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }