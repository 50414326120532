var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "search-columns": _vm.searchColumns,
          "grid-head-btn": _vm.gridHeadBtn,
        },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
          "head-preview": function ($event) {
            return _vm.headPreview()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "scopeWrap" },
        _vm._l(_vm.scopeTable, function (item, index) {
          return _c(
            "div",
            { key: item, staticClass: "scopeForm" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c("formItem", {
                        attrs: {
                          "data-form": item,
                          index: index,
                          themeId: _vm.themeId,
                        },
                        on: { itemForm: _vm.itemForm },
                      }),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "deleteForm",
                        on: {
                          click: function ($event) {
                            return _vm.deleteForm(item, index)
                          },
                        },
                      },
                      [_vm._v("X")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }