var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c("CommonTree", {
        ref: "tree",
        attrs: {
          treeData: _vm.treeData,
          defaultProps: _vm.defaultProps,
          searchTitle: _vm.searchTitle,
          treeTitle: this.$t("cip.index.appIndex.theme.treeTitle"),
          isShowdig: false,
          showCheckbox: false,
          "node-key": "id",
        },
        on: { getNodeClick: _vm.treeNodeClick },
      }),
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": _vm.$t(`cip.index.atomIndex.headTitle`),
            },
          }),
          _c(
            "div",
            { staticClass: "headers" },
            [
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
            ],
            1
          ),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "grid-row-btn": _vm.gridRowBtn,
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.crudLoading,
              "data-total": _vm.dataTotal,
            },
            on: {
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
              "page-refresh-change": _vm.onLoad,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }