<template>
  <basic-container>
    <avue-skeleton :loading="waiting"
                   :rows="8"
                   avatar>
      <avue-title :styles="{fontSize: '20px'}"
                  :value="process.name"
                  style="margin-bottom: 20px; "></avue-title>
      <el-card shadow="never"
               style="margin-top: 20px" v-if="option && ((option.column && option.column.length > 0) || (option.group && option.group.length > 0))">
        <avue-form
                    ref="form"
                   v-model="form"
                   :defaults.sync="defaults"
                   :option="option"
                   :upload-preview="handleUploadPreview">
        </avue-form>
      </el-card>

      <el-card v-if="showExamForm"
               shadow="never"
               style="margin-top: 20px;">
        <wf-examine-form ref="examineForm"
                         :process="process"
                         @user-select="handleUserSelect"></wf-examine-form>
      </el-card>
      <div style="height: 120px;"></div>
      <el-row id="avue-view"
              :style="{width: layoutType == 'functional'?'100%':isCollapse ? 'calc(100% - 68px)': 'calc(100% - 220px)' }"
              class="foot-item avue-affix">
        <el-button :loading="loading"
                   size="medium"
                   type="primary"
                   @click="handleStartProcess">发起
        </el-button>
        <el-button v-if="permission.wf_process_draft"
                   :loading="loading"
                   size="medium"
                   type="success"
                   @click="handleDraft(process.id, process.formKey, form)">存为草稿
        </el-button>
      </el-row>
    </avue-skeleton>

    <!-- 人员选择弹窗 -->
    <wf-user-select ref="user-select"
                    :check-type="checkType"
                    :default-checked="defaultChecked"
                    @onConfirm="handleUserSelectConfirm"></wf-user-select>
  </basic-container>
</template>

<script>
import WfExamineForm from './examForm.vue'
import WfUserSelect from './user-select'
import exForm from '../../mixins/ex-form'
import draft from '../../mixins/draft'
import {mapGetters} from "vuex";

export default {
  components: {
    WfUserSelect, WfExamineForm
  },
  mixins: [exForm, draft],
  watch: {
    '$route.params.params': {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const {processId, processDefKey} = param
          if (processId || processDefKey) this.getForm(processId, processDefKey)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["isCollapse","layoutType"]),
    showExamForm() {
      const {hideComment, hideCopy, hideExamine} = this.process
      return !hideComment || !hideCopy || !hideExamine
    }
  },
  data() {
    return {
      defaults: {},
      form: {},
      option: {},
      process: {},
      loading: false
    }
  },
  methods: {
    getForm(processId, processDefKey) {
      let param
      let method
      if (processId) {
        param = processId
        method = 'getStartForm'
      } else if (processDefKey) {
        param = processDefKey
        method = 'getStartFormByProcessDefKey'
      }
      this[method](param).then(res => {
        let {process, form, startForm} = res
        this.form.processId = process.id
        if (form) {
          const option = {...eval('(' + form + ')'), menuBtn: false}
          const {column, group} = option

          const groupArr = []
          const columnArr = this.filterAvueColumn(column, startForm).column
          if (group && group.length > 0) { // 处理group
            group.forEach(gro => {
              gro.column = this.filterAvueColumn(gro.column, startForm).column
              if (gro.column.length > 0) groupArr.push(gro)
            })
          }

          option.column = columnArr
          option.group = groupArr
          this.option = option

          if (this.permission.wf_process_draft) {
            // 查询是否有草稿箱
            this.initDraft(process.id).then(data => {
              this.$confirm('是否恢复之前保存的草稿？', '提示', {}).then(() => {
                this.form = JSON.parse(data)
              }).catch(() => {
              })
            })
          }
        }
        this.waiting = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.foot-item {
  position: fixed;
  bottom: 0;
  right: 0;
  //margin-left: -20px;
  z-index: 101;
  height: 66px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
