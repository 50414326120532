<template>
  <div class="center">
    <el-container>
      <avue-tree :option="treeOption"
                 :data="treeData"
                 @node-click="nodeClick" style="border-right: 1px solid #cccccc;"></avue-tree>
      <el-main>
        <!--列表label-->
        <head-layout :head-btn-options="headBtnOptions"
                     :head-title="$t(`cip.plat.wfDesign.defaultValues.title.indexHeadTitle`)"
                     @head-add="rowSave"
                     @head-delete="handleDelete"></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout ref="gridHeadLayout"
                          :search-columns="searchColumns"
                          @grid-head-search="gridHeadSearch"
                          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout ref="gridLayOut"
                     :table-options="option"
                     :table-data="data"
                     :table-loading="loading"
                     :data-total="page.total"
                     :grid-row-btn="gridRowBtn"
                     :page="page"
                     @page-current-change="onLoad"
                     @page-size-change="onLoad"
                     @page-refresh-change="onLoad"
                     @row-del="rowDel"
                     @row-edit="rowEdit"
                     @row-copy="handleCopy"></grid-layout>
      </el-main>
    </el-container>

    <!--  <basic-container>
    <el-container>
      <el-aside width="200px">
        <avue-tree :option="treeOption"
                   :data="treeData"
                   @node-click="nodeClick"></avue-tree>
      </el-aside>
      <el-main style="margin-left: 10px;">
        <avue-crud :option="option"
                   :table-loading="loading"
                   :data="data"
                   :page.sync="page"
                   :permission="permissionList"
                   v-model="form"
                   ref="crud"
                   @row-update="rowUpdate"
                   @row-save="rowSave"
                   @row-del="rowDel"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @selection-change="selectionChange"
                   @current-change="currentChange"
                   @size-change="sizeChange"
                   @refresh-change="refreshChange"
                   @on-load="onLoad">
          <template slot="menuLeft">
            <el-button type="danger"
                       size="mini"
                       icon="el-icon-delete"
                       plain
                       v-if="permission.wf_form_default_values_delete"
                       @click="handleDelete">删 除
            </el-button>
          </template>
          <template #menu="{row}">
            <el-button v-if="permission.wf_form_default_values_copy"
                       type="text"
                       icon="el-icon-document-copy"
                       size="mini"
                       @click="handleCopy(row)">复制</el-button>
          </template>
        </avue-crud>
      </el-main>
    </el-container>

  </basic-container>-->
  </div>
</template>

<script>
import { getList, remove, listType } from "@/api/plugin/workflow/form-default-values";
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  components: { GridLayout, HeadLayout },
  data () {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        index: true,
        selection: true,
        dialogType: 'drawer',
        align: 'center',
        searchMenuSpan: 6,
        menuWidth:200,
        column: [
          {
            label: this.$t(`cip.plat.wfDesign.defaultValues.field.name`),
            prop: "name",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.name`),
              trigger: "blur"
            }],
            span: 24,
            search: true,
            overHidden: true,
            width: 300,
            align:'left',
          },
          {
            label:  this.$t(`cip.plat.wfDesign.defaultValues.field.content`),
            prop: "content",
            placeholder: this.$t(`cip.plat.wfDesign.defaultValues.msg.content`),
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.content`),
              trigger: "blur"
            }],
            span: 24,
            overHidden: true,
            align:'left',
          },
          {
            label:  this.$t(`cip.plat.wfDesign.defaultValues.field.fieldType`),
            prop: "fieldType",
            type: 'select',
            filterable: true,
            overHidden: true,
            width: 120,
            align:'center',
            allowCreate: true,
            dicData: [{
              label: 'input',
              value: 'input'
            }, {
              label: 'textarea',
              value: 'textarea'
            }, {
              label: 'select',
              value: 'select'
            }, {
              label: 'tree',
              value: 'tree'
            }],
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.fieldType`),
              trigger: "blur"
            }],
            span: 24
          },
        ]
      },
      data: [],
      treeData: [],
      treeOption: {
        size: 'mini',
        addBtn: false,
        props: {
          label: 'fieldType',
          value: 'fieldType'
        }
      }
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.wf_form_default_values_add, false),
        viewBtn: this.vaildData(this.permission.wf_form_default_values_view, false),
        delBtn: this.vaildData(this.permission.wf_form_default_values_delete, false),
        editBtn: this.vaildData(this.permission.wf_form_default_values_edit, false)
      };
    },
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t(`cip.plat.wfDesign.defaultValues.field.name`),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.name`),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      //新增按钮
      if (this.permission.wf_form_default_values_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_form_default_values_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-delete",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {

      let result = [];
      //新增按钮
      if (this.permission.wf_form_default_values_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_form_default_values_delete) {
        result.push({
          label:  this.$t('cip.cmn.btn.delBtn'),
          emit: "row-del",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_form_default_values_copy) {
        result.push({
          label: this.$t('cip.plat.wfDesign.defaultValues.btn.copy'),
          emit: "row-copy",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      return result;
    },
  },
  mounted () {
    this.getListType()
    this.onLoad(this.page, {});
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    nodeClick ({ fieldType }) {
      this.fieldType = fieldType
      this.searchChange(this.query)
    },
    getListType () {
      listType().then(res => {
        this.treeData = res.data.data
        this.treeData.unshift({
          fieldType: '全部'
        })
      })
    },
    handleCopy (row) {
      delete row.id
      this.form = this.deepClone(row)
      let data = encodeURIComponent(JSON.stringify(this.form))
      this.$router.push({
        path: '/workflow/design/form-default-values-edit',
        query: {
          type: 'edit',
          data: data
        }
      })
    },
    rowSave () {
      this.$router.push({
        path: '/workflow/design/form-default-values-edit',
        query: {
          type: 'add',
        }
      })
    },
    rowEdit (row) {
      let data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/workflow/design/form-default-values-edit',
        query: {
          type: 'edit',
          data: data
        }
      })
    },
    rowDel (row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.gridHeadSearch({})
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    handleDelete () {
      let selectionList = this.$refs.gridLayOut.selectionList
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      if (done && typeof done == 'function') done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad (page, params = {}) {
      this.page = page
      this.loading = true;
      if (this.fieldType) {
        if (this.fieldType == '全部') delete params.fieldType
        else params.fieldType = this.fieldType
      }
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    },
    mounted() {
      this.onLoad(this.page);
    },


}
};
</script>

<style>
</style>
