var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": _vm.$t(
                "cip.plat.resource.folder.title.indexHeadTitle"
              ),
            },
            on: { "head-add": _vm.getNew, "head-romve": _vm.headRomve },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.searchChange,
              "grid-head-empty": _vm.searchReset,
            },
            model: {
              value: _vm.searchForm,
              callback: function ($$v) {
                _vm.searchForm = $$v
              },
              expression: "searchForm",
            },
          }),
          _c("avue-crud", {
            ref: "crud",
            staticClass: "folderBox",
            attrs: {
              "data-total": _vm.page.total,
              option: _vm.option,
              data: _vm.data,
            },
            on: {
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
              "page-refresh-change": _vm.onLoad,
              "selection-change": _vm.selectionChange,
              "row-update": _vm.rowUpdate,
              "row-del": _vm.rowDel,
            },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }