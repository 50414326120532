<template>
  <basic-container>
    <el-container>
      <el-aside width="200px">
        <wf-category @node-click="nodeClick"
                     @list-change="findObject(option.column, 'categoryId').dicData = $event"></wf-category>
      </el-aside>
      <el-main style="margin-left: 10px;">
        <avue-crud :option="option"
                   :table-loading="loading"
                   :data="data"
                   :page.sync="page"
                   :search.sync="query"
                   v-model="form"
                   @row-del="rowDel"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @selection-change="selectionChange"
                   @current-change="currentChange"
                   @size-change="sizeChange"
                   @refresh-change="onLoad(page, query)">
          <template slot="menuLeft">
            <el-button type="danger"
                       size="mini"
                       icon="el-icon-delete"
                       plain
                       v-if="permission.wf_design_form_history_delete"
                       @click="handleDelete">{{ $t(`cip.cmn.btn.delBtn`) }}
            </el-button>
          </template>
          <template slot="menu"
                    slot-scope="{row}">
            <el-button v-if="permission.wf_design_form_history_view"
                       type="text"
                       size="mini"
                       icon="el-icon-view"
                       @click="handlePreview(row)">{{ $t(`cip.plat.wfDesign.form.btn.previewBtn`) }}</el-button>
            <el-button v-if="permission.wf_design_form_history_main"
                       type="text"
                       size="mini"
                       icon="el-icon-edit"
                       @click="handleMain(row)">{{ $t(`cip.plat.wfDesign.form.btn.mainBtn`) }}</el-button>
          </template>
        </avue-crud>
      </el-main>
    </el-container>

    <el-drawer :visible.sync="viewVisible"
               :title="$t(`cip.plat.wfDesign.form.title.viewIconHeadTitle`)"
               size="50%"
               append-to-body>
      <avue-form v-if="viewVisible"
                 style="height: 100%; overflow: auto;"
                 ref="viewForm"
                 v-model="viewForm"
                 :option="viewOption">
      </avue-form>
    </el-drawer>
  </basic-container>
</template>

<script>
import { getList, remove, setMainVersion } from "@/api/plugin/workflow/form-history";

import { mapGetters } from "vuex";

import WfCategory from '../process/components/category.vue'

export default {
  components: { WfCategory },
  data() {
    return {
      formVisible: false,
      options: {},
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        index: true,
        selection: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        dialogType: 'drawer',
        align: 'center',
        searchMenuSpan: 6,
        column: [
          {
            label: this.$t(`cip.plat.wfDesign.form.field.formKey`),
            prop: "formKey",
            search: true
          },
          {
            label: this.$t(`cip.plat.wfDesign.form.field.name`),
            prop: "name",
            search: true
          },
          {
            label: this.$t(`cip.plat.wfDesign.form.field.categoryId`),
            prop: 'categoryId',
            type: 'tree',
            props: {
              label: 'name',
              value: 'id'
            },
            dicData: [],
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.categoryId`),
              trigger: "change"
            }],
          },
          {
            label: this.$t(`cip.plat.wfDesign.form.field.remark`),
            prop: 'remark',
            type: 'textarea',
            span: 24
          },
          {
            label: this.$t(`cip.plat.wfDesign.form.field.version`),
            prop: 'version',
            display: false
          },
        ]
      },
      data: [],
      viewVisible: false,
      viewForm: {},
      viewOption: {},
      treeData: [],
      treeOption: {
        size: 'mini',
        addBtn: false,
        props: {
          label: 'name',
          value: 'id'
        }
      }
    };
  },
  watch: {
    '$route.params.id': {
      handler(val) {
        this.formId = val
        this.onLoad(this.page, this.query)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["permission", "tag"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  methods: {
    nodeClick({ id }) {
      this.categoryId = id
      this.searchChange(this.query)
    },
    handleMain(row) {
      this.$confirm(this.$t(`cip.plat.wfDesign.form.msg.main`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        setMainVersion({ id: row.id }).then(() => {
          this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
          this.$store.commit('DEL_TAG', this.tag)
          this.$router.push("/plugin/workflow/design/form")
        })
      })
    },
    handlePreview(row) {
      this.viewOption = { ...eval('(' + row.content + ')'), menuBtn: false }
      this.viewVisible = true
      setTimeout(() => {
        this.$refs.viewForm.clearValidate()
      })
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      if (done) done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
      this.onLoad(this.page, this.query);
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      params.formId = this.formId

      if (this.categoryId) params['categoryId'] = this.categoryId
      else delete params['categoryId']

      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
