<template>
  <basic-container>
    <el-container>
      <!-- <avue-tree :data="menuData" @node-click="treeNodeClick">
      </avue-tree> -->
      <div class="leftNavigation">
        <el-menu :default-active="currentIndexLight" @select="handleSelect">
          <div v-for="item in menuList" :key="item.path">
            <!-- 没有子菜单 -->
            <template v-if="item.children && item.children.length == 0">
              <el-menu-item :index="item.path" @click="clickMenu(item)">
                <i :class="item.icon"></i>
                {{ item.title }}
              </el-menu-item>
            </template>
            <!-- 有子菜单 -->
            <el-submenu v-else :index="item.path">
              <template slot="title">
                <i :class="item.icon"></i>
                {{ item.title }}
              </template>
              <template v-for="child in item.children">
                <!-- 这里是类似递归循环 -->
                <sidebar-item v-if="child.children&&child.children.length>0" :key="child.path" :item="child" />
                <el-menu-item v-else :key="child.path" :index="child.path" @click="clickMenu(child)">
                  <i :class="child.icon"></i>
                  {{ child.title }}
                </el-menu-item>
              </template>
            </el-submenu>
          </div>
        </el-menu>
      </div>
      <el-main>
        <!-- <head-layout :head-title="$t('附件管理')" :head-btn-options="headBtnOptions" v-on="onHeadEvent" /> -->
        <!-- <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-on="onSearchEvent" /> -->
        <!-- <div class="buttonBox">
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleUpload">上传</el-button>
            <el-button type="primary" size="small" icon="el-icon-plus">新建文件</el-button>
            <el-button type="primary" size="small" icon="el-icon-edit-outline">新建在线文档</el-button>
            <el-button type="primary" size="small" icon="el-icon-delete">批量删除</el-button>
            <el-button type="primary" size="small" icon="el-icon-rank">批量移动</el-button>
            <el-button type="primary" size="small" icon="el-icon-download">批量下载</el-button>
            <el-button type="primary" size="small" icon="el-icon-share">批量分享</el-button>
        </div> -->
        <div class="crumbsBox">
          <div style="display: flex">
            <el-button icon="el-icon-back" round size="small" style="margin-right: 16px" type="primary" @click="returnUpperLevel">{{ $t('cip.plat.resource.attach.btn.upOneLevelBtn')}}</el-button>
            {{ $t('cip.plat.resource.attach.field.currentLocation')}}:
            <el-breadcrumb class="crumsPlate" separator-class="el-icon-arrow-right">
              <el-breadcrumb-item v-for="(item,index) in breadList" :key="index">{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-upload
            class="upload-demo"
            action="/"
            :show-file-list="false"
            :before-upload="beforeSuccess"
            :on-success="uploadSuccess"
            :http-request="handleHttpRequest"
            multiple
            >
            <el-button size="small" type="primary">{{$t('cip.plat.resource.attach.btn.UploadBtn')}}</el-button>
          </el-upload>
          <el-popover
            placement="bottom"
            trigger="hover">
            <el-button-group>
              <el-button v-for="(item,index) in emergeList" :key="index" :icon="item.icon" :type="emergeIndex == index?'primary':''" @click="chooseEmerge(item,index)">{{ item.name }}</el-button>
            </el-button-group>
            <el-button slot="reference" size="small" type="primary"> {{ $t('cip.plat.resource.attach.btn.howToPresentBtn')}}:<i class="el-icon-menu el-icon--right"></i>
            </el-button>
          </el-popover>
        </div>
        <div class="exhibitionBox">
          <div class="tabelBox">
            <el-table v-show="emergeIndex == 0" ref="fileTable" v-loading="loading" :data="data" height="680" highlight-current-row style="width: 100%" @row-click="fileDetails" @row-dblclick="openFile">
              <el-table-column align="right" prop="link" width="80">
                <template slot-scope="scope">
                  <img :src="scope.row.link" alt="" height="34" min-width="34">
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" align="left" :label="$t('cip.plat.resource.attach.field.filename')" prop="fileName" sortable>
                <template slot-scope="scope">
                  {{scope.row.fileName || scope.row.folderName}}
                </template>
              </el-table-column>
              <el-table-column align="center" :label="$t('cip.plat.resource.attach.field.type')" prop="extension" sortable>
              </el-table-column>
              <el-table-column align="center" :label="$t('cip.plat.resource.attach.field.size')" prop="totalSize" sortable>
              </el-table-column>
              <el-table-column align="center" :label="$t('cip.plat.resource.attach.field.dateUpload')" prop="createTime" sortable>
              </el-table-column>
            </el-table>
            <div v-show="emergeIndex == 1" class="netWorkBox">
              <div v-for="(item,index) in data" :key="index" :class="netWorkIndex == index?'netWorkActive':''" class="netWork" @click="chooseNetWork(item,index)" @dblclick="openFile(item)">
                <img :src="item.link" class="netWorkImg">
                <p>{{ item.originalName }}</p>
              </div>
            </div>
            <div class="paginationBox">
              <el-pagination :current-page="page.currentPage" :page-size="page.pageSize" :page-sizes="[10, 20, 30, 40,50,100]" :total="page.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
          <div class="informationBox">
            <div class="detailsRightTitle">{{ $t('cip.plat.resource.attach.title.viewHeadTitle')}}</div>
            <div class="detailsRightBox">
              <el-form :model="detailsForm" size="small">
                <el-form-item :label="$t('cip.plat.resource.attach.field.fileSize')" prop="attachSize">
                  <el-input v-model="detailsForm.totalSize" readonly></el-input>
                </el-form-item>
                <el-form-item :label="$t('cip.plat.resource.attach.field.creationTime')">
                  <el-input v-model="detailsForm.createTime" readonly></el-input>
                </el-form-item>
<!--                <el-form-item :label="$t('cip.plat.resource.attach.field.attachmentDomain')">-->
<!--                  <el-input v-model="detailsForm.domainUrl" readonly></el-input>-->
<!--                </el-form-item>-->
                <el-form-item :label="$t('cip.plat.resource.attach.field.attachmentExtension')">
                  <el-input v-model="detailsForm.extension" readonly></el-input>
                </el-form-item>
                <el-form-item :label="$t('cip.plat.resource.attach.field.attachmentAddress')">
                  <el-input v-model="detailsForm.objectKey" readonly></el-input>
                </el-form-item>
                <el-form-item :label="$t('cip.plat.resource.attach.field.attachmentName')">
                  <el-input v-model="detailsForm.fileName" readonly></el-input>
                </el-form-item>
<!--                <el-form-item :label="$t('cip.plat.resource.attach.field.formerly')">-->
<!--                  <el-input v-model="detailsForm.originalName" readonly></el-input>-->
<!--                </el-form-item>-->
              </el-form>
            </div>
          </div>
        </div>
        <el-dialog :visible.sync="attachBox" append-to-body :title="$t('cip.plat.resource.attach.field.management')" width="555px">
          <avue-form ref="form" v-model="attachForm" :option="attachOption" :upload-after="uploadAfter">
          </avue-form>
        </el-dialog>
      </el-main>
    </el-container>
    <el-dialog
      :before-close="closeFileDia"
      :title="fileTitle"
      :visible.sync="fileShow"
      width="50%">
      <img v-if="extension == 'img'" :src="fileSrc" class="fileContainer">
      <video v-if="extension == 'video'" :src="fileSrc" autoplay class="fileContainer" controls></video>
      <audio v-if="extension == 'audio'" :src="fileSrc" autoplay controls style="width: 100%" type="audio/mp3"></audio>
    </el-dialog>
  </basic-container>
</template>

<script>
import {getList, initTask, merge, preSignUrl, remove, taskInfo} from "@/api/resource/attach";
import {mapGetters} from "vuex";
import ServerNameEnum from '@/util/ServerNameEnum';
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree";
import Queue from "promise-queue-plus";
import md5 from "@/util/md5";
export default {
  components: {CommonTree},
  data() {
    return {
      tableData:[],
      fileUploadChunkQueue:{},
      action:"/api/sinoma-resource/oss/endpoint/put-file-attach-name-file",
      // 双击文件访问地址
      fileSrc: '',
      // 双击文件弹窗开关
      fileShow: false,
      // 双击文件弹窗标题
      fileTitle: '',
      // 展现按钮列表
      emergeList: [{
        icon: 'el-icon-tickets',
        name: this.$t('cip.plat.resource.attach.field.list')
      }, {
        icon: 'el-icon-s-grid',
        name: this.$t('cip.plat.resource.attach.field.network')
      }],
      // 确认选中浮现方式index
      emergeIndex: 0,
      // 确认选中网格Index
      netWorkIndex: 0,
      // 详情表单
      detailsForm: {},
      // 上一级文件夹ID
      upperLevelId: [],
      parentId:'',
      // 文件类型
      extension: '',
      // 左侧树结构选取的类型
      fileType: '',
      direction: 'rtl',
      currentIndexLight: '',//当前激活菜单的 index  高亮显示
      menuList: [
        {
          "path": "",
          "title": this.$t('cip.plat.resource.attach.field.myPapers'),
          "icon": 'el-icon-receiving',
          "children": [
            {
              "path": "",
              "title": this.$t('cip.plat.resource.attach.field.myPapers'),
              "bread": this.$t('cip.plat.resource.attach.field.myPapers')+','+this.$t('cip.plat.resource.attach.field.all'),
              "icon": 'el-icon-menu',
              "children": []
            },
            {
              "path": "1",
              "title": this.$t('cip.plat.resource.attach.field.picture'),
              "bread": this.$t('cip.plat.resource.attach.field.myPapers')+','+this.$t('cip.plat.resource.attach.field.picture'),
              "icon": 'el-icon-picture-outline',
              "children": []
            },
            {
              "path": "2",
              "title": this.$t('cip.plat.resource.attach.field.document'),
              "bread": this.$t('cip.plat.resource.attach.field.myPapers')+','+this.$t('cip.plat.resource.attach.field.document'),
              "icon": 'el-icon-document',
              "children": []
            },
            {
              "path": "3",
              "title": this.$t('cip.plat.resource.attach.field.video'),
              "bread": this.$t('cip.plat.resource.attach.field.myPapers')+','+this.$t('cip.plat.resource.attach.field.video'),
              "icon": 'el-icon-video-camera',
              "children": []
            },
            {
              "path": "4",
              "title": this.$t('cip.plat.resource.attach.field.music'),
              "bread": this.$t('cip.plat.resource.attach.field.myPapers')+','+this.$t('cip.plat.resource.attach.field.music'),
              "icon": 'el-icon-headset',
              "children": []
            },
            {
              "path": "5",
              "title": this.$t('cip.plat.resource.attach.field.other'),
              "bread": this.$t('cip.plat.resource.attach.field.myPapers')+','+this.$t('cip.plat.resource.attach.field.other'),
              "icon": 'el-icon-wallet',
              "children": []
            },
          ]
        },
        // {
        //   "path": "6",
        //   "title": "回收站",
        //   "bread": '回收站',
        //   "icon": 'el-icon-delete',
        //   "children": []
        // },
        // {
        //   "path": "7",
        //   "title": "我的分享",
        //   "bread": '我的分享',
        //   "icon": 'el-icon-share',
        //   "children": []
        // }
      ],
      breadList: [this.$t('cip.plat.resource.attach.field.myPapers'),this.$t('cip.plat.resource.attach.field.all'),],
      List: [],
      menuOption: {
        defaultExpandAll: true,
      },
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      attachBox: false,
      selectionList: [],
      headBtnOptions: [{
        label: this.$t('cip.plat.resource.attach.field.upload'),
        emit: "head-upload",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.delBtn'),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
      searchColumns: [
        {
          prop: "domainUrl",
          search: true,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentDomain'),
        },
        {
          prop: "name",
          search: true,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentName'),
        },
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.formerly'),
          prop: "originalName",
          search: true
        }
      ],
      option: {
        height: 'auto',
        calcHeight: 30,
        tip: false,
        searchShow: false,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        dialogClickModal: false,
        column: [
          {
            label: this.$t('cip.plat.resource.attach.field.attachmentAddress'),
            prop: "link",
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentAddress'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.attach.field.attachmentDomain'),
            prop: "domainUrl",
            search: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentDomain'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.attach.field.attachmentName'),
            prop: "name",
            search: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentName'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.attach.field.formerly'),
            prop: "originalName",
            search: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.formerly'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.attach.field.attachmentExtension'),
            prop: "extension",
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentExtension'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.attach.field.attachmentSize'),
            prop: "attachSize",
            slot: true,
            align: 'right',
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.attach.field.attachmentSize'),
              trigger: "blur"
            }]
          },
        ]
      },
      sysFileTree: {},
      data: [],
      attachForm: {},
      attachOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t('cip.plat.resource.attach.field.attachmentUpload'),
            prop: 'attachFile',
            type: 'upload',
            drag: true,
            loadText: this.$t('cip.plat.resource.attach.msg.templateUploaded'),
            span: 24,
            propsHttp: {
              res: 'data'
            },
            action: "/api/sinoma-resource/oss/endpoint/put-file-attach"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: false,
        editBtn: false,
        viewBtn: false,
        delBtn: this.vaildData(this.permission.attach_delete, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    onSearchEvent() {
      return {
        'grid-head-search': this.searchChange,
        'grid-head-empty': this.searchReset
      }
    },
    onHeadEvent() {
      return {
        'head-upload': this.handleUpload,
        'head-romve': this.handleDelete
      }
    },
  },
  mounted() {
    // document.getElementsByClassName("avue-crud__menu").item(0).remove();
    this.onLoad(this.page, this.query);

  },
  methods: {
    async handleUpload(file, taskRecord, options){

      let lastUploadedSize = 0; // 上次断点续传时上传的总大小
      let uploadedSize = 0 // 已上传的大小
      const totalSize = file.size || 0 // 文件总大小
      let startMs = new Date().getTime(); // 开始上传的时间
      const { exitPartList, chunkSize, chunkNum, identifier } = taskRecord

      // 获取从开始上传到现在的平均速度（byte/s）
      const getSpeed = () => {
        // 已上传的总大小 - 上次上传的总大小（断点续传）= 本次上传的总大小（byte）
        const intervalSize = uploadedSize - lastUploadedSize
        const nowMs = new Date().getTime()
        // 时间间隔（s）
        const intervalTime = (nowMs - startMs) / 1000
        return intervalSize / intervalTime
      }

      const uploadNext = async (partNumber) => {
        const start = new Number(chunkSize) * (partNumber - 1)
        const end = start + new Number(chunkSize)
        const blob = file.slice(start, end)
        const res = await preSignUrl(identifier, partNumber)
        let code = res.data.code
        let data = res.data.data
        if (code === 200 && data) {
          await axios.request({
            url: data ,
            method: 'PUT',
            data: blob,
            headers: {'Content-Type': 'application/octet-stream'}
          })
          return Promise.resolve({ partNumber: partNumber, uploadedSize: blob.size })
        }
        return Promise.reject(`分片${partNumber}， 获取上传地址失败`)
      }

      /**
       * 更新上传进度
       * @param increment 为已上传的进度增加的字节量
       */
      const updateProcess = (increment) => {
        increment = new Number(increment)
        const { onProgress } = options
        let factor = 1000; // 每次增加1000 byte
        let from = 0;
        // 通过循环一点一点的增加进度
        while (from <= increment) {
          from += factor
          uploadedSize += factor
          const percent = Math.round(uploadedSize / totalSize * 100).toFixed(2);
          onProgress({percent: percent})
        }

        const speed = getSpeed();
        const remainingTime = speed != 0 ? Math.ceil((totalSize - uploadedSize) / speed) + 's' : '未知'
        if (!this.multiple) this.schedule = parseInt(uploadedSize/totalSize * 100)
        if (this.multiple) this.tableData.find(item => item.uid == file.uid).schedule = parseInt(uploadedSize/totalSize * 100)+"%"
        this.$forceUpdate()
        console.log('剩余大小：', (totalSize - uploadedSize) / 1024 / 1024, 'mb');
        console.log('进度：', parseInt(uploadedSize/totalSize * 100),"%");
        console.log('当前速度：', (speed / 1024 / 1024).toFixed(2), 'mbps');
        console.log('预计完成：', remainingTime);
      }


      return new Promise(resolve => {
        const failArr = [];
        const queue = Queue(5, {
          "retry": 3,               //Number of retries
          "retryIsJump": false,     //retry now?
          "workReject": function(reason,queue){
            failArr.push(reason)
          },
          "queueEnd": function(queue){
            resolve(failArr);
          }
        })
        this.fileUploadChunkQueue[file.uid] = queue
        for (let partNumber = 1; partNumber <= chunkNum; partNumber++) {
          const exitPart = (exitPartList || []).find(exitPart => exitPart.partNumber == partNumber)
          if (exitPart) {
            // 分片已上传完成，累计到上传完成的总额中,同时记录一下上次断点上传的大小，用于计算上传速度
            lastUploadedSize += new Number(exitPart.size)
            updateProcess(exitPart.size)
          } else {
            queue.push(() => uploadNext(partNumber).then(res => {
              // 单片文件上传完成再更新上传进度
              updateProcess(res.uploadedSize)
            }))
          }
        }
        if (queue.getLength() == 0) {
          // 所有分片都上传完，但未合并，直接return出去，进行合并操作
          resolve(failArr);
          return;
        }
        queue.start()
      })
    },
    async getTaskInfo(file){
      if (file.size == 0){
        this.$message.warning({
          message: '不允许上传空文件'
        })
        return true
      }
      let task;
      const identifier = await md5(file)
      let res = await taskInfo(identifier)
      if (res.data.code === 200) {
        task = res.data.data
        if(Object.keys(task).length < 1){
          let initTaskData = {
            'identifier':identifier,
            'fileName': file.name,
            'totalSize': file.size,
            'modelId': this.parentId,
            'chunkSize': 5 * 1024 * 1024
          }
          let initTaskRes = await initTask(initTaskData)
          this.parentId = ''
          if (initTaskRes.data.code == 200) {
            task = initTaskRes.data.data
          } else {
            this.$message.error({
              title: '文件上传错误',
              message: initTaskRes.data.msg
            })
          }
        }
      } else {
        this.$message.error({
          title: '文件上传错误',
          message: res.data.msg
        })
      }
      return task
    },
    async handleHttpRequest(options){
      const file = options.file
      const task = await this.getTaskInfo(options.file)
      if (task) {
        const { finished, path, taskRecord } = task
        const { identifier, id } = taskRecord
        if (finished) {
          this.tableData.find(item => item.uid == file.uid).schedule = "100%"
          this.schedule = 100
          this.$forceUpdate()
          return {'id':id,'uid':file.uid,'path':path}
        } else {
          const errorList = await this.handleUpload(file, taskRecord, options)
          if (errorList.length > 0) {
            this.$message.error({
              title: '文件上传错误',
              message: '部分分片上次失败，请尝试重新上传文件'
            })
            return;
          }
          const mergeRes = await merge(identifier)
          let code = mergeRes.data.code
          let msg = mergeRes.data.msg
          if (code === 200){
            return {'id':id,'uid':file.uid,'path':path}
          } else {
            this.$message.error({
              title: '文件上传错误',
              message: msg
            })
          }
        }
      } else {
        this.$message.error({
          title: '文件上传错误',
          message: '获取上传任务失败'
        })
      }
    },
    beforeSuccess(res){
      this.tableData.push(res)
      console.log(this.tableData,"beforeSuccess")
    },
    uploadSuccess(res){
      //重载表格
      this.onLoad(this.page,this.query)
    },
    handleAvatarSuccess(res, file) {
      this.onLoad(this.page, this.query);
      this.$message.success('上传成功');
      this.action = "/api/sinoma-resource/oss/endpoint/put-file-attach-name-file"
    },
    beforeRemove(){
      this.$message.error('上传失败');
    },

    treeNodeClick(node) {
      let tmpData = Object.assign({}, this.$refs.searchFrom.searchForm, {fileType: node.value});
      this.onLoad(this.page, tmpData)
    },
    // handleUpload() {
    //   this.attachBox = true;
    // },
    uploadAfter(res, done, loading, column) {
      this.attachBox = false;
      this.refreshChange();
      done();
    },
    handleDownload(row) {
      window.open(`${row.link}`);
    },
    rowDel(row) {
      this.$confirm(this.$t(`cip.cmn.msg.warning.delWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    // 返回上一级
    returnUpperLevel() {
      this.onLoad(this.page, {sysFileTreeId: this.upperLevelId[this.upperLevelId.length - 2], fileType: this.fileType})
      this.upperLevelId.pop();
      this.List.pop();
      let url = this.List
      console.log(url)
      if (url.length - 1 >= 0) {
        this.action =  "/api/sinoma-resource/oss/endpoint/put-file-attach-name/" + url[url.length-1];
        console.log(this.action)
      }else {
        this.action =  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-file"
      }
      if (this.breadList.length > 2) {
         this.breadList.pop();
      }

    },
    // 选中网格数据
    chooseNetWork(item, index) {
      this.netWorkIndex = index;
      this.detailsForm = item;
    },
    // 选中数据展现方式
    chooseEmerge(item, index) {
      this.emergeIndex = index;
      if (this.data.length != 0) {
        this.netWorkIndex = 0;
        this.$refs.fileTable.setCurrentRow(this.data[0]);
        this.detailsForm = this.data[0];
      }
    },
    // 详情
    fileDetails(row) {
      this.detailsForm = row;
      this.detailsForm.fileName = row.fileName ||row.folderName
    },
    // 打开文件夹或者文件
    openFile(row) {
      if (row.extension == "folder") {
        this.parentId = row.id
        this.upperLevelId.push(row.id);
        this.breadList.push(row.originalName)
        this.page.currentPage = '1';
        this.sysFileTree = {sysFileTreeId : row.id }
        this.onLoad(this.page, this.sysFileTree)
        this.List.push(row.originalName)
        if(row.originalName!=null){
          this.action =  "/api/sinoma-resource/oss/endpoint/put-file-attach-name/"  + row.originalName;
        }
        return
      }
      // 图片预览
      if (row.extension == 'png' || row.extension == 'jpg') {
        this.extension = 'img';
        this.fileTitle = this.$t('cip.plat.resource.attach.title.pictureHeadTitle');
        this.fileSrc = row.link;
        this.fileShow = true;
      }
      // 视频预览
      if (row.extension == 'mp4') {
        this.extension = 'video';
        this.fileTitle = this.$t('cip.plat.resource.attach.title.videoHeadTitle');
        this.fileSrc = row.link;
        this.fileShow = true;
      }
      // 音频预览
      if (row.extension == 'mp3') {
        this.extension = 'audio';
        this.fileTitle = this.$t('cip.plat.resource.attach.title.audioHeadTitle');
        this.fileSrc = row.link;
        this.fileShow = true;
      }
      // 文档下载
      if (row.extension!='png' && row.extension!='jpg' && row.extension!='mp4' && row.extension!='mp3' ) {
        let fileId = row.id
        axios({
          url: ServerNameEnum.SERVER_RESOURCE_+'/file/download/'+fileId,
          method: 'GET',
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            // this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          }
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', row.fileName);
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        });
        // console.log(row)
        // window.location.href = `${row.domainUrl+'/'+row.name}`;
      }

    },
    // 关闭文件弹窗
    closeFileDia() {
      this.fileShow = false;
      this.fileTitle = '';
      this.fileSrc = '';
    },
    // 点击左侧结构获取面包屑数据
    clickMenu(item) {
      this.upperLevelId = [];
      this.breadList = item.bread.split(",");
    },
    // 左侧结构获取文件类型
    handleSelect(key) {
      this.fileType = key;
      this.page.currentPage = '1';
      this.query.fileType = key;
      this.onLoad(this.page, {fileType: key})
    },
    // 选取分页
    handleCurrentChange(e) {
      this.page.currentPage = e;
      this.onLoad(this.page,this.sysFileTree);
    },
    // 选取每页展示几条
    handleSizeChange(e) {
      this.page.pageSize = e;
      this.onLoad(this.page,this.sysFileTree);
    },
    // 获取接口列表数据
    onLoad(page, params = {}) {
      this.data = [];
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        if (res.data.data.folder) {
          let data = res.data.data.folder.records;
          data.forEach((item) => {
            this.$set(item, "originalName", item.folderName);
            this.$set(item, "extension", 'folder');
            this.$set(item, "attachSize", '/');
            this.$set(item, "domainUrl", '/');
            this.$set(item, "link", '/');
            this.$set(item, "name", '/');
          })
          data = data.concat(res.data.data.file.records);
          data.forEach((item) => {
            this.$set(item,'totalSize', parseInt(item.totalSize/1000) || '/')
            this.$set(item, "link", require('@/assets/images/app.png'))
            if (item.extension == 'folder') {
              this.$set(item, "link", require('@/assets/images/folder.png'))
            }
            if (item.extension == 'xlsx' || item.extension == 'xls' || item.extension == 'docx' || item.extension == 'pdf' || item.extension == 'doc') {
              this.$set(item, "link", require('@/assets/images/file.png'))
            }
            if (item.extension == 'video') {
              this.$set(item, "link", require('@/assets/images/video.png'))
            }
            if (item.extension == 'audio') {
              this.$set(item, "link", require('@/assets/images/audio.png'))
            }
          })
          this.data = data;
          this.page.total = res.data.data.file.total + res.data.data.folder.total;
        } else {
          res.data.data.records.forEach((item) => {
            if (item.extension == 'folder') {
              this.$set(item, "link", require('@/assets/images/folder.png'))
            }
            if (item.extension == 'xlsx' || item.extension == 'xls' || item.extension == 'docx' || item.extension == 'pdf' || item.extension == 'doc') {
              this.$set(item, "link", require('@/assets/images/file.png'))
            }
            if (item.extension == 'video') {
              this.$set(item, "link", require('@/assets/images/video.png'))
            }
            if (item.extension == 'audio') {
              this.$set(item, "link", require('@/assets/images/audio.png'))
            }
          })
          this.data = res.data.data.records;
          this.page.total = res.data.data.total;
        }
        if (this.data.length != 0) {
          this.$refs.fileTable.setCurrentRow(this.data[0]);
          this.detailsForm = this.data[0];
        }
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input-group__append ::v-deep {
  display: none;
}

.leftNavigation {
  width: 200px;
  margin-right: 5px;
  height: calc(100vh - 150px);
  background-color: #ffffff;
  overflow: auto;
  flex-shrink: 0;
}

.buttonBox {
  width: calc(100% - 20px);
  background-color: #ffffff;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.crumbsBox {
  width: calc(100% - 20px);
  background-color: #ffffff;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: flex;
  line-height: 31px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.crumsPlate {
  line-height: 31px;
}

.exhibitionBox {
  width: 100%;
  height: calc(93% - 5px);
  // height: 87%;
  // background-color: #ffffff;

  display: flex;
  justify-content: space-between;
  // position: relative;
}

.paginationBox {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.detailsRightTitle {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.detailsRightBox {
  width: 100%;
}

.informationBox {
  width: calc(28% - 20px);
  height: calc(100% - 10px);
  background-color: #ffffff;
  // height: 100%;
  padding: 16px 10px 5px 10px;
}

.tabelBox {
  width: 70%;
  height: 100%;
  padding: 5px 10px;
  background-color: #ffffff;
  position: relative;
}

.fileContainer {
  width: 100%;
  height: 100%;
}

.netWorkBox {
  width: 100%;
  height: 670px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.netWork {
  width: 18%;
  height: 140px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: #333333;
  margin: 1%;
}

.netWorkImg {
  width: 75px;
  height: 75px;
  display: block;
  margin: auto;
}

.netWork p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.netWorkActive {
  background-color: #ecf5ff;
}

.el-menu-item.is-active {
  color: #FFFFFF;
}
</style>
