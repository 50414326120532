<template>
  <div>
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-col :span="12">
          <el-form-item label-width="120px" label="host" prop="host">
            <el-input v-model="formData.host" :placeholder="$t('cip.plat.message.channel.msg.host')" show-word-limit clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="user" prop="user">
            <el-input v-model="formData.user" :placeholder="$t('cip.plat.message.channel.msg.user')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="from" prop="from">
            <el-input v-model="formData.from" :placeholder="$t('cip.plat.message.channel.msg.from')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="pass" prop="pass">
            <el-input v-model="formData.pass" :placeholder="$t('cip.plat.message.channel.msg.pass')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        host: "",
        user: "",
        from:"",
        pass: "",
      },
      rules: {
        host: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.host'),
          trigger: 'blur'
        }],
        user: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.user'),
          trigger: 'blur'
        }],
        from: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.from'),
          trigger: 'blur'
        }],
        pass: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.pass'),
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style>
</style>
