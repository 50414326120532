<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t(`cip.plat.message.sendRecord.sendRecord.title.viewHeadTitle`)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from '@/util/ServerNameEnum';
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {getDetailInfo} from "@/api/message/sendInfo";

export default {
  name: "msgSendDetail",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {}
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let this_ = this;
      return [
        {
          label: this.$t('cip.plat.message.sendRecord.sendRecord.field.detailCode'),
          labelWidth: 130,
          prop: "detailCode",
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.sendRecord.field.detailCode`),
          span: 8
        },
        {
          label: this.$t('cip.plat.message.sendRecord.sendRecord.field.channelName'),
          labelWidth: 130,
          prop: "channelName",
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.sendRecord.field.channelName`),
          span: 8
        },
        {
          label: this.$t('cip.plat.message.sendRecord.sendRecord.field.sendAccount'),
          labelWidth: 130,
          prop: "sendAccount",
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.sendRecord.field.sendAccount`),
          span: 8
        },
        {
          label: this.$t('cip.plat.message.sendRecord.sendRecord.field.receiveAccount'),
          labelWidth: 130,
          prop: "receiveAccount",
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.sendRecord.field.receiveAccount`),
          span: 8
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.sendStatus'),
          labelWidth: 130,
          prop: "sendStatus",
          span: 8,
          row: true,
          cell: true,
          type: 'select',
          readonly: true,
          disabled: true,
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_status`,
          props: {
            label: "dictValue",
            value: "dictKey"
          }
        },
        {
          label: this.$t('cip.plat.message.sendRecord.sendRecord.field.msgTile'),
          labelWidth: 130,
          prop: "msgTile",
          readonly: true,
          row: true,
          cell: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.sendRecord.field.msgTile`),
          span: 24
        },
        {
          label: this.$t('cip.plat.message.sendRecord.sendRecord.field.msgContent'),
          labelWidth: 130,
          type: "textarea",
          minRows: 8,
          prop: "msgContent",
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.sendRecord.field.msgContent`),
          span: 24
        }
      ]
    },
  },
  watch: {

  },
  created() {
    let {id, pageType,node} = this.$route.query;
    this.nodeData = node;
    this.type = pageType;
    this.dataForm.id = id
    this.initData()
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetailInfo(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
