<!--消息通道管理公共页面-->
<template>
  <el-container>
    <el-main>
      <el-table
        :data="tableDataComp"
        size="medium"
        style="width: 100%">
        <el-table-column
          type="index"
          width="80"
          align="center"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="channelCode"
          label="通道编号"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="channelName"
          label="通道名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="channelType"
          label="通道类型"
          align="center"
          :formatter="dictFormat"
        >
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="rowCheck(scope.row)" :disabled="scope.row.$disabledBtn" size="small" type="text">
              {{ $t(`cip.cmn.btn.defBtn`) }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>

import TableDialog from "@/views/business/components/common/tableDialog";
import {
  selectPage
} from '@/api/message/channel';
import {getDictionary} from '@/api/system/dictbiz'
export default {
  name: "msChannelDialog",
  components: {
    TableDialog
  },
  props: {
    memberTableData: {
      type: Array
    },
    formatEdit:{
      type: Function,
      default: null
    },
  },
  data() {
    return {
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      dictdata:[],
      tableData: [],
      allMemberData: [],
      dataTotal: 0,
      tableLoading: false,
      gridRowBtn: [],
      dialogTitle: this.title ? this.title : this.$t('cip.business.components.mdWarehouseDialog.title.wareHouseTitle'),
      tableOptions: {
        menuWidth: 120,
        selection: false,
        column: [
          {
            label: "通道编号",
            prop: 'channelCode',
          },
          {
            label: "通道名称",
            prop: 'channelName',
          },
          {
            label: "通道类型",
            prop: 'channelType',
            dicUrl:  "/api/sinoma-system/dict-biz/dictionary?code=channel_type",
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true
          }
        ],
      },

      searchColumns: [
        {
          placeholder: "通道编号",
          prop: "channelCode",
        },
        {
          placeholder: "通道名称",
          prop: "channelName",
        },
      ],
    }
  },
  /*watch: {
    disabledList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit("update:disabledList", val)
      }
    }
  },*/
  computed: {
    tableDataComp() {
      let memberTableCodeData = this.memberTableData.map(e => e.channelCode)
      // let allMemberCodeData = this.allMemberData.map(e => e.channelCode);
      return this.tableData
        .map(e => ({
          ...e,
          $disabledBtn: memberTableCodeData.includes(e.channelCode) ,
        }))
    }
  },
  methods: {
    initMember() {
      this.allMemberData=[]
    },

    rowCheck(row) {
      // this.memberTableData.push({
      //   // $cellEdit: true,
      //   channelId: row.id,
      //   channelCode: row.channelCode,
      //   channelName: row.channelName,
      //   channelType: row.channelType,
      //   titleFormat: "",
      //   contentFormat:""
      // })
      this.formatEdit(row);
    },
    onLoad() {
      this.tableLoading = true;
      selectPage(
        this.page.currentPage,
        this.page.pageSize
      ).then(res => {
        const data = res.data.data;
        this.dataTotal = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    dictFormat(row,column){
      return this.dictdata.filter(item=>item.dictKey==row.channelType)[0].dictValue;
    },
  },
  mounted() {
    //this.initMember()
   // /api/sinoma-system/dict-biz/dictionary?code=channel_type;

    let parms = {
      code:"channel_type"
    }
    getDictionary(parms).then(res=>{
      this.dictdata = res.data.data;
    });
    this.onLoad();
  },
  /*async created(){
    console.log(11)
    //this.initMember()
    this.onLoad(this.page, {});
  }*/
}
</script>

<style scoped>

</style>
