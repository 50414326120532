var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        [
          _c("head-layout", {
            attrs: {
              "head-title": "",
              showIcon: false,
              "head-btn-options": _vm.headBtnOptions,
            },
            on: { "head-add": _vm.handleAdd, "head-delete": _vm.handleDelete },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableOption,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "grid-row-detail-click": _vm.rowView,
              "gird-handle-select-click": _vm.selectionChange,
              "page-current-change": _vm.currentChange,
              "page-size-change": _vm.sizeChange,
            },
            scopedSlots: _vm._u(
              [
                !this.pageDisabled
                  ? {
                      key: "customBtn",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 3px" },
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowEdit(row)
                                },
                              },
                            },
                            [_vm._v("编辑\n        ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 3px" },
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowDel(row)
                                },
                              },
                            },
                            [_vm._v("移除\n        ")]
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("proceduresDialog", { ref: "proceduresDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }