var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.step, "finish-status": "success", simple: "" } },
        [
          _c("el-step", {
            attrs: {
              title: _vm.$t(
                "cip.plat.wfDesign.model.title.designFormsHeadTitle"
              ),
              icon: "el-icon-edit",
            },
          }),
          _c("el-step", {
            attrs: {
              title: _vm.$t(
                "cip.plat.wfDesign.model.title.designProcessHeadTitle"
              ),
              icon: "el-icon-upload",
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "cip.plat.wfDesign.model.title.designProcessHeadTitle"
                          )
                        ) +
                        "\n        "
                    ),
                    _c(
                      "el-tooltip",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == "1",
                            expression: "step == '1'",
                          },
                        ],
                        attrs: {
                          content: _vm.$t(
                            "cip.plat.wfDesign.model.field.fullScreen"
                          ),
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-full-screen",
                          on: { click: _vm.handleFullScreen },
                        }),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("el-step", {
            attrs: {
              title: _vm.$t("cip.plat.wfDesign.model.title.doneHeadTitle"),
              icon: "el-icon-circle-check",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 0,
              expression: "step == 0",
            },
          ],
        },
        [
          _c("avue-form", {
            ref: "form1",
            staticClass: "form-design",
            staticStyle: { "margin-bottom": "66px", padding: "12px" },
            attrs: { option: _vm.step1.option },
            scopedSlots: _vm._u([
              {
                key: "tip",
                fn: function () {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(
                              "/plugin/workflow/design/form"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cip.plat.wfDesign.model.msg.form"))
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "form",
                fn: function () {
                  return [
                    _vm.option &&
                    ((_vm.option.column && _vm.option.column.length > 0) ||
                      (_vm.option.group && _vm.option.group.length > 0))
                      ? _c("avue-form", {
                          ref: "form2",
                          attrs: { option: _vm.option },
                          model: {
                            value: _vm.form,
                            callback: function ($$v) {
                              _vm.form = $$v
                            },
                            expression: "form",
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.step1.form,
              callback: function ($$v) {
                _vm.$set(_vm.step1, "form", $$v)
              },
              expression: "step1.form",
            },
          }),
        ],
        1
      ),
      _vm.step == 1
        ? _c(
            "div",
            [
              _c("wf-design", {
                ref: "bpmn2",
                staticStyle: {
                  height: "calc(100vh - 290px)",
                  background: "white",
                },
                attrs: { id: "bpmn2", options: _vm.step2.option },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 2
        ? _c(
            "div",
            [
              _c("wf-design", {
                ref: "bpmn3",
                staticStyle: { height: "calc(100vh - 290px)" },
                attrs: { options: _vm.step3.option },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "foot-item",
          style: {
            width:
              _vm.layoutType == "functional"
                ? "100%"
                : _vm.isCollapse
                ? "calc(100% - 68px)"
                : "calc(100% - 220px)",
          },
        },
        [
          _vm.step > 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: {
                    click: function ($event) {
                      _vm.step--
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.plat.wfDesign.model.btn.prev")))]
              )
            : _vm._e(),
          _vm.step < 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.handleNextStep },
                },
                [_vm._v(_vm._s(_vm.$t("cip.plat.wfDesign.model.btn.next")))]
              )
            : _vm._e(),
          _vm.step == 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.saveBtn")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }