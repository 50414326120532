var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "head-layout",
        _vm._g(
          {
            attrs: {
              "head-title": _vm.$t(
                `cip.plat.resource.oss.title.indexHeadTitle`
              ),
              "head-btn-options": _vm.headBtnOptions,
            },
          },
          _vm.onHeadEvent
        )
      ),
      _c(
        "grid-head-layout",
        _vm._g(
          { ref: "searchFrom", attrs: { "search-columns": _vm.searchColumns } },
          _vm.onSearchEvent
        )
      ),
      _c(
        "avue-crud",
        {
          ref: "crud",
          staticClass: "ossBox",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.userInfo.role_name.includes("administrator")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDebug(scope.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cip.plat.resource.oss.btn.debugBtn")
                            ) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.permission.oss_enable
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleEnable(scope.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("cip.cmn.btn.enable")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.permission.oss_delete
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "small", plain: "" },
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("cip.cmn.btn.delBtn")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm.box
        ? _c(
            "common-dialog",
            {
              attrs: {
                dialogTitle: _vm.$t(
                  `cip.plat.resource.oss.title.debugHeadTitle`
                ),
                width: "550px",
                "show-btn": false,
              },
              on: {
                cancel: function ($event) {
                  _vm.box = false
                },
              },
            },
            [
              _c("avue-form", {
                ref: "form",
                attrs: { option: _vm.debugOption },
                on: { submit: _vm.handleSubmit },
                model: {
                  value: _vm.debugForm,
                  callback: function ($$v) {
                    _vm.debugForm = $$v
                  },
                  expression: "debugForm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }