var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(`cip.index.app.category.indexView`),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headShowLine,
                },
              }),
              _c("viewComponent", {
                ref: "viewComponent",
                attrs: { "sys-category-id": _vm.sysCategoryId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }