<!--人员选择-->
<template>
  <div>
    <tree-table-dialog
      ref="tableDialog"
      row-key="key"
      disabledCode="title"
      :disabled-list="disabledList"
      tree-key="id"
      :title="dialogTitle"
      :column="column"
      :single-selection="singleSelection"
      :search-columns="searchColumns"
      :select-page="selectPage"
      :tree="tree"
      :tree-props="treeProps"
      :tree-query="treeQuery"
      :selectable="selectable"
      :reserve-selection="reserveSelection"
      @close-dialog="closeDialog"
      @select-data="selectData"
    ></tree-table-dialog>
  </div>
</template>

<script>
import TreeTableDialog from "@/views/business/components/common/treeTableDialog";
import {getDeptTree as tree} from "@/api/system/dept";
import {selectUser as selectPage} from "@/api/system/user";
import {IS_ENABLE} from "@/util/dictConstant";
import {mapGetters} from "vuex";

export default {
  name: "leftDialog",
  components: {
    TreeTableDialog
  },
  props: {
    reserveSelection: {
      default: false,
      type: Boolean,
    },
    singleSelection: {
      type: Boolean,
      default: true,
    },
    closeDialog: {
      type: Function,
      default: (done) => {
        done()
      },
    },
    disabledList: {
      default: [],
      type: Array,
    },
    selectData: {
      type: Function,
      default: (data, done) => {
        done()
      },
    },
    treeQuery: {
      type: Object,
      default: () => ({
        parentId: 0,
        isEnable: IS_ENABLE.Y,
      })
    },
    title: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      dialogTitle: this.title ? this.title : this.$t('人员选择'),
      treeProps: {
        label: 'title',
        value: 'id',
      },
      column: [
        {
          label: this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
        },
        {
          label: this.$t("cip.plat.sys.user.field.deptName"),
          prop: "deptName",
        },
        {
          label: this.$t("cip.plat.sys.user.field.roleName"),
          prop: "roleName",
        },
      ],
      searchColumns: [
        {
          placeholder:this.$t(`cip.plat.sys.user.field.realName`),
          prop: "realName",
        },
        {
          placeholder: this.$t("cip.plat.sys.user.field.deptName"),
          prop: "deptName"
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      "userInfo"
    ]),
  },
  methods: {

    selectable(row){
      return this.disabledList.indexOf(row.realName) === -1
    },
    selectPage(...args) {
      return selectPage(...args);
    },
    tree() {
      return tree(this.userInfo.tenant_id)
    },
    openDialog(query = {}) {
      this.$refs.tableDialog.openDialog({
        ...query,
        isEnable: IS_ENABLE.Y
      })
    },
  }
}
</script>

<style scoped>

</style>
