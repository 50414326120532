var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c("div", { staticClass: "SNOWS-preview-main flow-form-main" }, [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _c("el-page-header", {
            attrs: { content: "预览表单【" + _vm.setting.fullName + "】" },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.cancelButton")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(_vm.currentView, {
            ref: "form",
            tag: "component",
            on: { close: _vm.goBack },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }