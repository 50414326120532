var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c("CommonTree", {
        attrs: {
          treeData: _vm.treeData,
          searchTitle: _vm.searchTitle,
          treeTitle: _vm.$t(`cip.plat.sys.user.title.treeTitle`),
          isShowdig: false,
          showCheckbox: false,
          defaultProps: _vm.defaultProps,
        },
        on: { getNodeClick: _vm.treeNodeClick },
      }),
      _c(
        "el-main",
        [
          _c("grid-layout", {
            ref: "gridLayout",
            attrs: {
              "data-total": _vm.dataTotal,
              tableOptions: _vm.tableOptions,
              tableData: _vm.tableDataComp,
              tableLoading: _vm.tableLoading,
              gridRowBtn: _vm.gridRowBtn,
            },
            on: {
              "page-size-change": _vm.onLoad,
              "page-current-change": _vm.onLoad,
              "page-refresh-change": _vm.onLoad,
              "update:tableData": function ($event) {
                _vm.tableDataComp = $event
              },
              "update:table-data": function ($event) {
                _vm.tableDataComp = $event
              },
              "row-check": _vm.rowCheck,
            },
            scopedSlots: _vm._u([
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: row.$disabledBtn,
                          size: "small",
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowCheck(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }