var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "formContent" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "相关方",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.subMit("ruleForm")
          },
        },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { padding: "10px" },
          attrs: {
            model: _vm.dataForm,
            rules: _vm.rules,
            disabled: _vm.disabled,
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: this.$t(
                          "cip.plat.message.template.parties.field.sendFromType"
                        ),
                        prop: "sendFromType",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled:
                              this.$route.query.type == "view" ||
                              this.$route.query.type == "",
                          },
                          model: {
                            value: _vm.dataForm.sendFromType,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "sendFromType", $$v)
                            },
                            expression: "dataForm.sendFromType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(
                              _vm._s(
                                this.$t(
                                  "cip.plat.message.template.parties.field.presetpPersonnel"
                                )
                              )
                            ),
                          ]),
                          _vm.mouldShow
                            ? _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(
                                  _vm._s(
                                    this.$t(
                                      "cip.plat.message.template.parties.field.inputParameter"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataForm.sendFromType == 1
                ? _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.sendAccount"
                            ),
                            prop: "sendSetValue",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder:
                                this.$t("cip.cmn.rule.inputWarning") +
                                this.$t(
                                  "cip.plat.message.template.parties.field.sendAccount"
                                ),
                              disabled: this.$route.query.type == "view",
                              readonly: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.showPersonDialog.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.dataForm.sendSetValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "sendSetValue", $$v)
                              },
                              expression: "dataForm.sendSetValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataForm.sendFromType == 2
                ? _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.inputParameter"
                            ),
                            prop: "sendSetParam",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder:
                                  this.$t("cip.cmn.rule.inputWarning") +
                                  this.$t(
                                    "cip.plat.message.template.parties.field.inputParameter"
                                  ),
                                disabled: this.$route.query.type == "view",
                              },
                              model: {
                                value: _vm.dataForm.sendSetParam,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "sendSetParam", $$v)
                                },
                                expression: "dataForm.sendSetParam",
                              },
                            },
                            _vm._l(_vm.sendSetParamOptions, function (item) {
                              return _c("el-option", {
                                key: item.paramKey,
                                attrs: {
                                  label: item.paramName,
                                  value: item.paramKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: this.$t(
                          "cip.plat.message.template.parties.field.receiveFromType"
                        ),
                        prop: "receiveFromType",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled:
                              this.$route.query.type == "view" ||
                              this.$route.query.type == "",
                          },
                          model: {
                            value: _vm.dataForm.receiveFromType,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "receiveFromType", $$v)
                            },
                            expression: "dataForm.receiveFromType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(
                              _vm._s(
                                this.$t(
                                  "cip.plat.message.template.parties.field.presetpPersonnel"
                                )
                              )
                            ),
                          ]),
                          _vm.mouldShow
                            ? _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(
                                  _vm._s(
                                    this.$t(
                                      "cip.plat.message.template.parties.field.inputParameter"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataForm.receiveFromType == 1
                ? _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.receiveSetType"
                            ),
                            prop: "receiveSetType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder:
                                  this.$t("cip.cmn.rule.inputWarning") +
                                  this.$t(
                                    "cip.plat.message.template.parties.field.receiveSetType"
                                  ),
                                disabled: this.$route.query.type == "view",
                              },
                              on: { change: _vm.chooseSetType },
                              model: {
                                value: _vm.dataForm.receiveSetType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "receiveSetType", $$v)
                                },
                                expression: "dataForm.receiveSetType",
                              },
                            },
                            _vm._l(_vm.receiveSetTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataForm.receiveFromType == 2
                ? _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.inputParameter"
                            ),
                            prop: "receiveSetParam",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder:
                                  this.$t("cip.cmn.rule.inputWarning") +
                                  this.$t(
                                    "cip.plat.message.template.parties.field.inputParameter"
                                  ),
                                disabled: this.$route.query.type == "view",
                              },
                              model: {
                                value: _vm.dataForm.receiveSetParam,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "receiveSetParam", $$v)
                                },
                                expression: "dataForm.receiveSetParam",
                              },
                            },
                            _vm._l(_vm.sendSetParamOptions, function (item) {
                              return _c("el-option", {
                                key: item.paramKey,
                                attrs: {
                                  label: item.paramName,
                                  value: item.paramKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              (_vm.dataForm.receiveSetType == 1 ||
                _vm.dataForm.receiveSetType == 3) &&
              _vm.dataForm.receiveFromType == 1
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.role"
                            ),
                            prop: "role",
                          },
                        },
                        [
                          _c("avue-input-tree", {
                            attrs: {
                              "default-expand-all": "",
                              multiple: true,
                              placeholder:
                                this.$t("cip.cmn.rule.selectWarning") +
                                this.$t(
                                  "cip.plat.message.template.parties.field.parentId"
                                ),
                              dic: _vm.roleDic,
                            },
                            model: {
                              value: _vm.dataForm.role,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "role", $$v)
                              },
                              expression: "dataForm.role",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.dataForm.receiveSetType == 2 ||
                _vm.dataForm.receiveSetType == 3) &&
              _vm.dataForm.receiveFromType == 1
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.organ"
                            ),
                            prop: "organ",
                          },
                        },
                        [
                          _c("avue-input-tree", {
                            attrs: {
                              "default-expand-all": "",
                              multiple: true,
                              placeholder:
                                this.$t("cip.cmn.rule.selectWarning") +
                                this.$t(
                                  "cip.plat.message.template.parties.field.organ"
                                ),
                              dic: _vm.organDic,
                            },
                            model: {
                              value: _vm.dataForm.organ,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "organ", $$v)
                              },
                              expression: "dataForm.organ",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.dataForm.receiveSetType == 5 &&
              _vm.dataForm.receiveFromType == 1
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: this.$t(
                              "cip.plat.message.template.parties.field.postId"
                            ),
                            prop: "postId",
                          },
                        },
                        [
                          _c("avue-input-tree", {
                            attrs: {
                              "default-expand-all": "",
                              multiple: true,
                              placeholder:
                                this.$t("cip.cmn.rule.selectWarning") +
                                this.$t(
                                  "cip.plat.message.template.parties.field.postId"
                                ),
                              dic: _vm.postIdOptions,
                            },
                            model: {
                              value: _vm.dataForm.postId,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "postId", $$v)
                              },
                              expression: "dataForm.postId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dataForm.receiveSetType == 4 && _vm.dataForm.receiveFromType == 1
        ? _c(
            "div",
            [
              _c("head-layout", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  "head-title": _vm.$t(
                    "cip.plat.message.template.parties.title.appointHeadTitle"
                  ),
                  "head-btn-options": _vm.gridHeadBtn,
                },
                on: {
                  "head-add-selection": _vm.headAddSelection,
                  "head-romve": _vm.handleDelete,
                },
              }),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.dataForm.receiveSetValue,
                    tableLoading: _vm.tableLoading,
                    size: "medium",
                  },
                  on: { "selection-change": _vm.selectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: this.$t(
                        "cip.plat.message.template.parties.field.sort"
                      ),
                      align: "center",
                      width: "55",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: _vm.$t("cip.plat.sys.user.field.realName"),
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deptName",
                      label: _vm.$t("cip.plat.sys.user.field.deptName"),
                      "show-overflow-tooltip": true,
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roleName",
                      label: _vm.$t("cip.plat.sys.user.field.roleName"),
                      "show-overflow-tooltip": true,
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contentFormat",
                      label: _vm.$t("cip.plat.sys.user.field.contentFormat"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                staticClass: "item",
                                attrs: {
                                  disabled:
                                    _vm.type == "detail" || _vm.type == "view",
                                  placeholder: _vm.$t(
                                    "cip.plat.sys.user.field.contentFormat"
                                  ),
                                },
                                model: {
                                  value: scope.row.contentFormat,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "contentFormat", $$v)
                                  },
                                  expression: "scope.row.contentFormat",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3832139145
                    ),
                  }),
                  this.$route.query.type != "view" &&
                  this.$route.query.type != ""
                    ? _c("el-table-column", {
                        attrs: {
                          label: this.$t(
                            "cip.plat.message.template.parties.field.operate"
                          ),
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.deleteRow(scope.$index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("cip.cmn.btn.delBtn")) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2214738371
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          staticClass: "avue-dialog avue-dialog--top",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.showDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("design-person", {
            ref: "designPerson",
            attrs: {
              "member-table-data": _vm.dataForm.receiveSetValue,
              headForm: _vm.headForm,
            },
            on: {
              "update:memberTableData": function ($event) {
                return _vm.$set(_vm.dataForm, "receiveSetValue", $event)
              },
              "update:member-table-data": function ($event) {
                return _vm.$set(_vm.dataForm, "receiveSetValue", $event)
              },
              "update:headForm": function ($event) {
                _vm.headForm = $event
              },
              "update:head-form": function ($event) {
                _vm.headForm = $event
              },
            },
          }),
        ],
        1
      ),
      _c("left-person", {
        ref: "leftDialog",
        attrs: { title: _vm.dialogTitle, "select-data": _vm.selectItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }