<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="80%"
    class="qmDialog"
    @close="closeDialog"
    :destroy-on-close="true"
  >
    <itemList
      v-if="showDialog"
      ref="itemList"
      :isIndex="false"
      @selectPlan="rowSelect"
    ></itemList>
  </el-dialog>
</template>
      
  <script>
import itemList from "@/views/business/contingency/emergencyPlan/index.vue";
export default {
  props: ["companyCode", "userId"],
  components: {
    itemList,
  },
  data() {
    return {
      showDialog: false,
      title: "",
    };
  },
  methods: {
    init() {
      this.showDialog = true;
      this.title = "选择预案";
    },
    // 选择公司
    rowSelect(row) {
      this.$emit("changeEmergencyPlan", row);
      this.closeDialog();
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
      