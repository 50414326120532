var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
        _vm.formShow
          ? _c("div", { staticClass: "formBox" }, [
              _c("div", { staticClass: "formTitleBox" }, [
                _c("div", { staticClass: "leftBack" }, [
                  _c(
                    "div",
                    {
                      staticClass: "backBox",
                      on: {
                        click: function ($event) {
                          _vm.formShow = false
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-back" }),
                      _vm._v("\n              返回\n            "),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "margin-left": "40px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.formTitle))]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "rightBtn" },
                  [
                    _c(
                      "el-button-group",
                      [
                        _vm.stepActive == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.preview },
                              },
                              [_vm._v("预览\n              ")]
                            )
                          : _vm._e(),
                        _vm.stepActive == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.stepActive = 1
                                  },
                                },
                              },
                              [_vm._v("下一步\n              ")]
                            )
                          : _vm._e(),
                        _vm.stepActive == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.stepActive = 0
                                  },
                                },
                              },
                              [_vm._v("上一步\n              ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.submitAllForm },
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.formShow = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "formContentBox" },
                [
                  _c(
                    "el-steps",
                    {
                      attrs: {
                        active: _vm.stepActive,
                        simple: "",
                        "finish-status": "success",
                      },
                    },
                    [
                      _c("el-step", {
                        attrs: { title: "填写主表信息", icon: "el-icon-edit" },
                      }),
                      _c("el-step", {
                        attrs: {
                          title: "填写子表信息",
                          icon: "el-icon-upload",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "formMain" }, [
                    _vm.stepActive == 0
                      ? _c("div", [
                          _c("div", { staticClass: "formTopic" }, [
                            _vm._v("填报要求"),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { padding: "12px" } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "form",
                                  attrs: {
                                    model: _vm.dataForm,
                                    "label-width": "150px",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "标题：" } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "title",
                                                  placeholder: "请输入标题",
                                                },
                                                model: {
                                                  value: _vm.dataForm.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "dataForm.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "表名称：" } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "tableName",
                                                  placeholder: "请输入表名称",
                                                },
                                                model: {
                                                  value: _vm.dataForm.tableName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "tableName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm.tableName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "角色：" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择角色",
                                                  },
                                                  on: {
                                                    change: _vm.chooseRole,
                                                  },
                                                  model: {
                                                    value: _vm.dataForm.roleId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm,
                                                        "roleId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm.roleId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.roleData,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.roleName,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "采集周期：" } },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  on: {
                                                    input: _vm.chooseCycle,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm
                                                        .acquisitionCycle,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm,
                                                        "acquisitionCycle",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm.acquisitionCycle",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "1" } },
                                                    [_vm._v("每天")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "2" } },
                                                    [_vm._v("每周")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "3" } },
                                                    [_vm._v("每月")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "4" } },
                                                    [_vm._v("每年")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "5" } },
                                                    [_vm._v("单次")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.dataForm.acquisitionCycle == 2 ||
                                  _vm.dataForm.acquisitionCycle == 3 ||
                                  _vm.dataForm.acquisitionCycle == 4
                                    ? _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 20 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "采集时间：",
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      type: "date",
                                                      format: "yyyy-MM-dd",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      placeholder:
                                                        "请选择采集时间",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dataForm
                                                          .acquisitionTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dataForm,
                                                          "acquisitionTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dataForm.acquisitionTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "通知时间：" } },
                                            [
                                              _vm._v(
                                                "\n                        通知周期动态变化，详细查看任务规则页面"
                                              ),
                                              _c("span", {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                },
                                              }),
                                              _vm._v(
                                                "填报周期：\n                        "
                                              ),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                model: {
                                                  value:
                                                    _vm.dataForm
                                                      .reportingPeriod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "reportingPeriod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm.reportingPeriod",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                        天\n                      "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "汇总周期：" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                model: {
                                                  value:
                                                    _vm.dataForm.summaryPeriod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "summaryPeriod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm.summaryPeriod",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                        天\n                      "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "上传附件：" } },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass: "upload-demo",
                                                  attrs: {
                                                    action:
                                                      "api/sinoma-resource/oss/endpoint/put-file",
                                                    "on-success":
                                                      _vm.handleSuccess,
                                                    "on-remove":
                                                      _vm.handleRemove,
                                                    headers: _vm.headers,
                                                    multiple: "",
                                                    limit: 1,
                                                    "file-list": _vm.fileList,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "点击上传\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      attrs: { slot: "tip" },
                                                      slot: "tip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            支持扩展名：.rar .zip .doc .docx .pdf .jpg...\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "填写说明：" } },
                                            [
                                              _c("el-input", {
                                                attrs: { type: "textarea" },
                                                model: {
                                                  value:
                                                    _vm.dataForm
                                                      .acquisitionDescription,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "acquisitionDescription",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm.acquisitionDescription",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "formTopic" }, [
                            _vm._v("填报单位"),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "12px",
                                display: "flex",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1", border: "" },
                                  model: {
                                    value: _vm.radio1,
                                    callback: function ($$v) {
                                      _vm.radio1 = $$v
                                    },
                                    expression: "radio1",
                                  },
                                },
                                [_vm._v("常用分组一\n                ")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2", border: "" },
                                  model: {
                                    value: _vm.radio1,
                                    callback: function ($$v) {
                                      _vm.radio1 = $$v
                                    },
                                    expression: "radio1",
                                  },
                                },
                                [_vm._v("常用分组二\n                ")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "3", border: "" },
                                  model: {
                                    value: _vm.radio1,
                                    callback: function ($$v) {
                                      _vm.radio1 = $$v
                                    },
                                    expression: "radio1",
                                  },
                                },
                                [_vm._v("常用分组三\n                ")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "4", border: "" },
                                  model: {
                                    value: _vm.radio1,
                                    callback: function ($$v) {
                                      _vm.radio1 = $$v
                                    },
                                    expression: "radio1",
                                  },
                                },
                                [_vm._v("常用分组四\n                ")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "shuttleBackBox" }, [
                            _c("div", { staticClass: "shutleBack" }, [
                              _c("div", { staticClass: "shutleTitle" }, [
                                _vm._v("组织架构"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "shutContent" },
                                [
                                  _c("el-tree", {
                                    ref: "tree",
                                    attrs: {
                                      data: _vm.deptTree,
                                      props: _vm.defaultProps,
                                      "node-key": "id",
                                    },
                                    on: { "node-click": _vm.handleNodeClick },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "leftRightBtn" },
                              [
                                _c(
                                  "el-button-group",
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                        icon: "el-icon-arrow-left",
                                      },
                                    }),
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                        icon: "el-icon-arrow-right",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "shutleBack" }, [
                              _c("div", { staticClass: "shutleTitle" }, [
                                _vm._v("填报单位"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "shutContent" },
                                _vm._l(
                                  _vm.middleShutData,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "deptBox" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.organize)),
                                        ]),
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          staticStyle: {
                                            color: "#f56c6c",
                                            "font-size": "20px",
                                            "line-height": "unset",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteMiddleShut(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "leftRightBtn" },
                              [
                                _c(
                                  "el-button-group",
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                        icon: "el-icon-arrow-left",
                                      },
                                    }),
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                        icon: "el-icon-arrow-right",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "shutleBack" }, [
                              _c("div", { staticClass: "shutleTitle" }, [
                                _vm._v("汇总单位"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "shutContent" },
                                _vm._l(
                                  _vm.rightShutData,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "deptBox" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.organize)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.stepActive == 1
                      ? _c("div", { staticStyle: { height: "100%" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "12px 0",
                                display: "flex",
                                "justify-content": "space-between",
                                height: "calc(100% - 24px)",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "leftClass" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "primary",
                                        size: "medium",
                                        icon: "el-icon-circle-plus-outline",
                                      },
                                      on: { click: _vm.addClass },
                                    },
                                    [_vm._v("添加分类\n                  ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "classDataBox" },
                                    _vm._l(
                                      _vm.classList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "classLittle",
                                            class:
                                              _vm.classActive == index
                                                ? "classActive"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.chooseTableData(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-edit",
                                              staticStyle: {
                                                "line-height": "unset",
                                                color: "#e6a23c",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editClass(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.groupName) +
                                                  " - " +
                                                  _vm._s(item.columnNum) +
                                                  "列"
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              staticStyle: {
                                                "line-height": "unset",
                                                color: "#f56c6c",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteClass(index)
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "rightTable" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "table",
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        data: _vm.properties,
                                        border: "",
                                        size: "medium",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "date",
                                          label: "排序",
                                          align: "center",
                                          type: "index",
                                          width: "80",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "fieldCode",
                                          label: "数据项编号",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入数据项编号",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.fieldCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "fieldCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.fieldCode",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1346286253
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "fieldName",
                                          label: "数据项名称",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入数据线名称",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.fieldName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "fieldName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.fieldName",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2090389661
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "type",
                                          label: "类型",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择类型",
                                                      },
                                                      model: {
                                                        value: scope.row.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.type",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.typeOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.dictKey,
                                                          attrs: {
                                                            label:
                                                              item.dictValue,
                                                            value: item.dictKey,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          881340059
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "isEnable",
                                          label: "是否必填",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择是否必填",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.isEnable,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "isEnable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.isEnable",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.yesOrNoOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.dictKey,
                                                          attrs: {
                                                            label:
                                                              item.dictValue,
                                                            value: item.dictKey,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2959949796
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "isSummary",
                                          label: "是否汇总",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择是否汇总",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.isSummary,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "isSummary",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.isSummary",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.yesOrNoOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.dictKey,
                                                          attrs: {
                                                            label:
                                                              item.dictValue,
                                                            value: item.dictKey,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1002976795
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "inputPrompt",
                                          label: "输入提示",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入输入提示",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.inputPrompt,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "inputPrompt",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.inputPrompt",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3497767114
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "inputLength",
                                          label: "录入长度",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入录入长度",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.inputLength,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "inputLength",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.inputLength",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2131943079
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "dictionaryCode",
                                          label: "字典编码",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入字典编码",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .dictionaryCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "dictionaryCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.dictionaryCode",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          976128969
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "inputWay",
                                          label: "输入方式",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择输入方式",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.inputWay,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "inputWay",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.inputWay",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.inputWayOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.dictKey,
                                                          attrs: {
                                                            label:
                                                              item.dictValue,
                                                            value: item.dictKey,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2641079115
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "calculationRules",
                                          label: "计算公式",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入计算公式",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .calculationRules,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "calculationRules",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.calculationRules",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          609031365
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addTable(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "新增\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteTable(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "删除\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3528707526
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.classTitle,
            visible: _vm.classShow,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.classShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "classForm",
              attrs: {
                model: _vm.classForm,
                "label-width": "80px",
                rules: _vm.classRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "groupName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.classForm.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.classForm, "groupName", $$v)
                      },
                      expression: "classForm.groupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类列数", prop: "columnNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类列数" },
                    model: {
                      value: _vm.classForm.columnNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.classForm, "columnNum", $$v)
                      },
                      expression: "classForm.columnNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.classShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitClass } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "预览", visible: _vm.previewShow, width: "70%" },
          on: {
            "update:visible": function ($event) {
              _vm.previewShow = $event
            },
          },
        },
        [_c("preview", { ref: "preview" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }