var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "headers" },
        [
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: {
              "grid-head-btn": _vm.gridHeadBtn,
              "search-columns": _vm.searchColumns,
            },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.dataTotal,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-row-detail-click": _vm.rowDetail,
          "row-remove": _vm.rowRemove,
          "row-view": _vm.rowDetail,
        },
      }),
      _c("dyn-table-dialog", {
        ref: "dynTableDialog",
        attrs: { headForm: _vm.dataForm, type: _vm.type },
        on: {
          "update:headForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:head-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }