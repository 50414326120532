var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              treeTitle: _vm.$t(`cip.plat.message.list.title.msgTypeTitle`),
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.message.list.title.indexHeadTitle`
                  ),
                },
                on: {
                  "head-readAll": _vm.readAll,
                  "head-message-setting": _vm.headMessageSetting,
                  "head-read": _vm.headRead,
                  "head-remove": _vm.headRemove,
                },
              }),
              _c(
                "div",
                { staticClass: "headers" },
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: {
                      "grid-head-btn": _vm.gridHeadBtn,
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                ],
                1
              ),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "row-view": _vm.rowDetail,
                  "row-remove": _vm.rowDel,
                  "gird-handle-select-click": _vm.selectionChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.msg.msgTile,
            visible: _vm.dialogVisible,
            "show-close": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("cip.plat.message.list.field.createTime")) +
                "：" +
                _vm._s(_vm.msg.createTime)
            ),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("cip.plat.message.list.field.msgContent")) + "："
            ),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.msg.msgContent) } }),
          ]),
          _vm.attachment.length > 0
            ? _c(
                "div",
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("cip.plat.message.list.field.attachment")) +
                        "："
                    ),
                  ]),
                  _vm._l(_vm.attachment, function (item) {
                    return _c(
                      "span",
                      {
                        key: item.id,
                        staticStyle: {
                          color: "#1890ff",
                          cursor: "pointer",
                          "text-decoration": "underline",
                          "margin-right": "10px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.originalName))]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.images.length > 0
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("cip.plat.message.list.field.picture")) + "："
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  _vm._l(_vm.images, function (item) {
                    return _c("img", {
                      key: item.id,
                      staticStyle: {
                        width: "50px",
                        height: "50px",
                        "margin-right": "10px",
                      },
                      attrs: { src: item.link, alt: "" },
                    })
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.videos.length > 0
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("cip.plat.message.list.field.video")) + "："
                    ),
                  ]),
                  _vm._l(_vm.videos, function (item) {
                    return _c(
                      "span",
                      {
                        key: item.id,
                        staticStyle: {
                          color: "#1890ff",
                          cursor: "pointer",
                          "text-decoration": "underline",
                          "margin-right": "5px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openVideo(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.link))]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.pcUrl
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.goToUrl } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("cip.plat.message.list.field.buttonName"))
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeDialog } },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(`cip.plat.message.list.title.msgSettingTitle`),
            visible: _vm.settingVisible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.settingVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "cip.plat.message.list.title.notifySettingTitle"
                    ),
                  },
                },
                [
                  _c("el-tree", {
                    ref: "msgSetting",
                    attrs: {
                      data: _vm.treeData,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.userMsgSettingObj,
                      props: _vm.props,
                      "default-expand-all": "",
                      "check-strictly": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "cip.plat.message.list.title.bcstSettingTitle"
                    ),
                  },
                },
                [
                  _c("el-tree", {
                    ref: "bcstSetting",
                    attrs: {
                      data: _vm.treeData,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.userBcstSettingObj,
                      props: _vm.props,
                      "default-expand-all": "",
                      "check-strictly": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.settingVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoVisible,
            "append-to-body": "",
            width: "30%",
            height: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoVisible = $event
            },
            close: _vm.closeVideo,
          },
        },
        [
          _c("video", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              controls: "",
              controlsList: "nodownload",
              src: _vm.openVideoUrl,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }