<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.message.channel.title.indexHeadTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
    </grid-layout>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  remove,
  selectPage,
  saveOrUpdate
} from '@/api/message/channel'

export default {
  name: "channel",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          label: this.$t(`cip.plat.message.channel.field.channelCode`),
          prop: "channelCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.plat.message.channel.field.channelCode`),
        },
        {
          label: this.$t(`cip.plat.message.channel.field.channelName`),
          prop: "channelName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.plat.message.channel.field.channelName`),
        },
        {
          label: this.$t(`cip.plat.message.channel.field.channelType`),
          prop: "channelType",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.plat.message.channel.field.channelType`),
          type:'select',
          dicUrl:  `${baseUrl}channel_type`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        linklabel: "channelCode",
        selection: false,
        column: [
          {
            label: this.$t(`cip.plat.message.channel.field.channelCode`),
            prop: 'channelCode',
            overHidden: true,
            align: 'center'
          },
          {
            label: this.$t(`cip.plat.message.channel.field.channelName`),
            prop: 'channelName',
            overHidden: true,
            align: 'center'
          },
          // {
          //   label: '应用范围',
          //   prop: 'sourceCode',
          //   overHidden: true,
          //   align: 'center'
          // },
          {
            label: this.$t(`cip.plat.message.channel.field.channelType`),
            prop: 'channelType',
            dicUrl:  `${baseUrl}channel_type`,
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true
          },
          {
            label: this.$t(`cip.plat.message.channel.field.status`),
            labelWidth: 130,
            prop: "status",
            type: 'select',
            // dicData: [
            //   {
            //     label: this.$t('cip.cmn.btn.enable'),
            //     value: 1
            //   },
            //   {
            //     label: this.$t('cip.cmn.btn.disable'),
            //     value: 0
            //   }
            // ],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=EnableOrNot",
            align: "center",
          },
          {
            label: this.$t(`cip.plat.message.channel.field.createTime`),
            prop: 'createTime',
            align: "center",
            overHidden: true
          },
          // {
          //   label: '创建人',
          //   prop: 'createUserName',
          //   align: "center",
          //   overHidden: true
          // }
        ],
      }
    },

    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.channel_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.channel_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      return result;
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.channel_add) {
        result.push( {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.channel_delete) {
        result.push( {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result;
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    handleBlack(row, type) {
      let params = {
        id: row.id,
        type: type
      }
      updateEnable(params).then(
        res => {
          if (res.data.code === 200) {
            if (type===1){
              this.$message.success(res.data.msg)
            }else {
              this.$message.warning(res.data.msg)
            }
            this.onLoad(this.page);

          }
        })
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (ids.length === 0) {
            //只选中了空数据
            let index = 0;
            selectionList.filter(e => !e.id).forEach(e => {
              this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
              index++
            })
            this.$refs.gridLayOut.selectionClear()
            return;
          }
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.selectionClear()
            });
        }).catch(() => {
      })
    },
    headAddTabs() {
      this.$router.push({
        path: `/message/channel/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/message/channel/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/message/channel/view`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
