var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        overflow: "hidden",
        padding: "10px",
        "background-color": "#fff",
      },
    },
    [
      _c(
        "div",
        { staticClass: "topContainer" },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                "margin-top": "auto",
                "margin-bottom": "auto",
              },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "el-button",
            { staticStyle: { margin: "10px" }, on: { click: _vm.celcen } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "SNOWS-el_tabs",
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { label: "流程信息", name: "comment" },
            },
            [
              _vm.processShow
                ? _c("Process", { attrs: { conf: _vm.flowTemplateJson } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { label: "流转记录" },
            },
            [
              _vm.processShow
                ? _c("recordList", {
                    attrs: {
                      list: _vm.flowTaskOperatorRecordList,
                      flowId: _vm.setting.flowId,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }