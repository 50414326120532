<template>
  <div v-loading="loading" class="dashboard-container">
    <template v-if="!ajaxing">
      <template v-if="show">
        <PortalLayout v-if="type === 0" :layout="layout" />
        <div v-if="type === 1" class="custom-page">
          <component :is="currentView" v-if="linkType === 0" />
          <embed v-if="linkType === 1" :src="url" height="100%" type="text/html" width="100%" />
        </div>
      </template>
      <div v-if="!show" class="portal-layout-nodata">
        <p v-if="!show" class="layout-nodata-txt">暂无数据</p>
      </div>
    </template>
  </div>


</template>

<script>
import { getEnablePortalById } from '@/api/onlineDev/portal'
import PortalLayout from '@/components/VisualPortal/Layout'
import { mapGetters } from 'vuex'
import { updateAreaData } from "@/api/base/region";

export default {
  name: 'dashboard',
  components: { PortalLayout },
  data() {
    return {
      isFirstLogin: '',
      passwdOption: {
        column: [{
          label: this.$t('userinfo.oldpasswd'),
          span: 24,
          type: 'password',
          prop: 'oldPassword',
        }, {
          label: this.$t('userinfo.newPasswd'),
          span: 24,
          maxlength: 30,
          type: 'password',
          prop: 'newPassword',
          rules: [
            {
              pattern: /^[^\s]*$/,
              message: this.$t("cip.cmn.rule.removeSpacesWarning"),
            },
          ],
        }, {
          label: this.$t('userinfo.confirmPasswd'),
          span: 24,
          type: 'password',
          prop: 'newPassword1',
        }]
      },
      passwdForm: {},
      layout: [],
      type: null,
      linkType: null,
      currentView: null,
      url: '',
      ajaxing: true,
      loading: false,
      show: false
    }
  },
  watch: {
    portalId: function (n) {
      this.getPortalData(n)
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'portalId'])
  },
  created() {
    this.getPortalData();
    // updateAreaData();
  },
  methods: {
    getPortalData() {
      this.loading = true;
      this.show = false;
      this.layout = []
      getEnablePortalById(this.portalId).then(res => {
        let result = res.data.data
        this.type = result.type || 0
        this.linkType = result.linkType || 0
        this.url = result.customUrl
        if (result) {
          this.show = true;
          if (result.type === 1) {
            if (result.customUrl && result.customUrl !== 1) {
              this.currentView = (resolve) => require([`@/views/${result.customUrl}`], resolve)
            }
          } else {
            if (result.formData) {
              let formData = JSON.parse(result.formData)
              this.layout = formData.layout || []
            }
          }
        }
        this.ajaxing = false
        setTimeout(() => {
          this.loading = false
        }, 500);
      }).catch(() => {
        this.loading = false
        this.ajaxing = false
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

// ::v-deep .el-table .cell {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: normal;
//   word-break: break-all;
//   line-height: 32px;
// }

.dashboard-container {
  width: 100%;
  height: 100%;
  background: #ebeef5;
  position: relative;

  .custom-page {
    width: 100%;
    height: 100%;
  }

  ::v-deep .layout-area {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
  }

  .setting-btn {
    position: absolute;
    top: 200px;
    right: -10px;
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 0;
    border-radius: 20px 0 0 20px;
    z-index: 100;

    ::v-deep i {
      font-size: 20px;
      font-weight: 580;
    }
  }

  ::v-deep .vue-grid-layout {
    margin: -10px;
  }

  ::v-deep .el-scrollbar__view {
    overflow: hidden;
  }

  .img1 {
    width: 100%;
    height: 100%;
    // padding: 10px;
    margin: 0 auto;
  }

}
</style>
