var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _c(
        "div",
        { staticClass: "SNOWS-common-layout-center" },
        [
          _c("head-layout", { attrs: { "head-title": "我发起的" } }),
          _c(
            "el-row",
            { staticClass: "SNOWS-common-search-box", attrs: { gutter: 16 } },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入流程标题",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.fullName,
                              callback: function ($$v) {
                                _vm.fullName = $$v
                              },
                              expression: "fullName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "picker-options": _vm.pickerOptions,
                              "value-format": "timestamp",
                              clearable: "",
                              editable: false,
                            },
                            model: {
                              value: _vm.pickerVal,
                              callback: function ($$v) {
                                _vm.pickerVal = $$v
                              },
                              expression: "pickerVal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择流程状态",
                                clearable: "",
                              },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            },
                            _vm._l(_vm.statusList, function (item, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: item.fullName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择紧急程度",
                                clearable: "",
                              },
                              model: {
                                value: _vm.urgent,
                                callback: function ($$v) {
                                  _vm.urgent = $$v
                                },
                                expression: "urgent",
                              },
                            },
                            _vm._l(_vm.urgentList, function (item, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: item.fullName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button-group",
                        [
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.search()
                              },
                            },
                          }),
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              icon: "reset-refresh icon-refresh",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.refresh()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "SNOWS-common-layout-main SNOWS-flex-main" },
            [
              _c(
                "SNOWS-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fullName",
                      label: "流程标题",
                      "show-overflow-tooltip": "",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "flowName",
                      label: "所属流程",
                      width: "130",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startTime",
                      label: "发起时间",
                      width: "130",
                      formatter: _vm.snows.tableDateFormat,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "thisStep",
                      label: "审批节点",
                      width: "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "flowUrgent",
                      label: "紧急程度",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.flowUrgent == 2
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("urgentText")(scope.row.flowUrgent)
                                    )
                                  ),
                                ])
                              : scope.row.flowUrgent == 3
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("urgentText")(scope.row.flowUrgent)
                                    )
                                  ),
                                ])
                              : _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("urgentText")(scope.row.flowUrgent)
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "流程状态",
                      width: "130",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 1
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("等待审核"),
                                ])
                              : scope.row.status == 2
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("审核通过"),
                                ])
                              : scope.row.status == 3
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("审核退回"),
                                ])
                              : scope.row.status == 4
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("流程撤回"),
                                ])
                              : scope.row.status == 5
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("审核终止"),
                                ])
                              : _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("等待提交"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "completion",
                      label: "流程进度",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 5 || scope.row.completion == 0
                              ? _c("p", { staticClass: "text-grey" }, [
                                  _vm._v("----"),
                                ])
                              : scope.row.completion == 100
                              ? _c("p", [_vm._v("已完成")])
                              : _c("el-progress", {
                                  attrs: { percentage: scope.row.completion },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "200", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled:
                                    [1, 2, 4, 5].indexOf(scope.row.status) > -1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetail(scope.row, "-1")
                                  },
                                },
                              },
                              [_vm._v("编辑\n              ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "SNOWS-table-delBtn",
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled:
                                    [1, 2, 3, 5].indexOf(scope.row.status) > -1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDel(
                                      scope.$index,
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除\n              ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled: !scope.row.status,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetail(scope.row, 0)
                                  },
                                },
                              },
                              [_vm._v("详情\n              ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toProcess(scope.row)
                                  },
                                },
                              },
                              [_vm._v("流程追踪")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.currentPage,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.initData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.processVisible
        ? _c("FlowChart", { ref: "flowChart", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.flowVisible
        ? _c("flow", {
            ref: "flow",
            on: {
              close: function ($event) {
                _vm.flowVisible = false
              },
              choiceFlow: _vm.choiceFlow,
            },
          })
        : _vm._e(),
      _vm.formVisible
        ? _c("FlowBox", { ref: "FlowBox", on: { close: _vm.closeForm } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }