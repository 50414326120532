var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "发送通道",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-remove": _vm.headDeleteSelection, "head-add": _vm.headAdd },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "update:tableData": function ($event) {
            _vm.tableData = $event
          },
          "update:table-data": function ($event) {
            _vm.tableData = $event
          },
          "grid-cell-click": _vm.cellClick,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-delete": _vm.rowDelete,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.formatEdit(row)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`cip.plat.message.template.channel.btn.editBtn`)
                      ) + "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.showDialogChanel
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "70%",
                showBtn: false,
                dialogTitle: _vm.$t(
                  `cip.plat.message.template.channel.title.channelHeadTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.showDialogChanel = false
                },
              },
            },
            [
              _c("ms-channel-dialog", {
                ref: "msChannelDialog",
                attrs: {
                  name: "templateParties",
                  "member-table-data": _vm.tableData,
                  headForm: _vm.headForm,
                  formatEdit: _vm.formatEdit,
                },
                on: {
                  "update:memberTableData": function ($event) {
                    _vm.tableData = $event
                  },
                  "update:member-table-data": function ($event) {
                    _vm.tableData = $event
                  },
                  "update:headForm": function ($event) {
                    _vm.headForm = $event
                  },
                  "update:head-form": function ($event) {
                    _vm.headForm = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showDialog
        ? _c(
            "CommonDialog",
            {
              staticClass: "qmDialog",
              attrs: {
                width: "60%",
                dialogTitle: _vm.$t(
                  `cip.plat.message.template.channel.title.headItemTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.showDialog = false
                },
                confirm: _vm.formatSave,
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.treeDialogOption },
                model: {
                  value: _vm.formatform,
                  callback: function ($$v) {
                    _vm.formatform = $$v
                  },
                  expression: "formatform",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }