var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "avue-crud",
                    {
                      ref: "crud",
                      attrs: {
                        option: _vm.option,
                        "table-loading": _vm.loading,
                        data: _vm.dataParent,
                        page: _vm.pageParent,
                        permission: _vm.permissionList,
                        "before-open": _vm.beforeOpen,
                      },
                      on: {
                        "row-del": _vm.rowDel,
                        "row-update": _vm.rowUpdate,
                        "row-save": _vm.rowSave,
                        "row-click": _vm.handleRowClick,
                        "search-change": _vm.searchChange,
                        "search-reset": _vm.searchReset,
                        "selection-change": _vm.selectionChange,
                        "current-change": _vm.currentChange,
                        "size-change": _vm.sizeChange,
                        "refresh-change": _vm.refreshChange,
                        "on-load": _vm.onLoadParent,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "isSealed",
                          fn: function ({ row }) {
                            return [
                              _c("el-tag", [
                                _vm._v(
                                  _vm._s(row.isSealed === 0 ? "否" : "是")
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.formParent,
                        callback: function ($$v) {
                          _vm.formParent = $$v
                        },
                        expression: "formParent",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "menuLeft" },
                        [
                          _vm.permission.dict_delete
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    icon: "el-icon-delete",
                                    plain: "",
                                  },
                                  on: { click: _vm.handleDelete },
                                },
                                [_vm._v("删 除\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "basic-container",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "avue-crud",
                    {
                      ref: "crudChild",
                      attrs: {
                        option: _vm.option,
                        "table-loading": _vm.loading,
                        data: _vm.dataChild,
                        page: _vm.pageChild,
                        permission: _vm.permissionList,
                        "before-open": _vm.beforeOpenChild,
                      },
                      on: {
                        "row-del": _vm.rowDelChild,
                        "row-update": _vm.rowUpdateChild,
                        "row-save": _vm.rowSaveChild,
                        "search-change": _vm.searchChangeChild,
                        "search-reset": _vm.searchResetChild,
                        "selection-change": _vm.selectionChangeChild,
                        "current-change": _vm.currentChangeChild,
                        "size-change": _vm.sizeChangeChild,
                        "refresh-change": _vm.refreshChangeChild,
                        "on-load": _vm.onLoadChild,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "isSealed",
                          fn: function ({ row }) {
                            return [
                              _c("el-tag", [
                                _vm._v(
                                  _vm._s(row.isSealed === 0 ? "否" : "是")
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.formChild,
                        callback: function ($$v) {
                          _vm.formChild = $$v
                        },
                        expression: "formChild",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "menuLeft" },
                        [
                          _vm.permission.dict_delete
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    icon: "el-icon-delete",
                                    plain: "",
                                  },
                                  on: { click: _vm.handleDelete },
                                },
                                [_vm._v("删 除\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }