<template>
  <div>
    <el-container>
      <CommonTree ref="CommonTree"  :treeData="treeData" :defaultProps="defaultProps" :searchTitle="searchTitle"
        :treeTitle="$t(`cip.index.app.category.treeHeader`)" :isShowdig="true" :showCheckbox="false" :node-key="'id'"
        @getTreeAdd="treeMenuAdd" @getTreeEdit="treeMenuEdit" @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick" />
      <el-main>
        <head-layout :head-btn-options="headBtnOptions" :head-title="$t(`cip.index.app.category.listHeader`)"
          @head-remove="headRemove" @head-add-tabs="headAdd"></head-layout>
        <queryIndexManageWithCategory ref="queryIndexManageWithCategory" :dataFormExt.sync="dataFormExt" :test="test"
          :sys-category-id="sysCategoryId"></queryIndexManageWithCategory>
      </el-main>
    </el-container>
    <addIndexManageWithCategoryWindow ref="addIndexManageWithCategoryWindow" @saveExt="saveExt">
    </addIndexManageWithCategoryWindow>
    <el-dialog v-dialog-drag :title="$t(`cip.index.app.category.treeHeader`)" :modal="false" :modal-append-to-body="false"
      :close-on-click-modal="false" :visible.sync="showTreeDialog" v-if="showTreeDialog" width="60%"
      :fullscreen="fullscreen" class="qmDialog">
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form :option="treeDialogOption" v-model="form" ref="formLayout"></avue-form>
      <span slot="footer">
        <el-button size="small" @click="showTreeDialog = false">{{ $t(`cip.cmn.btn.celBtn`) }}</el-button>
        <el-button size="small" type="primary" @click="treeNodeSave">{{ $t(`cip.cmn.btn.defBtn`) }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index"
import queryIndexManageWithCategory from "@/views/business/index/appCategory/common/queryIndexManageWithCategory";
import ServerNameEnum from '@/util/ServerNameEnum';
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import {
  tree,
  removeTree,
  saveOrUpdateTree,
  checkCategoryCodeExits,
  removeCategoryWithIndex
} from "@/api/index/appSystemCategory";
import addIndexManageWithCategoryWindow
  from "@/views/business/index/appCategory/common/addIndexManageWithCategoryWindow.vue";

const CONTROLLER_NAME = "/appSystemCategory";

export default {
  name: "index",
  props: {
    dataFormExt: {},
  },
  data() {
    return {
      test: 1,
      page: {
        currentPage: 1,
        dataTotal: 0,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
      },
      form: {
        parentId: 0
      },
      treeReadOnlyFlag: false,
      tableData: [],
      drawerBox: false,
      treeLoading: false,
      tableLoading: false,
      treeData: [],
      sysCategoryId: '', // 点击左侧树的code
      node: {},   //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      searchTitle: 'categoryName',
      defaultProps: {
        label: "categoryName",
        value: "key",
        key: "categoryCode"
      },

    };
  },
  computed: {
    ...mapGetters(["permission"]),
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.index.app.category.parentCode"),
            prop: "parentId",
            type: "tree",
            props: {
              label: 'categoryName',
              value: 'id',
              key: 'parentId',

            },
            placeholder: this.$t(`cip.index.app.category.parentCode`),
            dicUrl: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/tree`,


          },
          {
            label: this.$t(`cip.index.app.category.categoryCode`),
            prop: "categoryCode",
            maxlength: 20,
            readonly: this.treeReadOnlyFlag,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.categoryCode`),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t(`cip.index.app.category.categoryName`),
            prop: "categoryName",
            maxlength: 20,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.categoryName`),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t(`cip.index.app.category.sort`),
            prop: "sort",
            type: 'number',
            controls: false,
            precision: 0,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.sort`),
                trigger: "blur",
              },
            ],
          },

          {
            label: '自动同步',
            prop: 'isAuto',
            type: 'radio',
            labelWidth: 130,
            props: {
              label: "label",
              value: "value"
            },
            dataType: 'number',
            dicData: [
              {
                label: '是',
                value: 1,
              },
              {
                label: '否',
                value: 0
              },

            ],
            value: '0',
          },


        ]
      }
    },
  },
  components: {
    addIndexManageWithCategoryWindow,
    GridLayout,
    HeadLayout,
    CommonTree,
    queryIndexManageWithCategory
  },
  created() {
    this.initTree();
  },
  methods: {

    getSelectionList() {
      return this.$refs.queryIndexManageWithCategory.$refs.gridLayOut.selectionList
    },
    saveExt() {
      this.$refs.queryIndexManageWithCategory.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, { sysCategoryId: this.sysCategoryId });
    },
    //新增主题
    headAdd() {
      this.$refs.addIndexManageWithCategoryWindow.openDialog(this.sysCategoryId);
    },
    headRemove() {
      let selectionList = this.$refs.queryIndexManageWithCategory.ids('mappingId');
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return removeCategoryWithIndex({ indexMappingVOS: selectionList })
            .then(() => {
              this.$refs.queryIndexManageWithCategory.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, { sysCategoryId: this.sysCategoryId });
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.queryIndexManageWithCategory.selectionList = null;

            });
        }).catch(() => {
        })
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd() {
      this.treeReadOnlyFlag = false;
      this.form = {};
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      console.log(data,'编辑data');


      let aa =  JSON.parse(JSON.stringify(this.treeData));
        aa =  this.flatten(aa)
        aa.map((item) =>{
          if(item.id == data.id){
            console.log(item,data);
            data = item
          }
        })

      if (data.isAuto == 1) {
        this.$message({
          message: '此指标体系为自动同步，不允许手工编辑，删除！',
          type: 'warning'
        });
      } else {
        this.treeReadOnlyFlag = true;
        this.fullscreen = false;
        this.showTreeDialog = true;
        this.form = data;
      }

    },

    initTree(ischeck = false) {
      tree({ parentId: 0 }).then(res => {
        const { data } = res.data;
        this.treeData = data;
        if (ischeck) {
          this.$refs.CommonTree.checkNode()
        }
      });
    },
    // 新增左侧树节点
    treeNodeSave() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          if (this.node.id == this.form.parentId) {
            return this.$message.error('节点不能以自己为父节点！！')
          }

          let aa =  JSON.parse(JSON.stringify(this.treeData));
               aa =  this.flatten(aa)
               aa.map(element => {
                if(element.id == this.form.parentId && element.isAuto==1){
                  return this.$message.error('该节点自动同步，不能为父节点！！')
                }
               });

          if (this.form.id) {
            this.saveCategory();

          } else {
            checkCategoryCodeExits(this.form).then(res => {
              if (res.data.data) {
                this.$message.warning(this.$t('cip.index.app.category.errorMsg1'));
                return;
              }
              this.saveCategory();
            })
          }
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },
    saveCategory() {
      saveOrUpdateTree(this.form).then(res => {
        const { msg, data } = res.data;
        if (data.code === 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        } else {
          this.$message.success(msg);
        }
        this.initTree(true)
        this.showTreeDialog = false;
        this.$refs.formLayout.$refs.form.allDisabled = false
        this.$refs.CommonTree.$refs.commonTree.setCurrentKey(this.sysCategoryId)

      })
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }
      if (this.$refs.queryIndexManageWithCategory.tableData.length > 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasIndicators'))
        return
      }


      if (data.isAuto == 1) {
        this.$message({
          message: '此指标体系为自动同步，不允许手工编辑，删除！',
          type: 'warning'
        });
        return
      }



      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(() => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          this.$refs.queryIndexManageWithCategory.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, {});
          done()
        })
      }).catch(() => {
      });
    },


    // 点击左侧树节点
    treeNodeClick(node) {

      this.test += 1
      this.node = node;
      console.log(this.node,"点击node",);
      this.sysCategoryId = node.id;

      // this.$refs.queryIndexManageWithCategory.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
    },
    close() {
      this.drawerBox = false;
      this.page.currentPage = 1;
      this.$refs.queryIndexManageWithCategory.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, { sysCategoryId: this.sysCategoryId });
    },

    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = [];
          result = result.concat(arr[i]);
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },


  },
};
</script>


