var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            { attrs: { width: "200px" } },
            [
              _c("wf-category", {
                on: {
                  "node-click": _vm.nodeClick,
                  "list-change": function ($event) {
                    _vm.findObject(_vm.option.column, "categoryId").dicData =
                      $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-main",
            { staticStyle: { "margin-left": "10px" } },
            [
              _c(
                "avue-crud",
                {
                  attrs: {
                    option: _vm.option,
                    "table-loading": _vm.loading,
                    data: _vm.data,
                    page: _vm.page,
                    search: _vm.query,
                  },
                  on: {
                    "update:page": function ($event) {
                      _vm.page = $event
                    },
                    "update:search": function ($event) {
                      _vm.query = $event
                    },
                    "row-del": _vm.rowDel,
                    "search-change": _vm.searchChange,
                    "search-reset": _vm.searchReset,
                    "selection-change": _vm.selectionChange,
                    "current-change": _vm.currentChange,
                    "size-change": _vm.sizeChange,
                    "refresh-change": function ($event) {
                      return _vm.onLoad(_vm.page, _vm.query)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "menu",
                      fn: function ({ row }) {
                        return [
                          _vm.permission.wf_design_form_history_view
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    icon: "el-icon-view",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePreview(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `cip.plat.wfDesign.form.btn.previewBtn`
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.permission.wf_design_form_history_main
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    icon: "el-icon-edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMain(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `cip.plat.wfDesign.form.btn.mainBtn`
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "menuLeft" },
                    [
                      _vm.permission.wf_design_form_history_delete
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                size: "mini",
                                icon: "el-icon-delete",
                                plain: "",
                              },
                              on: { click: _vm.handleDelete },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t(`cip.cmn.btn.delBtn`)) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.viewVisible,
            title: _vm.$t(`cip.plat.wfDesign.form.title.viewIconHeadTitle`),
            size: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.viewVisible = $event
            },
          },
        },
        [
          _vm.viewVisible
            ? _c("avue-form", {
                ref: "viewForm",
                staticStyle: { height: "100%", overflow: "auto" },
                attrs: { option: _vm.viewOption },
                model: {
                  value: _vm.viewForm,
                  callback: function ($$v) {
                    _vm.viewForm = $$v
                  },
                  expression: "viewForm",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }