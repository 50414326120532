var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c(
      "div",
      { staticClass: "JNPF-preview-main" },
      [
        _c(
          "div",
          { staticClass: "JNPF-common-page-header" },
          [
            _c("el-page-header", {
              attrs: { content: "批量审批" },
              on: { back: _vm.goBack },
            }),
            _c(
              "div",
              { staticClass: "options" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goBack()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.cancelButton")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "el-row",
              { staticClass: "JNPF-common-search-box", attrs: { gutter: 16 } },
              [
                _c(
                  "el-form",
                  {
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "流程标题" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入流程标题查询",
                                clearable: "",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.search()
                                },
                              },
                              model: {
                                value: _vm.listQuery.flowTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "flowTitle", $$v)
                                },
                                expression: "listQuery.flowTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属流程" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "选择所属流程",
                                  clearable: "",
                                },
                                on: { change: _vm.onFlowChange },
                                model: {
                                  value: _vm.listQuery.flowId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "flowId", $$v)
                                  },
                                  expression: "listQuery.flowId",
                                },
                              },
                              _vm._l(_vm.flowOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.fullName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属节点" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "选择所属节点",
                                  clearable: "",
                                },
                                on: { "visible-change": _vm.visibleChange },
                                model: {
                                  value: _vm.listQuery.nodeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "nodeCode", $$v)
                                  },
                                  expression: "listQuery.nodeCode",
                                },
                              },
                              _vm._l(_vm.nodeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.fullName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.showAll
                      ? [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "日期" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      "picker-options": _vm.pickerOptions,
                                      "value-format": "timestamp",
                                      clearable: "",
                                      editable: false,
                                    },
                                    model: {
                                      value: _vm.pickerVal,
                                      callback: function ($$v) {
                                        _vm.pickerVal = $$v
                                      },
                                      expression: "pickerVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "所属分类" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "选择所属分类",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.listQuery.flowCategory,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "flowCategory",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.flowCategory",
                                      },
                                    },
                                    _vm._l(_vm.categoryList, function (item) {
                                      return _c("el-option", {
                                        key: item.enCode,
                                        attrs: {
                                          label: item.fullName,
                                          value: item.enCode,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发起人员" } },
                                [
                                  _c("user-select", {
                                    attrs: { placeholder: "选择发起人员" },
                                    model: {
                                      value: _vm.listQuery.creatorUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "creatorUserId",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.creatorUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.search()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("common.search"))
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { icon: "el-icon-refresh-right" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reset()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("common.reset")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            !_vm.showAll
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-arrow-down",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showAll = true
                                      },
                                    },
                                  },
                                  [_vm._v("展开")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-arrow-up",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showAll = false
                                      },
                                    },
                                  },
                                  [_vm._v("\n                收起")]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "JNPF-common-head" }, [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "warning",
                        disabled: !_vm.listQuery.nodeCode,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatch(2)
                        },
                      },
                    },
                    [_vm._v("\n            批量转审")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: !_vm.listQuery.nodeCode,
                        loading: _vm.btnLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatch(0)
                        },
                      },
                    },
                    [_vm._v("批量通过")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        disabled: !_vm.listQuery.nodeCode,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatch(1)
                        },
                      },
                    },
                    [_vm._v("批量退回\n          ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "JNPF-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                attrs: { data: _vm.list, "has-c": "" },
                on: { "selection-change": _vm.handleChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "fullName",
                    label: "流程标题",
                    "show-overflow-tooltip": "",
                    "min-width": "150",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "flowName", label: "所属流程", width: "130" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "flowVersion",
                    label: "流程版本",
                    width: "130",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-tag", [
                            _vm._v("V:" + _vm._s(scope.row.flowVersion)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "startTime",
                    label: "发起时间",
                    width: "130",
                    formatter: _vm.jnpf.tableDateFormat,
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "userName", label: "发起人员", width: "130" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "thisStep", label: "审批节点", width: "130" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "flowUrgent",
                    label: "紧急程度",
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("urgentText")(scope.row.flowUrgent)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "status",
                    label: "流程状态",
                    width: "130",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status == 2
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("审核通过"),
                              ])
                            : scope.row.status == 3
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("审核退回"),
                              ])
                            : scope.row.status == 4
                            ? _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v("流程撤回"),
                              ])
                            : scope.row.status == 5
                            ? _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v("审核终止"),
                              ])
                            : _c("el-tag", { attrs: { type: "primary" } }, [
                                _vm._v("等待审核"),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "creatorTime",
                    label: "接收时间",
                    width: "130",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("toDate")(scope.row.creatorTime)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("pagination", {
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.currentPage,
                limit: _vm.listQuery.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "currentPage", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                pagination: _vm.initData,
              },
            }),
          ],
          1
        ),
        _vm.approveVisible
          ? _c("approve-dialog", {
              ref: "approveDialog",
              on: { submit: _vm.batchOperation },
            })
          : _vm._e(),
        _c("error-form", {
          attrs: { visible: _vm.errorVisible, nodeList: _vm.errorNodeList },
          on: {
            "update:visible": function ($event) {
              _vm.errorVisible = $event
            },
            submit: _vm.handleError,
          },
        }),
        _vm.userBoxVisible
          ? _c("actionDialog", {
              ref: "userBox",
              attrs: { title: "审批人" },
              on: { submit: _vm.handleTransfer },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }