var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.message.template.title.editHeadTitle")
              : this.type == "detail"
              ? _vm.$t("cip.plat.message.template.title.viewHeadTitle")
              : _vm.$t("cip.plat.message.template.title.addHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.onloadparm },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "cip.plat.message.template.parameter.title.indexHeadTitle"
                    ),
                    name: "templateParam",
                  },
                },
                [
                  _c("template-param", {
                    ref: "templateParam",
                    attrs: { headForm: _vm.dataForm, type: _vm.type },
                    on: {
                      "update:headForm": function ($event) {
                        _vm.dataForm = $event
                      },
                      "update:head-form": function ($event) {
                        _vm.dataForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "cip.plat.message.template.parties.title.indexHeadTitle"
                    ),
                    name: "templateParties",
                  },
                },
                [
                  _c("template-parties", {
                    ref: "templateParties",
                    attrs: {
                      headForm: _vm.dataForm,
                      type: _vm.type,
                      disabled: false,
                    },
                    on: {
                      "update:headForm": function ($event) {
                        _vm.dataForm = $event
                      },
                      "update:head-form": function ($event) {
                        _vm.dataForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "cip.plat.message.template.rule.title.indexHeadTitle"
                    ),
                    name: "templateRule",
                  },
                },
                [
                  _c("template-rule", {
                    ref: "templateRule",
                    attrs: { headForm: _vm.dataForm, type: _vm.type },
                    on: {
                      "update:headForm": function ($event) {
                        _vm.dataForm = $event
                      },
                      "update:head-form": function ($event) {
                        _vm.dataForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "cip.plat.message.template.channel.title.indexHeadTitle"
                    ),
                    name: "templateChannel",
                  },
                },
                [
                  _c("template-channel", {
                    ref: "templateChannel",
                    attrs: { headForm: _vm.dataForm, type: _vm.type },
                    on: {
                      "update:headForm": function ($event) {
                        _vm.dataForm = $event
                      },
                      "update:head-form": function ($event) {
                        _vm.dataForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }