var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.previewShow
    ? _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.dataForm,
                "label-width": "100px",
                disabled: _vm.disabled,
              },
            },
            _vm._l(_vm.formOption, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "preview" },
                [
                  _c("div", { staticClass: "previewTitle" }, [
                    _vm._v("\n        " + _vm._s(item.groupName) + "\n      "),
                  ]),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "12px" } },
                    _vm._l(item.properties, function (items, indexs) {
                      return _c(
                        "el-col",
                        { key: indexs, attrs: { span: item.span } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: items.fieldName,
                                prop: items.fieldCode,
                                rules: [
                                  {
                                    required:
                                      items.isEnable == "1" ? true : false,
                                    message:
                                      items.inputPrompt ||
                                      items.fieldName + "不能为空",
                                  },
                                ],
                              },
                            },
                            [
                              items.type == "1"
                                ? _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder:
                                        items.inputPrompt ||
                                        "请输入" + items.fieldName,
                                    },
                                    nativeOn: {
                                      input: function ($event) {
                                        return _vm.inputParamDefaultVal.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.dataForm[items.fieldCode],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          items.fieldCode,
                                          $$v
                                        )
                                      },
                                      expression: "dataForm[items.fieldCode]",
                                    },
                                  })
                                : _vm._e(),
                              items.type == "2"
                                ? _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      oninput:
                                        "this.value = this.value.replace(/[^0-9]/g, '');",
                                      placeholder:
                                        items.inputPrompt ||
                                        "请输入" + items.fieldName,
                                    },
                                    nativeOn: {
                                      input: function ($event) {
                                        return _vm.inputParamDefaultVal.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.dataForm[items.fieldCode],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          items.fieldCode,
                                          $$v
                                        )
                                      },
                                      expression: "dataForm[items.fieldCode]",
                                    },
                                  })
                                : _vm._e(),
                              items.type == "3"
                                ? _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      maxlength: items.inputLength,
                                      placeholder:
                                        items.inputPrompt ||
                                        "请输入" + items.fieldName,
                                    },
                                    model: {
                                      value: _vm.dataForm[items.fieldCode],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          items.fieldCode,
                                          $$v
                                        )
                                      },
                                      expression: "dataForm[items.fieldCode]",
                                    },
                                  })
                                : _vm._e(),
                              items.type == "4"
                                ? _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder:
                                        items.inputPrompt ||
                                        "请选择" + items.fieldName,
                                    },
                                    model: {
                                      value: _vm.dataForm[items.fieldCode],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          items.fieldCode,
                                          $$v
                                        )
                                      },
                                      expression: "dataForm[items.fieldCode]",
                                    },
                                  })
                                : _vm._e(),
                              items.type == "5"
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "select",
                                      refInFor: true,
                                      attrs: {
                                        placeholder:
                                          items.inputPrompt ||
                                          "请选择" + items.fieldName,
                                      },
                                      model: {
                                        value: _vm.dataForm[items.fieldCode],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            items.fieldCode,
                                            $$v
                                          )
                                        },
                                        expression: "dataForm[items.fieldCode]",
                                      },
                                    },
                                    _vm._l(
                                      items.dictionaryOption,
                                      function (dic) {
                                        return _c("el-option", {
                                          key: dic.dictKey,
                                          attrs: {
                                            label: dic.dictValue,
                                            value: dic.dictKey,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }