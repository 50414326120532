var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticStyle: { padding: "0 15px" },
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "130px",
                    label: "企业ID:",
                    prop: "corpId",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入企业ID", clearable: "" },
                    model: {
                      value: _vm.formData.corpId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "corpId", $$v)
                      },
                      expression: "formData.corpId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "130px",
                    label: "Secret:",
                    prop: "corpSecret",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入秘钥", clearable: "" },
                    model: {
                      value: _vm.formData.corpSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "corpSecret", $$v)
                      },
                      expression: "formData.corpSecret",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "130px",
                    label: "AgentId:",
                    prop: "agentId",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入应用ID", clearable: "" },
                    model: {
                      value: _vm.formData.agentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "agentId", $$v)
                      },
                      expression: "formData.agentId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }