var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-container",
        [
          _c("wf-category", {
            on: {
              "node-click": _vm.nodeClick,
              "list-change": function ($event) {
                _vm.findObject(_vm.option.column, "categoryId").dicData = $event
              },
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    "cip.plat.wfDesign.deployment.title.indexHeadTitle"
                  ),
                },
                on: { "head-delete": _vm.handleChangeCategory },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.option,
                  "table-data": _vm.data,
                  "table-loading": _vm.loading,
                  "data-total": _vm.page.total,
                  "grid-row-btn": _vm.gridRowBtn,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-sj": _vm.handleChangeStatus,
                  "row-bs": _vm.handleChangeStatus,
                  "row-qx": _vm.handleCategory,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.categoryVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                dialogTitle: _vm.$t(
                  "cip.plat.wfDesign.deployment.title.arrangeHeadTitle"
                ),
                width: "30%",
              },
              on: {
                cancel: function ($event) {
                  _vm.categoryVisible = false
                },
                confirm: _vm.deploySubmit,
              },
            },
            [
              _vm.categoryVisible
                ? _c("avue-form", {
                    ref: "deploySubmit",
                    attrs: {
                      option: {
                        menuBtn: false,
                        column: [
                          {
                            type: "tree",
                            label: this.$t(
                              `cip.plat.wfDesign.deployment.field.category`
                            ),
                            span: 24,
                            props: { label: "name", value: "id" },
                            prop: "category",
                            dicUrl: "/api/sinoma-workflow/design/category/tree",
                            required: true,
                            rules: [
                              {
                                required: true,
                                message:
                                  this.$t(`cip.cmn.rule.selectWarning`) +
                                  this.$t(
                                    `cip.plat.wfDesign.model.field.category`
                                  ),
                              },
                            ],
                          },
                        ],
                      },
                    },
                    on: { submit: _vm.handleCategorySubmit },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }