var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "signName",
                    prop: "signName",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.signName"
                      ),
                      "show-word-limit": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.signName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "signName", $$v)
                      },
                      expression: "formData.signName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "accessKeyId",
                    prop: "accessKeyId",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.accessKeyId"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.accessKeyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "accessKeyId", $$v)
                      },
                      expression: "formData.accessKeyId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "accessKeySecret",
                    prop: "accessKeySecret",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.accessKeySecret"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.accessKeySecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "accessKeySecret", $$v)
                      },
                      expression: "formData.accessKeySecret",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "templateCode",
                    prop: "templateCode",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.templateCode"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.templateCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "templateCode", $$v)
                      },
                      expression: "formData.templateCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }