var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-full-dialog",
      attrs: {
        visible: _vm.visible,
        fullscreen: "",
        "lock-scroll": "",
        "show-close": false,
        modal: false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "SNOWS-full-dialog-header" },
        [
          _c("div", { staticClass: "header-title" }, [
            _c("img", {
              staticClass: "header-logo",
              attrs: { src: require("@/assets/images/snow.png") },
            }),
            _c("p", { staticClass: "header-txt" }, [_vm._v(" · 表单设计")]),
          ]),
          _c(
            "el-steps",
            {
              staticClass: "steps",
              attrs: {
                active: _vm.activeStep,
                "finish-status": "success",
                simple: "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: "基础信息" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(0)
                  },
                },
              }),
              _c("el-step", {
                attrs: { title: "表单设计" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep <= 0 || _vm.btnLoading },
                  on: { click: _vm.prev },
                },
                [_vm._v("上一步\n        ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      _vm.activeStep >= 1 || _vm.loading || _vm.btnLoading,
                  },
                  on: { click: _vm.next },
                },
                [_vm._v("\n          下一步\n        ")]
              ),
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.loading,
                      loading: _vm.btnLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.dataFormSubmit()
                      },
                    },
                  },
                  [_vm._v("保 存")]
                ),
              ],
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          !_vm.activeStep
            ? _c(
                "el-row",
                {
                  staticClass: "basic-box",
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "basicForm",
                      attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            model: _vm.dataForm,
                            rules: _vm.dataRule,
                            "label-width": "100px",
                            "label-position": "right",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "SNOWS-form-tip-item",
                            { attrs: { label: "表单名称", prop: "fullName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "表单名称",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "fullName", $$v)
                                  },
                                  expression: "dataForm.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "SNOWS-form-tip-item",
                            { attrs: { label: "表单编码", prop: "enCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "表单编码",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.enCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enCode", $$v)
                                  },
                                  expression: "dataForm.enCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "SNOWS-form-tip-item",
                            {
                              staticStyle: { display: "none" },
                              attrs: { label: "表单类型", prop: "formType" },
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "50", disabled: "" },
                                model: {
                                  value: _vm.formType,
                                  callback: function ($$v) {
                                    _vm.formType = $$v
                                  },
                                  expression: "formType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.dataForm.formType == 1
                            ? [
                                _c(
                                  "SNOWS-form-tip-item",
                                  {
                                    attrs: {
                                      label: "Web地址",
                                      prop: "urlAddress",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { placeholder: "Web地址" },
                                        model: {
                                          value: _vm.dataForm.urlAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "urlAddress",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.urlAddress",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "prepend" }, [
                                          _vm._v("@/views/"),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "SNOWS-form-tip-item",
                                  {
                                    attrs: {
                                      label: "接口地址",
                                      prop: "interfaceUrl",
                                      "tip-label":
                                        "后端接口请求地址, 系统将会请求地址中的save(post方法), /{id}(get方法)接口例：http://nacos.sinoma.cn:8901/api/sinoma-xxx/example/UserController",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "接口地址" },
                                      model: {
                                        value: _vm.dataForm.interfaceUrl,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "interfaceUrl",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.interfaceUrl",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "SNOWS-form-tip-item",
                            { attrs: { label: "表单排序", prop: "sortCode" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  max: 999999,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.dataForm.sortCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "sortCode", $$v)
                                  },
                                  expression: "dataForm.sortCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "SNOWS-form-tip-item",
                            {
                              attrs: { label: "表单说明", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "流程说明",
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.dataForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "description", $$v)
                                  },
                                  expression: "dataForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                          [
                            _vm.dataForm.formType == 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "数据连接" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择数据库",
                                            clearable: "",
                                          },
                                          on: { change: _vm.onDbChange },
                                          model: {
                                            value: _vm.dataForm.linkId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "linkId",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.linkId",
                                          },
                                        },
                                        _vm._l(_vm.dbOptions, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.dbName,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "SNOWS-common-table",
                                      attrs: {
                                        data: _vm.mainTable,
                                        size: "medium",
                                        "empty-text":
                                          "点击“新增”可选择 1 条（单表）或 2 条以上（多表）",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "index",
                                          label: "序号",
                                          width: "50",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "typeId",
                                          label: "类别",
                                          width: "65",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.typeId == "1"
                                                    ? _c("el-tag", [
                                                        _vm._v("主表"),
                                                      ])
                                                    : _c(
                                                        "el-tag",
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                          attrs: {
                                                            type: "warning",
                                                            title:
                                                              "点击设置成主表",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeTable(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("从表")]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          407046229
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { prop: "table", label: "表名" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          scope.row.tableName ||
                                                          scope.row.table,
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.table
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1916873032
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "tableField",
                                          label: "外键字段",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return scope.row.typeId !== "1"
                                                  ? [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .tableField,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "tableField",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.tableField",
                                                          },
                                                        },
                                                        _vm._l(
                                                          scope.row.fields,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.field,
                                                                attrs: {
                                                                  label:
                                                                    item.field,
                                                                  value:
                                                                    item.field,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  : undefined
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "relationField",
                                          label: "关联主键",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return scope.row.typeId !== "1"
                                                  ? [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .relationField,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "relationField",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.relationField",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.mainTableFields,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.field,
                                                                attrs: {
                                                                  label:
                                                                    item.field,
                                                                  value:
                                                                    item.field,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  : undefined
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          fixed: "right",
                                          width: "50",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "SNOWS-table-delBtn",
                                                      attrs: {
                                                        size: "mini",
                                                        type: "text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delItem(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "移除\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3109784603
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "table-actions",
                                      on: { click: _vm.openTableBox },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-plus",
                                          },
                                        },
                                        [_vm._v("新增一行")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 1
            ? [
                this.dataForm.formType == 2
                  ? [
                      _c("Generator", {
                        ref: "generator",
                        attrs: {
                          conf: _vm.draftJson,
                          modelType: 6,
                          dbType: _vm.dbType,
                        },
                      }),
                    ]
                  : [
                      _c(
                        "el-row",
                        {
                          staticClass: "basic-box",
                          attrs: {
                            type: "flex",
                            justify: "center",
                            align: "middle",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "basicForm",
                              attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 10 },
                            },
                            [
                              _c("FieldForm", {
                                ref: "fieldForm",
                                attrs: {
                                  conf: _vm.draftJson,
                                  enCode: _vm.dataForm.enCode,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("TableForm", {
        ref: "tableForm",
        attrs: { visible: _vm.formVisible, linkId: _vm.dataForm.linkId },
        on: {
          "update:visible": function ($event) {
            _vm.formVisible = $event
          },
          closeForm: _vm.closeForm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }