<template>
  <div>
    <head-layout head-title="发送通道"
                 :head-btn-options="headBtnOptions"
                 @head-remove="headDeleteSelection"
                 @head-add="headAdd">
    </head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data.sync="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :grid-row-btn="gridRowBtn"
      :page="page"
      @grid-cell-click="cellClick"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-delete="rowDelete"
    >
      <template #customBtn="{row}">
        <el-button
          @click="formatEdit(row)"
          size="small"
          type="text"
        >{{ $t(`cip.plat.message.template.channel.btn.editBtn`) }}
        </el-button>

      </template>
    </grid-layout>
    <CommonDialog v-if="showDialogChanel" width="70%" :showBtn="false" :dialogTitle="$t(`cip.plat.message.template.channel.title.channelHeadTitle`)" @cancel="showDialogChanel = false">
      <ms-channel-dialog
        ref="msChannelDialog"
        name="templateParties"
        :member-table-data.sync="tableData"
        :headForm.sync="headForm"
        :formatEdit="formatEdit"
      ></ms-channel-dialog>
    </CommonDialog>
    <CommonDialog v-if="showDialog" class="qmDialog" width="60%" :dialogTitle="$t(`cip.plat.message.template.channel.title.headItemTitle`)" @cancel="showDialog = false" @confirm="formatSave">
      <avue-form :option="treeDialogOption" v-model="formatform"></avue-form>
    </CommonDialog>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

const removeHtmlStyle =(html)=> {
  let relStyle = /style\s*?=\s*?([‘"])[\s\S]*?\1/g; //去除样式
  let relTag = /<.+?>/g; //去除标签
  let relClass = /class\s*?=\s*?([‘"])[\s\S]*?\1/g;// 清除类名
  let relKg = /&nbsp;/ig;
  let newHtml = "";
  if (html) {
    newHtml = html.replace(relStyle, "");
    newHtml = newHtml.replace(relTag, '');
    newHtml = newHtml.replace(relClass, '');
    newHtml = newHtml.replace(relKg, '');
  }
  return newHtml;
};

import {
  selectPage,
  saveOrUpdate,
  remove
} from "@/api/message/templateChannel";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='

import {IS_ENABLE} from "@/util/dictConstant";
import func from "@/util/func";
import msChannelDialog from "@/views/business/components/msChannelDialog";
import ServerNameEnum from "@/util/ServerNameEnum";
import {selectById} from "@/api/message/templateRule";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "itemMapping",
  components: {
    msChannelDialog,
    GridLayout,
    HeadLayout,
    CommonDialog
  },
  props: {
    headForm: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: 'view',
    },
  },
  data() {
    return {
      formatform: {},
      showDialog: false,
      showDialogChanel: false,
      // table的加载
      tableLoading: true,
      // table的数据
      tableData: [],
      // table的数据条数
      dataTotal: 0,
      // TODO 点击行表的时候给获取行绑定的数据
      form: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
    };

  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.filter(e => e.id).forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    ...mapGetters(["permission"]),
    // 搜索栏
    searchColumns() {
      return [
        {
          label: "",
          prop: "channelCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.message.template.channel.field.channelCode"),
        },
      ];
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.plat.message.template.channel.field.titleFormat"),
            prop: "titleFormat",
            maxlength: 30,
            showWordLimit: true,
            placeholder: this.$t("cip.plat.message.template.channel.field.titleFormat"),
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.message.sendRecord.sendRecord.field.msgTile"),
              trigger: "blur"
            },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              }
            ],
          },
          {
            label: this.$t('cip.plat.message.template.channel.field.contentFormat'),
            prop: "contentFormat",
            type: 'content',
            labelWidth: 120,
            span: 24,
            component: 'AvueUeditor',
            toolbars: [], // 将工具栏设置为空数组
            disabled: !['edit', 'add'].includes(this.type),
            options: {
              action: `/`,
            },
            minRows: 6,
          }
        ]
      }
    },
    // 表格配置
    tableOptions() {
      return {
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        height: 0,
        customAddBtn: true,
        // calcHeight:'auto',
        align: 'center',
        menu: ['add', 'edit'].includes(this.type),
        selection: ['add', 'edit'].includes(this.type),
        column: [
          {
            label: this.$t("cip.plat.message.template.channel.field.channelCode"),
            prop: 'channelCode',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.message.template.channel.field.channelName"),
            prop: 'channelName',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.message.template.channel.field.channelType"),
            prop: 'channelType',
            dicUrl: `${baseUrl}channel_type`,
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true
          },
          {
            label: this.$t("cip.plat.message.template.channel.field.contentFormat"),
            prop: 'contentFormat',
            cell: true,
            overHidden: true,
            maxlength: 500,
            span: 24,
            showWordLimit: true,
          },
          {
            label: this.$t("cip.plat.message.template.channel.field.createTime"),
            prop: 'createTime',
            align: "center",
            overHidden: true
          },

        ]
      };
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push({
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add",
            type: "button",
            icon: "",
            btnOptType: 'add',
          },
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          })
      }
      return result;
    },
    // table的menu按钮
    gridRowBtn() {
      let result = [];
      if (['add', "edit"].includes(this.type)) {
        result.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-delete",
          type: "button",
          icon: ""
        })
      }
      return result;
    },
    isEnable() {
      return IS_ENABLE;
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    // 格式编辑
    formatEdit(row) {
      if (row.id) {
        this.formatform.id = row.id;
        this.formatform.titleFormat = row.titleFormat;
        this.formatform.contentFormat = row.contentFormat;
      } else {
        this.formatform.id = "";
        this.formatform.titleFormat = "";
        this.formatform.contentFormat = "";
      }
      this.formatform.templateId = this.headForm.id;
      this.formatform.channelCode = row.channelCode;
      this.formatform.channelName = row.channelName;
      this.formatform.channelType = row.channelType;
      this.formatform.channelId = row.channelId;
      this.showDialogChanel = false;
      this.showDialog = true;
    },
    gridSave() {
      this.saveLoading = true;
      try {

        let headId = this.headForm.id;
        if (!headId) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.saveHeadWarning'));
          return;
        }

        this.selectionList = this.$refs.gridLayOut.selectionList
        if (this.valid()) {
          saveOrUpdate(this.selectionList).then(res => {
            this.saveLoading = false;
            const {code} = res.data;
            if (code === 200) {
              this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
            }
            this.onLoad(this.page);
          });
        }
      } catch (e) {
        this.$message.warning(e.message)
        this.saveLoading = false;
        return
      }

    },
    //校验表格数据
    valid() {
      let result = true;
      if (!this.selectionList || this.selectionList.length === 0) {
        throw new Error(this.$t("cip.plat.message.template.channel.msg.listNotEmpty"))
        result = false;
      } else {
        try {
          let obj = {};
          this.tableData.forEach((e, index) => {
            if (!e.titleFormat || e.titleFormat == '') {
              this.$message.warning(this.$t("cip.plat.message.template.channel.msg.title"));
              result = false;
              throw new Error("ending");
            }
            if (!e.contentFormat || e.contentFormat == '') {
              this.$message.warning(this.$t("cip.plat.message.template.channel.msg.connect"));
              result = false;
              throw new Error("ending");
            }
            if (this.headForm.templateMode === "001" && e.titleFormat.indexOf("${") != -1 && e.contentFormat.indexOf("${") != -1) {
              this.$message.warning(this.$t("cip.plat.message.template.channel.msg.all"));
              result = false;
              throw new Error("ending");
            }
          })
        } catch (e) {
        }
        return result;
      }
    },
    formatSave() {
      let formatData = this.formatform;
      if (formatData.channelId == undefined) {
        formatData.channelId = formatData.id;
        formatData.id = '';
        this.tableData.push(formatData)
      }
      this.$nextTick(() => {
        let selectData = this.tableData.find(item => formatData.channelCode === item.channelCode)
        if (selectData) {
          this.$refs.gridLayOut.$refs.grid.$refs.table.toggleRowSelection(selectData, true)
        }
      })
      this.tableData.forEach((e, index) => {
        if (formatData.channelCode === e.channelCode) {
          e.titleFormat = formatData.titleFormat;
          e.contentFormat = removeHtmlStyle(formatData.contentFormat);
          e.templateId = this.headForm.id;
        }
        this.showDialog = false;
      });
      this.$nextTick(()=>{
        this.gridSave();
      })
    },
    // 获取
    selectExtItem(row, done) {
      let row1 = {};
      if (row) {
        row1 = {
          //$cellEdit: true,
          templateId: this.headForm.id,
          channelId: row.channelId,
          channelCode: row.channelCode,
          channelName: row.channelName,
          channelType: row.channelType,
          titleFormat: "",
          contentFormat: "",
          createTime: ""
        };
        this.tableData.push(row1)
      }
      // done();
      if (row1) {
        this.$refs.gridLayOut.getGrid().toggleRowSelection(row1, true);
      }
    },

    getHeight() {
      let height = document.body.clientHeight - 177;
      this.tableOptions.height = height;
      this.$refs.gridLayOut.option.height = height;
    },

    headDeleteSelection() {
      let headId = this.headForm.id;
      if (!headId) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.saveHeadWarning'));
        return;
      }
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'))
        return;
      }
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (ids.length === 0) {
            //只选中了空数据
            let index = 0;
            selectionList.filter(e => !e.id).forEach(e => {
              this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
              index++
            })
            this.$refs.gridLayOut.selectionClear()
            return;
          }
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.selectionClear()
            });
        }).catch(() => {
      })
    },
    headAdd() {
      let headId = this.headForm.id;
      if (!headId) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.saveHeadWarning'));
        return;
      }
      this.showDialogChanel = true;
    },
    rowDelete(row, index) {
      let headId = this.headForm.id;
      if (!headId) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.saveHeadWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (this.$func.isEmpty(row.id)) {
            this.$refs.gridLayOut.$refs.grid.rowCancel(row, index);
            return
          }
          remove(row.id)
            .then(() => {
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.tableData.splice(index, 1)
            });
        }).catch(() => {
      })
    },
    gridHeadSearch(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      if (!this.headForm || !this.headForm.id) {
        this.tableLoading = false;
        return
      }
      params.templateId = this.headForm.id;
      this.tableLoading = true;
      selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records
        this.tableLoading = false;
      });
    },
    cellClick(data) {
      let {row, column, cell, event} = data;
      this.form = row;
    },
    //校验数据
    async checkData() {
      let result = await this.$refs.gridLayOut.$refs.grid.validateCellForm();
      for (let item in result) {
        throw new Error(result[item][0].message);
      }
    },

  }
};
</script>
<style scoped lang="scss">
::v-deep .avue-crud .el-table {
  height: calc(100vh - 530px) !important;
  max-height: calc(100vh - 530px) !important;
}
</style>
