var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-container",
        [
          _c("wf-category", {
            on: {
              "node-click": _vm.nodeClick,
              "list-change": function ($event) {
                _vm.findObject(_vm.option.column, "categoryId").dicData = $event
              },
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "模型历史",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-delete": _vm.handleDelete },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.option,
                  "table-data": _vm.data,
                  "table-loading": _vm.loading,
                  "data-total": _vm.page.total,
                  "grid-row-btn": _vm.gridRowBtn,
                  page: _vm.page,
                },
                on: {
                  "row-delete": _vm.rowDelete,
                  "row-view": _vm.handlePreview,
                  "row-main": _vm.handleMain,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.viewVisible,
            title: _vm.$t(`cip.plat.wfDesign.model.title.viewIconHeadTitle`),
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.viewVisible = $event
            },
          },
        },
        [
          _c("wf-design", {
            ref: "bpmn",
            staticStyle: { height: "500px" },
            attrs: { options: _vm.viewOption },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }