var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", background: "#fff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.message.channel.title.editHeadTitle")
              : this.type == "view"
              ? _vm.$t("cip.plat.message.channel.title.viewHeadTitle")
              : _vm.$t("cip.plat.message.channel.title.addHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
          }),
          _c(
            "div",
            { attrs: { id: "sms" } },
            [
              _c("SMSform", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sms,
                    expression: "sms",
                  },
                ],
                ref: "SMSform",
                attrs: { formData: _vm.formData },
              }),
              _c("EmailMsg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                ref: "Emailform",
                attrs: { formData: _vm.formData },
              }),
              _c("AppMsg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.app,
                    expression: "app",
                  },
                ],
                ref: "Appform",
                attrs: { formData: _vm.formData },
              }),
              _c("WChatMsg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.wchat,
                    expression: "wchat",
                  },
                ],
                ref: "Wchatform",
                attrs: { formData: _vm.formData },
              }),
              _c("DingMsg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ding,
                    expression: "ding",
                  },
                ],
                ref: "Dingform",
                attrs: { formData: _vm.formData },
              }),
              _c("QWchatMsg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.qwchat,
                    expression: "qwchat",
                  },
                ],
                ref: "QWchatform",
                attrs: { formData: _vm.formData },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }