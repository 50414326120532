var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _c(
        "div",
        { staticClass: "SNOWS-common-layout-center" },
        [
          _c("head-layout", { attrs: { "head-title": "流程待办" } }),
          _c(
            "el-row",
            {
              staticClass: "SNOWS-common-search-box",
              staticStyle: { "margin-bottom": "0" },
              attrs: { gutter: 16 },
            },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入流程标题查询",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.flowTitle,
                              callback: function ($$v) {
                                _vm.flowTitle = $$v
                              },
                              expression: "flowTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "picker-options": _vm.pickerOptions,
                              "value-format": "timestamp",
                              clearable: "",
                              editable: false,
                            },
                            on: { change: _vm.search },
                            model: {
                              value: _vm.pickerVal,
                              callback: function ($$v) {
                                _vm.pickerVal = $$v
                              },
                              expression: "pickerVal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              ref: "selectTree",
                              staticClass: "main-select-tree",
                              staticStyle: { width: "560px" },
                              attrs: { placeholder: "选择所属分类" },
                              model: {
                                value: _vm.CategoryText,
                                callback: function ($$v) {
                                  _vm.CategoryText = $$v
                                },
                                expression: "CategoryText",
                              },
                            },
                            [
                              _vm._l(_vm.categoryList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  staticStyle: { display: "none" },
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              _c("el-tree", {
                                ref: "selecteltree",
                                staticClass: "main-select-el-tree",
                                attrs: {
                                  data: _vm.categoryList,
                                  "node-key": "id",
                                  "highlight-current": "",
                                  props: _vm.defaultProps,
                                  "default-expand-all": "",
                                },
                                on: { "node-click": _vm.handleNodeClick },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  true
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c("user-select", {
                                  attrs: { placeholder: "选择发起人员" },
                                  on: { change: _vm.search },
                                  model: {
                                    value: _vm.creatorUserId,
                                    callback: function ($$v) {
                                      _vm.creatorUserId = $$v
                                    },
                                    expression: "creatorUserId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "选择紧急程度",
                                      clearable: "",
                                    },
                                    on: { change: _vm.search },
                                    model: {
                                      value: _vm.urgent,
                                      callback: function ($$v) {
                                        _vm.urgent = $$v
                                      },
                                      expression: "urgent",
                                    },
                                  },
                                  _vm._l(_vm.urgentList, function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.fullName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button-group",
                        [
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.search()
                              },
                            },
                          }),
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              icon: "reset-refresh icon-refresh",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.refresh()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "SNOWS-common-layout-main SNOWS-flex-main" },
            [
              _c(
                "SNOWS-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.list, size: "medium" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "fullName",
                      label: "流程标题",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "flowName",
                      label: "所属流程",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "startTime",
                      label: "发起时间",
                      formatter: _vm.sinoma.tableDateFormat,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "userName",
                      label: "发起人员",
                      width: "130",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "thisStep",
                      label: "审批节点",
                      width: "130",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "flowUrgent",
                      label: "紧急程度",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.flowUrgent == 2
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("urgentText")(scope.row.flowUrgent)
                                    )
                                  ),
                                ])
                              : scope.row.flowUrgent == 3
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("urgentText")(scope.row.flowUrgent)
                                    )
                                  ),
                                ])
                              : _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("urgentText")(scope.row.flowUrgent)
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "status",
                      label: "流程状态",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 2
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("审核通过"),
                                ])
                              : scope.row.status == 3
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("审核退回"),
                                ])
                              : scope.row.status == 4
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("流程撤回"),
                                ])
                              : scope.row.status == 5
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("审核终止"),
                                ])
                              : _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("等待审核"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "creatorTime",
                      label: "接收时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("toDate")(scope.row.creatorTime)
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审批")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toProcess(scope.row)
                                  },
                                },
                              },
                              [_vm._v("流程追踪")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.currentPage,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.initData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formVisible
        ? _c("FlowBox", { ref: "FlowBox", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.processVisible
        ? _c("FlowChart", { ref: "flowChart", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.batchListVisible
        ? _c("BatchList", {
            ref: "BatchList",
            attrs: { categoryList: _vm.categoryList },
            on: {
              close: function ($event) {
                _vm.batchListVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }