<template>
  <div style="position: relative">
    <el-container>
      <CommonTree
        treeTitle="文件分类"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        :treeExpand="false"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <div style="width: calc(100% - 275px)">
        <head-layout
          head-title="填报设置"
          :head-btn-options="headBtnOptions"
          @head-remove="handleDelete"
          @head-add="headAdd"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          @gird-handle-select-click="selectionChange"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{row}">
            <el-button

              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowEdit(row)"
            >编辑
            </el-button>
            <el-button
              v-if="row.acquisitionStatus != 3"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowDel(row)"
            >删除
            </el-button>
            <el-button
              v-if="row.acquisitionStatus != 3"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowSet(row)"
            >发布
            </el-button>
          </template>
        </grid-layout>
      </div>
    </el-container>
    <!-- 文件分类表单弹窗 -->
    <el-dialog :title="treeDiaTitle" :visible.sync="treeDiaSHow" width="50%">
      <el-form
        ref="treeForm"
        :model="treeForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="classificationName">
              <el-input
                v-model="treeForm.classificationName"
                placeholder="请输入分类名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类编号" prop="classificationCode">
              <el-input
                v-model="treeForm.classificationCode"
                placeholder="请输入分类编号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="类别" prop="category">
              <el-select
                v-model="treeForm.category"
                placeholder="请输入分类名称"
              >
                <el-option
                  v-for="item in classificationOption"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input
                v-model="treeForm.sort"
                placeholder="请输入排序"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级" prop="parentId">
              <avue-input-tree
                default-expand-all
                v-model="treeForm.parentId"
                placeholder="请选择上级"
                :props="inputTreeProps"
                :dic="treeOption"
              ></avue-input-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                v-model="treeForm.remark"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="treeDiaSHow = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保 存</el-button>
      </span>
    </el-dialog>
    <Form ref="Form"></Form>
  </div>
</template>
<script>
// import {
//   dictionaryBiz,
//   classificationList,
//   classificationSave,
//   classificationRemove,
//   attributeList,
//   attributeRemove,
//   attributePublish
// } from "@/api/reportSet.js";
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import Form from "./form";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    Form,
  },
  data() {
    return {
      treeData: [],
      searchTitle: "classificationName",
      treeDiaTitle: "",
      treeDiaSHow: false,
      setDiaTitle: "",
      setDiaSHow: false,
      defaultProps: {
        label: "classificationName",
        value: "classificationCode",
      },
      inputTreeProps: {
        label: "classificationName",
        value: "id",
      },
      treeOption: [],
      treeForm: {},
      classificationOption: [],
      chooseTreeNode: {},
      selectionList: [],
      rules: {
        classificationName: [
          {required: true, message: "请输入分类名称", trigger: "blur"},
        ],
        classificationCode: [
          {required: true, message: "请输入分类编号", trigger: "blur"},
        ],
        category: [
          {required: true, message: "请选择类别", trigger: "change"},
        ],
        sort: [{required: true, message: "请输入排序", trigger: "blur"}],
        parentId: [
          {required: true, message: "请选择上级", trigger: "change"},
        ],
      },
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 180,
        column: [
          {
            label: "报表名称",
            prop: "title",
            align: "center",
          },
          {
            label: "表名称",
            prop: "tableName",
            align: "center",
          },
        ],
      },
      tableData: [],
      tableLoading: false,
      page: {
        size: 20,
        current: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        },
      ],
      searchColumns: [
        {
          label: '报表名称',
          prop: "title",
          span: 4,
          placeholder: '请输入报表名称'
        },
        {
          label: '表名称',
          prop: "tableName",
          span: 4,
          placeholder: '请输入表名称'
        }
      ],
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.getClassificationList();
    this.onLoad();
    this.getDictory();
  },
  methods: {
    // 左侧文件分类数据
    getClassificationList() {
      classificationList().then((res) => {
        let data = [
          {
            classificationName: "顶级节点",
            id: "0",
          },
        ];
        data = data.concat(res.data.data);
        let allData = [
          {
            classificationName: "全部",
            id: "0",
            category:'all'
          },
        ]
        allData = allData.concat(res.data.data);
        this.treeData = allData;
        this.treeOption = data;
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    // 获取数据字典
    getDictory() {
      dictionaryBiz("document_classification").then((res) => {
        this.classificationOption = res.data.data;
      });
    },
    // 新增文件分类
    treeMenuAdd() {
      this.treeDiaSHow = true;
      this.treeDiaTitle = "文件分类新增";
      this.resetFrom();
    },
    // 编辑文件分类
    treeMenuEdit(node) {
      this.treeDiaSHow = true;
      this.treeDiaTitle = "文件分类编辑";
      this.treeForm = node;
      this.resetFrom();
    },
    // 删除文件分类
    treeNodeDel(node) {
      this.$confirm("确定删除数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          classificationRemove(node.id).then((res) => {
            if (res.data.code == 200) {
              this.getClassificationList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
    },
    treeNodeClick(node) {
      this.chooseTreeNode = node;
      this.page.classificationId = node.id;
      this.gridHeadSearch();
    },
    // 搜索填报设置
    gridHeadSearch() {
      this.page.current = 1;
      this.onLoad();
    },
    // 清空填报设置搜索条件
    gridHeadEmpty() {
      this.page.current = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad()
    },
    // 获取填报设置列表
    onLoad() {
      let query = {
        ...this.page,
        ...this.$refs.gridHeadLayout.searchForm
      }
      attributeList(query).then((res) => {
        console.log(res, '327')
        if (res.data.code == 200) {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      });
    },
    // 填报设置编辑
    rowEdit(row) {
      this.$refs.Form.dataForm.id = row.id;
      this.$refs.Form.formShow = true;
      this.$refs.Form.formTitle = "编辑";
      this.$refs.Form.getAttributeDetail();
    },
    rowSet(row) {
      attributePublish(row.id).then((res) =>{
        console.log(res,'381')
        if (res.data.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
        }
      })
    },
    // 填报设置删除
    rowDel(row) {
      attributeRemove(row.id).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      })
    },
    // 新增填报设置
    headAdd() {
      if (this.chooseTreeNode.category == undefined) {
        this.$message({
          message: "请选择文件分类",
          type: "warning",
        });
        return;
      }
      if (this.chooseTreeNode.category == 1) {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
        return;
      }
      this.$refs.Form.resetData();
      this.$refs.Form.formShow = true;
      this.$refs.Form.formTitle = "新增";
      this.$refs.Form.dataForm.classificationId = this.chooseTreeNode.id;
    },
    // 填报设置多选删除
    handleDelete() {
      if (this.selectionList.length == 0) {
        this.$message({
          message: "请选择要删除的数据",
          type: "warning",
        });
        return;
      }
      attributeRemove(this.ids).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      })
    },
    // 重置分类文件表单
    resetFrom() {
      this.$nextTick(() => {
        this.$refs.treeForm.resetFields();
      })
      this.treeForm = {
        classificationName: "",
        classificationCode: "",
        category: "",
        sort: "",
        remark: "",
        parentId: "",
        parentName: "",
      };
    },
    // 新增/修改文件分类
    submitForm() {
      this.$refs.treeForm.validate((valid) => {
        if (valid) {
          classificationSave(this.treeForm).then((res) => {
            this.getClassificationList();
            this.treeDiaSHow = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.leftTree {
  width: 30%;
  background-color: #ffffff;
}
</style>
