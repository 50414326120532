<template>
  <div>
    <head-layout head-title="消息规则"
                 :head-btn-options="headBtnOptions"
                 @head-save="headSave()">
    </head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
import {PATTERN_MOBILE} from "@/util/validate";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {
  selectById,
  saveOrUpdate
} from '@/api/message/templateRule'


export default {
  name: "templateRule",
  components: {
    FormLayout,
    HeadLayout
  },
  props: {
    headForm: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: 'view',
    }
  },
  data() {
    return {
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
          }
        );
      }
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t("cip.plat.message.template.rule.field.startTime"),
          prop: "startTime",
          headerAlign: "center",
          align: 'center',
          cell: true,
          format: "HH:mm",
          valueFormat: "HH:mm",
          type: 'time',
          readonly: !['edit', 'add'].includes(this.type),
          placeholder: this.$t("cip.plat.message.template.rule.field.startTime"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.message.template.rule.field.startTime"),
            trigger: "blur"
          }],
        },
        {
          label: this.$t("cip.plat.message.template.rule.field.endTime"),
          prop: "endTime",
          headerAlign: "center",
          align: 'center',
          cell: true,
          format: "HH:mm",
          valueFormat: "HH:mm",
          type: 'time',
          readonly: !['edit', 'add'].includes(this.type),
          placeholder: this.$t("cip.plat.message.template.rule.field.endTime"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.message.template.rule.field.endTime"),
            trigger: "blur"
          }],
        },
        {
          row: true,
          label: this.$t("cip.plat.message.template.rule.field.maxFrequency"),
          labelWidth: 150,
          prop: 'maxFrequency',
          minRows: 0,
          type: 'number',
          disabled: !['edit', 'add'].includes(this.type),
        },
        {
          row: true,
          label: this.$t("cip.plat.message.template.rule.field.keywordFiltering"),
          prop: 'keywordFiltering',
          type: 'textarea',
          maxlength: 500,
          showWordLimit: true,
          readonly: !['edit', 'add'].includes(this.type),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.message.template.rule.field.keywordFiltering"),
            trigger: "blur"
          },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        }
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      selectById(this.headForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        this.dataForm.id = data.id;
        let str = data.timeFrame;
        if (!(typeof str === 'undefined' || str == null || str === '')) {
          let arr = str.split("~");
          this.dataForm.startTime = arr[0];
          this.dataForm.endTime = arr[1];
        }
      })
    },
    headSave(cancel = false) {
      let headId = this.headForm.id;
      if (!headId) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.saveHeadWarning'));
        return;
      }
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        this.dataForm.templateId = this.headForm.id;
        let data = {};
        data = this.$refs.formLayout.dataForm;
        data.templateId = this.headForm.id;
        if (valid) {
          saveOrUpdate({
            ...data,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
