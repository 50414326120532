<template>
  <div style="height:100%;background:#fff">
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? ($t('cip.plat.message.channel.title.editHeadTitle')) : (this.type == 'view' ? ($t('cip.plat.message.channel.title.viewHeadTitle')) : $t('cip.plat.message.channel.title.addHeadTitle'))"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
      <div id="sms">
        <!--        <SMSform ref="SMSform" :headForm.sync="dataForm" :type="type"></SMSform>-->
        <SMSform ref="SMSform" :formData="formData" v-show="sms"></SMSform>
        <EmailMsg ref="Emailform" :formData="formData" v-show="email"></EmailMsg>
        <AppMsg ref="Appform" :formData="formData" v-show="app"></AppMsg>
        <WChatMsg ref="Wchatform" :formData="formData" v-show="wchat"></WChatMsg>
        <DingMsg ref="Dingform" :formData="formData" v-show="ding"></DingMsg>
        <QWchatMsg ref="QWchatform" :formData="formData" v-show="qwchat"></QWchatMsg>
      </div>

    </el-scrollbar>
  </div>
</template>
<script>
import {PATTERN_MOBILE} from "@/util/validate";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {
  selectById,
  saveOrUpdate
} from '@/api/message/channel'
import SMSform from "@/views/message/channel/common/SMSMsg";
import EmailMsg from "@/views/message/channel/common/EmailMsg";
import AppMsg from "@/views/message/channel/common/AppMsg";
import WChatMsg from "@/views/message/channel/common/WChatMsg";
import DingMsg from "@/views/message/channel/common/DingMsg";
import QWchatMsg from "@/views/message/channel/common/QWchatMsg";
import {mapGetters} from "vuex";

export default {
  name: "channelEdit",
  components: {
    DingMsg,
    WChatMsg,
    AppMsg,
    EmailMsg,
    SMSform,
    FormLayout,
    HeadLayout,
    QWchatMsg,
  },
  data() {
    return {
      sms: false,
      email: false,
      app: false,
      wchat: false,
      ding: false,
      qwchat: false,
      phonechat: false,
      officialAccount: false, // 微信公众号
      type: 'view',
      dataForm: {},
      formData: {},

    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            // btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t(`cip.plat.message.channel.field.channelType`),
          prop: 'channelType',
          labelWidth: 130,
          width: 300,
          type: 'radio',
          span: 24,
          disabled: !["add", "edit"].includes(this.type),
          dicUrl: `${baseUrl}channel_type`,
          // value: "110",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          row: true,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.message.channel.field.channelType'),
            trigger: "blur"
          }],
          control: (val) => {
            if ("130" === val) {
              this.sms = false;
              this.email = false;
              this.app = false;
              this.wchat = false;
              this.ding = false;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
            }else if ("30" === val) {
              this.sms = false;
              this.email = false;
              this.app = false;
              this.wchat = false;
              this.ding = false;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
            }else if ("110" === val) {
              this.sms = false;
              this.email = false;
              this.app = false;
              this.wchat = false;
              this.ding = false;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
              /**
               } else if ("30" === val) {
               this.sms = true;
               this.email = false;
               this.app = false;
               this.wchat = false;
               this.ding = false;
               this.qwchat = false;
               this.phonechat = false;
               return {
               sendType: {
               display: false
               }
               }
               **/
            } else if ("40" === val) {
              this.sms = false;
              this.email = true;
              this.app = false;
              this.wchat = false;
              this.ding = false;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
            } else if ("20" === val) {
              this.sms = false;
              this.email = false;
              this.app = true;
              this.wchat = false;
              this.ding = false;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
            } else if ("50" === val) {
              this.sms = false;
              this.email = false;
              this.app = false;
              this.wchat = true;
              this.ding = false;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
            } else if ("90" === val) {
              this.sms = false;
              this.email = false;
              this.app = false;
              this.wchat = false;
              this.ding = true;
              this.qwchat = false;
              return {
                sendType: {
                  display: false
                }
              }
            } else if (val === '70') {
              this.sms = false;
              this.email = false;
              this.app = false;
              this.wchat = false;
              this.ding = false;
              this.qwchat = true;
              return {
                sendType: {
                  display: true
                }
              }
            }
          }
        },
        {
          label: '发送类型',
          prop: 'sendType',
          labelWidth: 130,
          width: 300,
          type: 'radio',
          span: 24,
          display: true,
          disabled: !["add", "edit"].includes(this.type),
          dicUrl: `${baseUrl}send_type`,
          value: "10",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          row: true,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + '发送类型',
            trigger: "blur"
          }],
        },
        {
          label: this.$t(`cip.plat.message.channel.field.channelCode`),
          prop: 'channelCode',
          labelWidth: 130,
          maxlength: 50,
          showWordLimit: true,
          disabled: ["add"].includes(this.type),
          readonly: !["add"].includes(this.type),
          placeholder: "通道编号自动生成",
        },
        {
          label: this.$t(`cip.plat.message.channel.field.channelName`),
          prop: 'channelName',
          labelWidth: 130,
          maxlength: 20,
          showWordLimit: true,
          span: 24,
          tip: '通道名称和微信应用名称保持一致',
          tipPlacement: 'right',
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.message.channel.field.channelName'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        // {
        //   label: '创建人',
        //   prop: 'createUserName',
        //   value: this.userInfo.real_name,
        //   labelWidth: 130,
        //   span: 24,
        //   maxlength: 20,
        //   showWordLimit: true,
        //   disabled: true,
        //   rules: [
        //     {
        //       required: true,
        //       message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.message.channel.field.channelName'),
        //       trigger: "blur"
        //     },
        //     {
        //       pattern: /^[^\s]+(\s+[^\s]+)*$/,
        //       message: this.$t('cip.cmn.rule.deleteWarning'),
        //     }
        //   ],
        // },
        {
          row: true,
          label: this.$t(`cip.plat.message.channel.field.channelDescribe`),
          span: 24,
          prop: 'channelDescribe',
          labelWidth: 130,
          maxlength: 180,
          showWordLimit: true,
          type: 'textarea'
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      selectById(this.dataForm.id).then(res => {
        const {data} = res.data;
        if (data.channelType === "30") {
          this.sms = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.SMSform.formData = cdata;
        } else if (data.channelType === "40") {
          this.email = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.Emailform.formData = cdata;
        } else if (data.channelType === "20") {
          this.app = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.Appform.formData = cdata;
        } else if (data.channelType === "50") {
          this.wchat = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.Wchatform.formData = cdata;
        } else if (data.channelType === "90") {
          this.ding = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.Dingform.formData = cdata;
        } else if (data.channelType === "70") {
          this.qwchat = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.QWchatform.formData = cdata;
        } else if (data.channelType === "30") {
          this.phonechat = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.phoneMsg.formData = cdata;
        } else if (data.channelType === "130") {
          this.officialAccount = true;
          let cdata = JSON.parse(data.channelParameter);
          this.$refs.officialAccount.formData = cdata;
        }
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = {}
          data = this.$refs.formLayout.dataForm;
          if (data.channelType === "30") {
            let smsdata = this.$refs.SMSform.formData;
            data.channelParameter = JSON.stringify(smsdata);
          } else if (data.channelType === "40") {
            let emaildata = this.$refs.Emailform.formData;
            data.channelParameter = JSON.stringify(emaildata);
          } else if (data.channelType === "20") {
            let appdata = this.$refs.Appform.formData;
            data.channelParameter = JSON.stringify(appdata);
          } else if (data.channelType === "50") {
            let wdata = this.$refs.Wchatform.formData;
            data.channelParameter = JSON.stringify(wdata);
          } else if (data.channelType === "90") {
            let ddata = this.$refs.Dingform.formData;
            data.channelParameter = JSON.stringify(ddata);
          } else if (data.channelType === "70") {
            let ddata = this.$refs.QWchatform.formData;
            data.channelParameter = JSON.stringify(ddata);
          }
          if (data.channelType === "110") {
            data.channelParameter = "";
          }
          saveOrUpdate({
            ...data,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(msg)
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>
/* ::v-deep .el-form-item--small .el-form-item__content{
  width: 25%;
} */
</style>
