<template>
  <div>
    <div class="headers">
      <grid-head-layout
        ref="gridHeadLayout"
        :grid-head-btn="gridHeadBtn"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
    </div>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.dataTotal"
      @gird-handle-select-click="selectionChange"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-view="rowDetail"
    ></grid-layout>
    <dyn-table-dialog ref="dynTableDialog" :headForm.sync="dataForm" :type="type"></dyn-table-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index"
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {removeCategoryWithIndex, sysCategoryWithIndexPage} from "@/api/index/appSystemCategory";
import {extIndexPage as getList} from "@/api/index/appIndexManage";
import dynTableDialog from "@/views/business/index/appIndex/common/dynTableDialog";

export default {
  name: "index",
  components: {
    dynTableDialog,
    GridLayout,
    HeadLayout,
    CommonTree
  },
  props: {
    sysCategoryId: {
      default: null,
      type:String
    },
    shouIndexManage: {
      default: false
    },
    test:{
      default:null,
      type:String
    },
    dataFormExt:{},
  },
  watch:{
    sysCategoryId:{
      handler(val){
        this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId})

      }

    }
  },
  data() {
    return {
      page: {
        currentPage: 1,
        dataTotal: 0,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
      },
      tableData: [],
      tableLoading: false,
      selectionList: [],
      query: {},
      dataForm: {}
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    gridHeadBtn() {
      return []
    },
    tableOptions() {
      return {
        menu: !this.shouIndexManage,
        linklabel: "indexCode",
        menuWidth: 120,
        column: [
          {
            label: this.$t('cip.index.app.category.indexCode'),
            prop: "indexCode",
            search: true,
          },
          {
            label: this.$t('cip.index.app.category.indexName'),
            prop: "indexName",
            search: true
          },
          {
            label: this.$t('cip.index.app.category.alias'),
            prop: "alias"
          },
          {
            label: this.$t('cip.index.app.category.unitCode'),
            prop: "unitCode",
            align: 'center',
            width: 90,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_unit",
          },
          {
            label: this.$t('cip.index.app.category.timeDimension'),
            prop: "timeDimension",
            align: 'center',
            width: 90,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          },
          {
            label: this.$t('cip.index.app.category.indexLevel'),
            prop: "indexLevel",
            align: 'center',
            width: 90,
            value: 'y',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_level",
          },
        ],
        reserveSelection:true
      }
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "indexCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.app.category.indexCode`)
        },
        {
          label: "",
          prop: "indexName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.app.category.indexName`)
        }
      ]
    },
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: ""
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: ""
        }
      ]
    },
  },
  created() {
    this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
  },
  methods: {
    //每页多少条数据
    handleSizeChange(val) {
      this.PAGE_CONSTANT.DEFAULT_PAGE_SIZE = val

      this.onLoad(this.page,{sysCategoryId: this.sysCategoryId});
    },
    //当前第几页
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.onLoad(this.page, {sysCategoryId: this.sysCategoryId})
    },
    gridHeadSearch(query) {
      this.query = query;
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
    },
    gridHeadEmpty(query) {
      this.query = query;
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          removeCategoryWithIndex({indexMappingVOS: [row.mappingId]}).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
            }
          });
        })
    },
    rowDetail(row) {
      this.dataForm = row
      // this.$router.push({
      //   path: `/business/index/appCategory/view`,
      //   query: {
      //     indexCode: row.indexCode,
      //
      //   }
      // })
      this.$refs.dynTableDialog.openDialog(this.dataForm);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    ids(idName = 'id') {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele[idName]);
      });
      this.selectionList=[]
      // this.$refs.gridLayOut.$refs.grid.clearSelection();
      return ids;
    },
    onLoad(page = {pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, params = {
    }) {
      this.crudLoading = true;

      if(this.$store.state.common.timeDimension){
        this.query.timeDimension=this.$store.state.common.timeDimension
      }


      if (this.dataFormExt) {
        params = {
          ...params,
          ...this.dataFormExt,
        }
      }
      if(this.sysCategoryId){
        params.sysCategoryId=this.sysCategoryId
      }
      let connect = sysCategoryWithIndexPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      );
      if (this.shouIndexManage) {
        connect = getList(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
        );
      }
      connect.then((res) => {

          const data = res.data.data;
          this.page.dataTotal = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
        })
        .catch(() => {
          this.crudLoading = true;
        });
    }
  }
}
</script>
