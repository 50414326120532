var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emergencyDrillAdd",
      staticStyle: { "background-color": "#ffffff" },
    },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": _vm.headSave,
          "head-submit": _vm.headSubmit,
          "head-execute-submit": _vm.headExecuteSubmit,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: { option: _vm.formOptions },
              model: {
                value: _vm.addForm,
                callback: function ($$v) {
                  _vm.addForm = $$v
                },
                expression: "addForm",
              },
            },
            [
              _c(
                "template",
                { slot: "exeCode" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请输入计划编号",
                        disabled:
                          _vm.addForm.isAutomatic ||
                          _vm.pageDisabled ||
                          _vm.addForm.id,
                      },
                      model: {
                        value: _vm.addForm.exeCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "exeCode", $$v)
                        },
                        expression: "addForm.exeCode",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "append" }, slot: "append" },
                        [
                          _vm._v("\n            自动生成\n            "),
                          _c("el-switch", {
                            attrs: {
                              disabled: _vm.pageDisabled || _vm.addForm.id,
                              "active-color": "#13ce66",
                            },
                            on: { change: _vm.handleSwitch },
                            model: {
                              value: _vm.addForm.isAutomatic,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "isAutomatic", $$v)
                              },
                              expression: "addForm.isAutomatic",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "planName" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请选择应急预案",
                        disabled:
                          _vm.pageDisabled || _vm.addForm.erStatus == "PREPARE",
                      },
                      on: { focus: _vm.selectPlan },
                      model: {
                        value: _vm.addForm.planName,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "planName", $$v)
                        },
                        expression: "addForm.planName",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "suffix" },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "files" },
                [
                  _c(
                    "el-upload",
                    {
                      class: {
                        uoloadSty: _vm.showImg,
                        disUoloadSty: _vm.noneUploadFile,
                      },
                      attrs: {
                        disabled: _vm.pageDisabled,
                        action:
                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                        accept: ".doc,.docx,.pdf,.zip,.png,.jpg,.jpeg",
                        limit: 1,
                        "file-list": _vm.fileArray,
                        headers: _vm.headers,
                        "on-success": _vm.uploadSuccess,
                        "on-remove": _vm.handleRemove,
                        "on-exceed": _vm.uploadExceed,
                        "on-preview": _vm.handleFilePreview,
                        "before-upload": _vm.handleBeforeUpload,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "small" } }, [
                        _c("i", { staticClass: "el-icon-upload2" }),
                        _vm._v(" 上传文件"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n            支持扩展名: .zip,.doc,.docx,.pdf,.png,.jpg\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("tabsPane", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.addForm.id,
                expression: "addForm.id",
              },
            ],
            ref: "tabsPane",
          }),
          _c("emergencyPlanDialog", {
            ref: "emergencyPlanDialog",
            on: { changeEmergencyPlan: _vm.changeEmergencyPlan },
          }),
          _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }