<template>
  <el-dialog
    v-dialog-drag
    :title="$t(`cip.index.intf.indexClass`)+$t(`cip.index.theme.list`)"
    :visible.sync="appIndexWindowShow"
    v-if="appIndexWindowShow"
    class="avue-dialog avue-dialog--top"
    width="75%">
    <AppIndexPage ref="appIndexPage" :dataFormExt.sync="dataFormExt" :shouIndexManage="false"></AppIndexPage>
    <div class="avue-dialog__footer">
      <el-button size="small" @click="appIndexWindowShow = false">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
      <el-button size="small" @click="confirm" type="primary">
        {{ $t('cip.cmn.btn.defBtn') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import AppIndexPage from "@/views/business/index/appCategory/index.vue";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
export default {
  components: {
    HeadLayout,
    FormLayout,
    AppIndexPage
  },
  data() {
    return {
      appIndexWindowShow: false,
      dataFormExt: {}
    }
  },
  methods:{
    openDialog(timeDimensionIn){
      this.appIndexWindowShow = true;
      this.dataFormExt = {timeDimension: timeDimensionIn}
    },
    confirm(){
      let selectionList = this.$refs.appIndexPage.getSelectionList();
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.rule.selectWarning'));
        return;
      }
      this.$emit("confirmExt",selectionList)
      this.appIndexWindowShow = false;
    }
  }
}
</script>
<style scoped>

</style>
