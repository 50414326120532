<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t(`cip.plat.wfDesign.condition.title.indexHeadTitle`)"
                 @head-add="rowSave"
                 @head-delete="handleDelete"></head-layout>

    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>

    <grid-layout ref="gridLayOut"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-del="rowDel"
                 @row-edit="rowEdit"></grid-layout>

    <!--  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :permission="permissionList"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="mini"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.wf_condition_delete"
                   @click="handleDelete">删 除
        </el-button>
        <el-tag type="warning"
                effect="dark"
                size="medium"><i class="el-icon-warning"></i> 此配置只做快捷选择用，具体逻辑请自行实现。具体实现方法请查看使用文档。</el-tag>
      </template>
    </avue-crud>
  </basic-container>-->
  </div>
</template>

<script>
import { getList, remove } from "@/api/plugin/workflow/condition";
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  components: { GridLayout, HeadLayout },
  data () {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        dialogType: 'drawer',
        size: 'mini',
        align: 'center',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        searchSize: 'mini',
        border: true,
        index: true,
        selection: true,
        dialogClickModal: false,
        column: [
          {
            label: this.$t('cip.plat.wfDesign.condition.field.name'),
            prop: "name",
            overHidden: true,
            width: 400,
            align:'left',
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.condition.field.name`),
              trigger: "blur"
            }],
          },
          {
            label:  this.$t('cip.plat.wfDesign.condition.field.expression'),
            prop: "expression",
            overHidden: true,
            align:'left',
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.condition.field.expression`),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.wfDesign.condition.field.type'),
            prop: "type",
            align:'center',
            overHidden: true,
            width: 140,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.condition.field.type`),
              trigger: "change"
            }],
            type: 'select',
            dicData: [{
              label: this.$t('cip.plat.wfDesign.condition.field.personnelConfiguration'),
              value: 'user'
            }, {
              label: this.$t('cip.plat.wfDesign.condition.field.flowCondition'),
              value: 'flow'
            }],
          },
          {
            label:  this.$t('cip.plat.wfDesign.condition.field.status'),
            prop: 'status',
            align:'center',
            overHidden: true,
            width: 120,
            type: 'select',
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.condition.field.status`),
              trigger: "change"
            }],
            // dicData: [{
            //   label: this.$t('cip.plat.wfDesign.condition.field.available'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfDesign.condition.field.forbidden'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
          }
        ]
      },
      data: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.wf_condition_add, false),
        viewBtn: this.vaildData(this.permission.wf_condition_view, false),
        delBtn: this.vaildData(this.permission.wf_condition_delete, false),
        editBtn: this.vaildData(this.permission.wf_condition_edit, false)
      };
    },
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plat.wfDesign.condition.field.name'),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.condition.field.name`),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      //新增按钮
      if (this.permission.wf_condition_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_condition_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-delete",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {

      let result = [];
      //新增按钮
      if (this.permission.wf_condition_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_condition_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-del",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      return result;
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    rowSave () {
      this.$router.push({
        path: '/workflow/design/conditionEdit',
        query: {
          type: 'add',
        }
      })
    },
    rowEdit (row) {
      let data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/workflow/design/conditionEdit',
        query: {
          type: 'edit',
          data: data
        }
      })
    },
    rowDel (row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.gridHeadSearch({})
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    handleDelete () {
      let selectionList = this.$refs.gridLayOut.selectionList
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    onLoad (page, params = {}) {
      this.page = page
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    }
  },
  mounted () {
    this.onLoad(this.page, {});
  },
};
</script>

<style>
</style>
