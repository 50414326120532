var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center previewDialog",
          attrs: { title: _vm.title, "lock-scroll": "", width: "600px" },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "preview-main" }, [
        _c(
          "div",
          {
            staticClass: "preview-item preview-item-pc",
            on: {
              click: function ($event) {
                return _vm.previewPc()
              },
            },
          },
          [
            _c("i", { staticClass: "preview-icon icon-ym icon-ym-pc" }),
            _c("div", { staticClass: "preview-txt" }, [
              _c("p", { staticClass: "preview-title" }, [_vm._v("Web预览")]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "preview-item",
            on: {
              click: function ($event) {
                return _vm.previewApp()
              },
            },
          },
          [
            _c("i", { staticClass: "preview-icon icon-ym icon-ym-mobile" }),
            _c("div", { staticClass: "preview-txt" }, [
              _c("p", { staticClass: "preview-title" }, [_vm._v("App预览")]),
            ]),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center code-dialog",
          attrs: {
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            "append-to-body": "",
            visible: _vm.previewAppVisible,
            title: _vm.title,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewAppVisible = $event
            },
            opened: _vm.getQRimg,
          },
        },
        [
          _c("div", { staticClass: "qrcode-img" }, [
            _c("div", { ref: "qrCode", attrs: { id: "qrcode" } }),
          ]),
          _c("p", { staticClass: "tip" }, [_vm._v("打开手机APP扫码预览")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }