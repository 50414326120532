<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="附件下载"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
        >
          <template #customBtn="{ row, index }">
            <el-button
              size="small"
              type="text"
              @click.stop="rowDownload(row)"
            >
              下载
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog :visible.sync="bpmnVisible"
               append-to-body
               destroy-on-close
               :title="$t(`cip.desk.workflow.title.indexHeadVisible`)"
               width="70%"
               custom-class="wf-dialog">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </el-dialog>
    <el-dialog :title="$t(`cip.plat.wfOps.process.title.processProgressTitle`)"
               :visible.sync="circulationVisible"
               width="35%">
      <wf-flow :flow="flow"></wf-flow>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {mapGetters} from "vuex";
import {pageByQuery} from "@/api/resource/attach";
import {downloadFile} from "@/api/resource/download";
import {downloadFileBase64} from "../../util/util";


export default {
  name: "eduexam",
  components: {
    HeadLayout,
    GridLayout
  },
  data() {
    return {
      bpmnOption: {},
      circulationVisible: false,
      bpmnVisible: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    searchColumns() {
      return [
        {
          placeholder: "请输入文件名",
          prop: "originalName",
          span: 4,
        },
        {
          placeholder: "请输入文件类型",
          prop: "extension",
          span: 4,
        },
      ]
    },
    tableOptions() {
      return {
        menu: true,
        // linklabel: "originalName",
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "文件名",
            prop: "originalName",
            align: 'left',
            overHidden: true,
          },
          {
            label: "类型",
            prop: "extension",
            sortable: true,
            width: 100,
            align: 'center',
            overHidden: true,
          },
          {
            label: "文件大小(KB)",
            prop: "attachSize",
            width: 120,
            align: 'center',
            overHidden: true,
          },
          {
            label: "上传日期",
            prop: "createTime",
            width: 200,
            align: "center",
            overHidden: true
          }
        ]
      }
    },
  },
  methods: {
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      pageByQuery(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
        const data = res.data.data;
        this.tableData = data.records;
        this.tableData.forEach((e) => {
          e.attachSize = parseInt(e.attachSize / 1024);
        })
        this.$refs.gridLayOut.page.total = data.total;
        this.tableLoading = false;
      });
    },
    rowDownload(row) {
      downloadFile(row.link, row.originalName).then((res) => {
        const blob = new Blob([res.data], {type: 'application/octet-stream'});
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = row.originalName; // 设置文件名
        a.click();

        window.URL.revokeObjectURL(url); // 释放 URL 对象
      })
    },
  },
  mounted() {
    this.onLoad(this.page)
  },
  created() {
    this.onLoad(this.page)
  }
}
</script>

<style scoped>
::v-deep .bjs-powered-by {
  display: none;
}
</style>
