<template>
  <div v-if="previewShow">
    <el-form ref="dataForm" :model="dataForm" label-width="100px" :disabled="disabled">
      <div class="preview" v-for="(item, index) in formOption" :key="index">
        <div class="previewTitle">
          {{ item.groupName }}
        </div>
        <el-row style="margin-top: 12px">
          <el-col
            :span="item.span"
            v-for="(items, indexs) in item.properties"
            :key="indexs"
          >
            <el-form-item
              :label="items.fieldName"
              :prop="items.fieldCode"
              :rules="[
                {
                  required: items.isEnable == '1' ? true : false,
                  message: items.inputPrompt || items.fieldName + '不能为空',
                },
              ]"
            >
              <el-input
                v-if="items.type == '1'"
                type="number"
                @input.native="inputParamDefaultVal"
                v-model="dataForm[items.fieldCode]"
                :placeholder="items.inputPrompt || '请输入' + items.fieldName"
              ></el-input>
              <el-input
                v-if="items.type == '2' "
                type="number"
                @input.native="inputParamDefaultVal"
                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                v-model="dataForm[items.fieldCode]"
                :placeholder="items.inputPrompt || '请输入' + items.fieldName"
              ></el-input>
              <el-input
                v-if="items.type == '3' "
                type="text"
                :maxlength="items.inputLength"
                v-model="dataForm[items.fieldCode]"
                :placeholder="items.inputPrompt || '请输入' + items.fieldName"
              ></el-input>
              <el-date-picker
                v-if="items.type == '4'"
                v-model="dataForm[items.fieldCode]"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :placeholder="items.inputPrompt || '请选择' + items.fieldName"
              >
              </el-date-picker>
              <el-select v-model="dataForm[items.fieldCode]" ref="select"
                         :placeholder="items.inputPrompt || '请选择' + items.fieldName"
                         v-if="items.type == '5'">
                <el-option
                  v-for="dic in items.dictionaryOption"
                  :key="dic.dictKey"
                  :label="dic.dictValue"
                  :value="dic.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataForm: {},
      type:'',
      previewShow:false,
      formOption: [],
      disabled:false
    };
  },
  methods: {
    inputParamDefaultVal(e) {
      console.log(e,'85')
    }
  },
};
</script>
<style lang="scss" scoped>
.preview {
  width: 100%;
}

.previewTitle {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
</style>
