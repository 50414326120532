var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t(`cip.index.atomIndex.title`),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-all": function ($event) {
            return _vm.headSaveAll()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
          "head-query": _vm.headQuery,
          "head-start": _vm.headStartTask,
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c("IndexCode", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataForm.confType === "COMPOSITE_INDEX",
                expression: "dataForm.confType === 'COMPOSITE_INDEX'",
              },
            ],
            ref: "indexCode",
            attrs: { "head-form": _vm.dataForm, type: _vm.type },
          }),
          _c(
            "el-tabs",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isScopeVisiable,
                  expression: "isScopeVisiable",
                },
              ],
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.index.omission.appIndexScopes`),
                    name: "scope",
                  },
                },
                [
                  _c("scope", {
                    ref: "scope",
                    attrs: {
                      scopeTable: _vm.dataForm.appIndexScopes,
                      headForm: _vm.dataForm,
                      type: _vm.type,
                      themeId: _vm.themeId,
                      indexId: _vm.id,
                    },
                    on: {
                      "update:headForm": function ($event) {
                        _vm.dataForm = $event
                      },
                      "update:head-form": function ($event) {
                        _vm.dataForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.index.omission.appIndexMeasures`),
                    name: "measure",
                  },
                },
                [
                  _c("measure", {
                    ref: "measure",
                    attrs: {
                      tableData: _vm.dataForm.appIndexMeasures,
                      headForm: _vm.dataForm,
                      type: _vm.type,
                    },
                    on: {
                      "update:headForm": function ($event) {
                        _vm.dataForm = $event
                      },
                      "update:head-form": function ($event) {
                        _vm.dataForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dyn-table-dialog", {
        ref: "dynTableDialog",
        attrs: { headForm: _vm.dataFormOld, type: _vm.type },
        on: {
          "update:headForm": function ($event) {
            _vm.dataFormOld = $event
          },
          "update:head-form": function ($event) {
            _vm.dataFormOld = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }