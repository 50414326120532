<template>
  <el-dialog
    :close-on-click-modal="false"
    v-dialog-drag
    :title="title"
    :visible.sync="visible"
    class="avue-dialog avue-dialog--top"
    width="60%"
  >
    <grid-head-layout
      ref="searchForm"
      v-if="searchColumns.length"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    ></grid-head-layout>
    <grid-layout
      v-if="visible"
      ref="gridLayout"
      class="warehouseTable"
      :data-total="dataTotal"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :grid-row-btn="gridRowBtn"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @row-select="rowSelect"
    >
      <!-- <template
        #customBtn="{ row }">
        <el-button
          v-if="!disabledList.includes(row.warehouseCode)"
          type="text"
          size="small"
          @click="rowSelect(row)">
          {{ $t('cip.cmn.btn.defBtn') }}
        </el-button>
      </template> -->
    </grid-layout>
    <div v-if="!singleSelection && !isDetail" class="avue-dialog__footer">
      <el-button :disabled="tableLoading" size="small" @click="close">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
      <el-button :disabled="tableLoading" size="small" @click="submit" type="primary">
        {{ $t('cip.cmn.btn.defBtn') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import GridLayout from "@/views/components/layout/grid-layout";

export default {
  name: "tableDialog",
  components: {
    GridLayout,
  },
  props: {
    isDetail: {
      default: false,
      type: Boolean,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      default: 'dialog',
      type: String,
    },
    singleSelection: {
      default: false,
      type: Boolean,
    },
    reserveSelection: {
      default: false,
      type: Boolean,
    },
    selectPage: {
      type: Function,
      default: () => {
        this.$message.error("ERROR")
      }
    },
    rowKey: {
      default: 'id',
      type: String,
    },
    column: {
      default: [],
      type: Array
    },
    searchColumns: {
      default: [],
      type: Array
    },
    selectable: {
      type: Function,
      default: () => {
        return true
      },
    },
  },
  data() {
    return {
      query: {},
      dataTotal: 0,
      tableLoading: false,
      disabledList: [],
      tableOptions: {
        height: 300,
        editBtn: false,
        menu: this.singleSelection && !this.isDetail,
        selection: !this.singleSelection,
        rowKey: this.rowKey,
        selectable: this.selectable,
        reserveSelection: this.reserveSelection,
        column: this.column,
      },
      tableData: [],
      page: {
        pageSize: 20,
        currentPage: 1,
      },
    }
  },
  computed: {
    gridRowBtn() {
      if (this.singleSelection) {
        return [{
          btnOptType: 'select',
          label: this.$t('cip.cmn.btn.defBtn'),
          emit: 'row-select',
        }];
      }
      return [];
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.$emit("update:visible", val)
      }
    }
  },
  methods: {
    searchReset(params) {
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    searchChange(params) {
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      let searchForm = {};
      if (this.$refs.searchForm) {
        searchForm = this.$refs.searchForm.searchForm
      }
      this.selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(this.query, params, searchForm)
      ).then(async res => {
        const data = res.data.data;
        this.dataTotal = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records
        this.tableLoading = false;
      });
    },
    async openDialog(query = {}) {
      this.query = query;
      this.visible = true;
      await this.$nextTick()
      if (this.$refs.searchForm) {
        this.$refs.searchForm.searchForm = {};
      }
      this.onLoad(this.page)
    },
    close() {
      this.$emit('close-dialog', () => {
        this.visible = false
      })
    },
    rowSelect(row) {
      this.tableLoading = true
      this.$emit('select-data', row, (clo = true) => {
        this.tableLoading = false
        if (clo) {
          this.visible = false
        }
      });
    },
    submit() {
      this.tableLoading = true
      let selection = this.$refs.gridLayout.selectionList;
      this.$emit('select-data', selection, (clo = true) => {
        this.tableLoading = false
        if (clo) {
          this.visible = false
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .warehouseTable .avue-crud .el-table {
  height: calc(100vh - 420px) !important;
  max-height: calc(100vh - 420px) !important;
}
</style>
