var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _c("div", { staticClass: "SNOWS-common-layout-center" }, [
        _c(
          "div",
          {
            staticClass: "SNOWS-common-head",
            staticStyle: {
              "text-align": "right",
              display: "flex",
              "align-items": "center",
              "background-color": "#fff",
              "border-bottom": "1px solid rgb(204, 204, 204)",
            },
          },
          [
            _c(
              "el-col",
              {
                staticStyle: {
                  float: "left",
                  display: "flex",
                  "align-items": "center",
                  height: "28px",
                },
                attrs: { span: "14" },
              },
              [
                _c("avue-avatar", {
                  style: { backgroundColor: _vm.themeColor },
                  attrs: {
                    shape: "square",
                    icon: "el-icon-tickets",
                    size: "small",
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "headLabel",
                    staticStyle: { "font-size": "14px", "margin-left": "6px" },
                  },
                  [_vm._v(_vm._s("流程表单"))]
                ),
              ],
              1
            ),
            _c(
              "el-col",
              [
                _c(
                  "el-badge",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.addForm(0, 1)
                          },
                        },
                      },
                      [_vm._v(_vm._s("新增"))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-row",
              {
                staticClass: "SNOWS-common-search-box",
                staticStyle: {
                  "background-color": "#FFFFFF",
                  padding: "14px 10px 0 10px",
                },
                attrs: { gutter: 5 },
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: { model: _vm.query, inline: "" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入表单名称查询",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.search()
                                    },
                                  },
                                  model: {
                                    value: _vm.query.fullName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.query, "fullName", $$v)
                                    },
                                    expression: "query.fullName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入表单编码查询",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.search()
                                    },
                                  },
                                  model: {
                                    value: _vm.query.enCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.query, "enCode", $$v)
                                    },
                                    expression: "query.enCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.search()
                                    },
                                  },
                                }),
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    icon: "reset-refresh icon-refresh",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "SNOWS-common-layout-main SNOWS-flex-main" },
          [
            _c(
              "SNOWS-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                attrs: { data: _vm.list },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "fullName",
                    label: "表单名称",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", prop: "enCode", label: "表单编码" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "formType",
                    label: "表单类型",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.formType == 2
                                  ? "自定义表单"
                                  : scope.row.flowType == 1
                                  ? "功能表单"
                                  : "系统表单"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "状态", width: "80", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type:
                                  scope.row.enabledMark == 1
                                    ? "success"
                                    : "info",
                                "disable-transitions": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    scope.row.enabledMark == 1
                                      ? "已发布"
                                      : "未发布"
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "creatorUserId",
                    label: "创建人",
                    width: "170",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "creatorTime",
                    label: "创建时间",
                    formatter: _vm.snows.tableDateFormat,
                    width: "170",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "操作",
                    fixed: "right",
                    width: "200",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "tableOpts",
                            {
                              on: {
                                edit: function ($event) {
                                  return _vm.addOrUpdateHandle(
                                    scope.row.id,
                                    scope.row.formType,
                                    0
                                  )
                                },
                                del: function ($event) {
                                  return _vm.handleDel(scope.row.id)
                                },
                              },
                            },
                            [
                              _c(
                                "el-dropdown",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                        },
                                        [
                                          _vm._v(_vm._s("更多")),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.releaseForm(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      发布表单\n                    "
                                          ),
                                        ]
                                      ),
                                      scope.row.enabledMark
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.rollBackForm(
                                                    scope.row.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      回滚表单\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.copy(scope.row.id)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      复制表单\n                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("pagination", {
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.currentPage,
                limit: _vm.listQuery.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "currentPage", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                pagination: _vm.initData,
              },
            }),
          ],
          1
        ),
      ]),
      _vm.formVisible
        ? _c("Form", { ref: "Form", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.previewVisible
        ? _c("preview", {
            ref: "preview",
            on: {
              close: function ($event) {
                _vm.previewVisible = false
              },
            },
          })
        : _vm._e(),
      _c("previewDialog", {
        attrs: {
          visible: _vm.previewDialogVisible,
          id: _vm.currRow.id,
          type: "flow",
          dataSource: _vm.currRow.dataSource,
        },
        on: {
          "update:visible": function ($event) {
            _vm.previewDialogVisible = $event
          },
          previewPc: _vm.previewPc,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }