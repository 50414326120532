var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: _vm.title,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showDialog,
            width: "40%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("avue-form", {
            ref: "addForm",
            attrs: { option: _vm.options },
            on: { submit: _vm.submitForm },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.closeDialog } },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitBtn },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }