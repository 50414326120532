var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-container",
        [
          _c("wf-category", {
            on: {
              "node-click": _vm.nodeClick,
              "list-change": function ($event) {
                _vm.findObject(_vm.option.column, "categoryId").dicData = $event
              },
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    "cip.plat.wfDesign.form.title.indexHeadTitle"
                  ),
                },
                on: {
                  "head-add": _vm.headAdd,
                  "head-change": _vm.handleChangeCategory,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.option,
                  "table-data": _vm.data,
                  "table-loading": _vm.loading,
                  "data-total": _vm.page.total,
                  "grid-row-btn": _vm.gridRowBtn,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "row-edit": _vm.rowEdit,
                  "row-del": _vm.rowDel,
                  "row-design": _vm.handleDesign,
                  "row-copy": _vm.handleCopy,
                  "row-history": _vm.handleHistory,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formVisible,
            "append-to-body": "",
            title: _vm.title,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formVisible = $event
            },
          },
        },
        [
          _vm.formVisible
            ? _c("avue-form-design", {
                ref: "formDesign",
                staticStyle: { height: "88vh" },
                attrs: {
                  toolbar: ["clear", "preview", "import", "generate"],
                  includeFields: [
                    "group",
                    "dynamic",
                    "input",
                    "textarea",
                    "number",
                    "map",
                    "radio",
                    "checkbox",
                    "select",
                    "tree",
                    "cascader",
                    "date",
                    "time",
                    "datetime",
                    "daterange",
                    "datetimerange",
                    "timerange",
                    "switch",
                    "rate",
                    "color",
                    "icon",
                    "slider",
                  ],
                  customFields: _vm.customFields,
                  "default-values": _vm.defaultValues,
                  options: _vm.options,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                size: "medium",
                                icon: "el-icon-download",
                              },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.saveBtn`)))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3443043247
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.copyVisible,
            "append-to-body": "",
            title: _vm.$t("cip.plat.wfDesign.form.title.copyHeadTitle"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.copyVisible = $event
            },
          },
        },
        [
          _c("avue-form", {
            ref: "copyForm",
            attrs: { option: _vm.copyOption },
            on: { submit: _vm.handleCopySubmit },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.categoryVisible,
            "append-to-body": "",
            title: _vm.$t("cip.plat.wfDesign.form.title.arrangeHeadTitle"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.categoryVisible = $event
            },
          },
        },
        [
          _c("avue-form", {
            attrs: {
              option: {
                column: [
                  {
                    type: "tree",
                    label: this.$t(`cip.plat.wfDesign.form.field.categoryId`),
                    span: 24,
                    props: { label: "name", value: "id" },
                    prop: "category",
                    dicUrl: "/api/sinoma-workflow/design/category/tree",
                    required: true,
                    rules: [
                      {
                        required: true,
                        message:
                          this.$t(`cip.cmn.rule.selectWarning`) +
                          this.$t(`cip.plat.wfDesign.form.field.categoryId`),
                      },
                    ],
                  },
                ],
              },
            },
            on: { submit: _vm.handleChangeCategorySubmit },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }