var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "el-container",
        [
          _c(
            "div",
            { staticClass: "leftNavigation" },
            [
              _c(
                "el-menu",
                {
                  attrs: { "default-active": _vm.currentIndexLight },
                  on: { select: _vm.handleSelect },
                },
                _vm._l(_vm.menuList, function (item) {
                  return _c(
                    "div",
                    { key: item.path },
                    [
                      item.children && item.children.length == 0
                        ? [
                            _c(
                              "el-menu-item",
                              {
                                attrs: { index: item.path },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickMenu(item)
                                  },
                                },
                              },
                              [
                                _c("i", { class: item.icon }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        : _c(
                            "el-submenu",
                            { attrs: { index: item.path } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("i", { class: item.icon }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                              ]),
                              _vm._l(item.children, function (child) {
                                return [
                                  child.children && child.children.length > 0
                                    ? _c("sidebar-item", {
                                        key: child.path,
                                        attrs: { item: child },
                                      })
                                    : _c(
                                        "el-menu-item",
                                        {
                                          key: child.path,
                                          attrs: { index: child.path },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickMenu(child)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", { class: child.icon }),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(child.title) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                    ],
                    2
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "el-main",
            [
              _c(
                "div",
                { staticClass: "crumbsBox" },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "16px" },
                          attrs: {
                            icon: "el-icon-back",
                            round: "",
                            size: "small",
                            type: "primary",
                          },
                          on: { click: _vm.returnUpperLevel },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "cip.plat.resource.attach.btn.upOneLevelBtn"
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "cip.plat.resource.attach.field.currentLocation"
                            )
                          ) +
                          ":\n            "
                      ),
                      _c(
                        "el-breadcrumb",
                        {
                          staticClass: "crumsPlate",
                          attrs: { "separator-class": "el-icon-arrow-right" },
                        },
                        _vm._l(_vm.breadList, function (item, index) {
                          return _c("el-breadcrumb-item", { key: index }, [
                            _vm._v(_vm._s(item)),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: "/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeSuccess,
                        "on-success": _vm.uploadSuccess,
                        "http-request": _vm.handleHttpRequest,
                        multiple: "",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cip.plat.resource.attach.btn.UploadBtn")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-popover",
                    { attrs: { placement: "bottom", trigger: "hover" } },
                    [
                      _c(
                        "el-button-group",
                        _vm._l(_vm.emergeList, function (item, index) {
                          return _c(
                            "el-button",
                            {
                              key: index,
                              attrs: {
                                icon: item.icon,
                                type: _vm.emergeIndex == index ? "primary" : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseEmerge(item, index)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            size: "small",
                            type: "primary",
                          },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "cip.plat.resource.attach.btn.howToPresentBtn"
                                )
                              ) +
                              ":"
                          ),
                          _c("i", {
                            staticClass: "el-icon-menu el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "exhibitionBox" }, [
                _c(
                  "div",
                  { staticClass: "tabelBox" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.emergeIndex == 0,
                            expression: "emergeIndex == 0",
                          },
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "fileTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.data,
                          height: "680",
                          "highlight-current-row": "",
                        },
                        on: {
                          "row-click": _vm.fileDetails,
                          "row-dblclick": _vm.openFile,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { align: "right", prop: "link", width: "80" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("img", {
                                    attrs: {
                                      src: scope.row.link,
                                      alt: "",
                                      height: "34",
                                      "min-width": "34",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": true,
                            align: "left",
                            label: _vm.$t(
                              "cip.plat.resource.attach.field.filename"
                            ),
                            prop: "fileName",
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        scope.row.fileName ||
                                          scope.row.folderName
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.$t(
                              "cip.plat.resource.attach.field.type"
                            ),
                            prop: "extension",
                            sortable: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.$t(
                              "cip.plat.resource.attach.field.size"
                            ),
                            prop: "totalSize",
                            sortable: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.$t(
                              "cip.plat.resource.attach.field.dateUpload"
                            ),
                            prop: "createTime",
                            sortable: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.emergeIndex == 1,
                            expression: "emergeIndex == 1",
                          },
                        ],
                        staticClass: "netWorkBox",
                      },
                      _vm._l(_vm.data, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "netWork",
                            class:
                              _vm.netWorkIndex == index ? "netWorkActive" : "",
                            on: {
                              click: function ($event) {
                                return _vm.chooseNetWork(item, index)
                              },
                              dblclick: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "netWorkImg",
                              attrs: { src: item.link },
                            }),
                            _c("p", [_vm._v(_vm._s(item.originalName))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "paginationBox" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.page.currentPage,
                            "page-size": _vm.page.pageSize,
                            "page-sizes": [10, 20, 30, 40, 50, 100],
                            total: _vm.page.total,
                            background: "",
                            layout: "total, sizes, prev, pager, next, jumper",
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "informationBox" }, [
                  _c("div", { staticClass: "detailsRightTitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("cip.plat.resource.attach.title.viewHeadTitle")
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "detailsRightBox" },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.detailsForm, size: "small" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.resource.attach.field.fileSize"
                                ),
                                prop: "attachSize",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.detailsForm.totalSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detailsForm, "totalSize", $$v)
                                  },
                                  expression: "detailsForm.totalSize",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.resource.attach.field.creationTime"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.detailsForm.createTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detailsForm, "createTime", $$v)
                                  },
                                  expression: "detailsForm.createTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.resource.attach.field.attachmentExtension"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.detailsForm.extension,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detailsForm, "extension", $$v)
                                  },
                                  expression: "detailsForm.extension",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.resource.attach.field.attachmentAddress"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.detailsForm.objectKey,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detailsForm, "objectKey", $$v)
                                  },
                                  expression: "detailsForm.objectKey",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.resource.attach.field.attachmentName"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.detailsForm.fileName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detailsForm, "fileName", $$v)
                                  },
                                  expression: "detailsForm.fileName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.attachBox,
                    "append-to-body": "",
                    title: _vm.$t("cip.plat.resource.attach.field.management"),
                    width: "555px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.attachBox = $event
                    },
                  },
                },
                [
                  _c("avue-form", {
                    ref: "form",
                    attrs: {
                      option: _vm.attachOption,
                      "upload-after": _vm.uploadAfter,
                    },
                    model: {
                      value: _vm.attachForm,
                      callback: function ($$v) {
                        _vm.attachForm = $$v
                      },
                      expression: "attachForm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeFileDia,
            title: _vm.fileTitle,
            visible: _vm.fileShow,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fileShow = $event
            },
          },
        },
        [
          _vm.extension == "img"
            ? _c("img", {
                staticClass: "fileContainer",
                attrs: { src: _vm.fileSrc },
              })
            : _vm._e(),
          _vm.extension == "video"
            ? _c("video", {
                staticClass: "fileContainer",
                attrs: { src: _vm.fileSrc, autoplay: "", controls: "" },
              })
            : _vm._e(),
          _vm.extension == "audio"
            ? _c("audio", {
                staticStyle: { width: "100%" },
                attrs: {
                  src: _vm.fileSrc,
                  autoplay: "",
                  controls: "",
                  type: "audio/mp3",
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }