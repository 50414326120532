<template>
  <el-container>
    <CommonTree ref="tree" :treeData="treeData" :defaultProps="defaultProps" :searchTitle="searchTitle" :treeTitle="this.$t('cip.index.appIndex.theme.treeTitle')"
                :isShowdig="false" :showCheckbox="false" @getNodeClick="treeNodeClick" node-key="id" />
    <el-main>
      <head-layout :head-btn-options="headBtnOptions" :head-title="$t(`cip.index.atomIndex.headTitle`)"></head-layout>
      <div class="headers">
        <grid-head-layout ref="gridHeadLayout" :grid-head-btn="gridHeadBtn" :search-columns="searchColumns"
                          @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      </div>
      <grid-layout ref="gridLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOptions" :table-data="tableData"
                   :table-loading="crudLoading" :data-total="dataTotal" @page-current-change="onLoad" @page-size-change="onLoad"
                   @page-refresh-change="onLoad">
      </grid-layout>
    </el-main>
  </el-container>
</template>

<script>

import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

import {getTreeList} from "@/api/dataCenter/programManage";
import {extIndexPage as getList} from "@/api/index/appIndexManage";

export default {
  name: "AppIndexPage",
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  props:{
    dataFormExt:{},
  },
  data() {
    return {
      treeData: [],
      defaultProps: {
        label: "name",
        value: "id",
        key: "id"
      },
      searchTitle: 'title',
      query: {},
      themeId: undefined,
      crudLoading: false,
      selectionList: [],
      dataTotal: 0,
      tableData: [],
    }
  },
  computed: {
    headBtnOptions() {
      return []
    },
    gridHeadBtn() {
      return []
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "indexCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.atomIndex.indexCode`)
        },
        {
          label: "",
          prop: "indexName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.atomIndex.indexName`),
        },
      ]
    },
    gridRowBtn() {
      return []
    },
    tableOptions() {
      return {
        menu: false,
        linklabel: "indexCode",
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.index.atomIndex.indexCode'),
            prop: "indexCode",
            search: true,
          },
          {
            label: this.$t('cip.index.atomIndex.indexName'),
            prop: "indexName",
            search: true,
          },
          {
            label: this.$t('cip.index.appIndex.theme.confType'),
            prop: "confType",
            align: 'center',
            width: 100,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_conf_type",
          },
          //指标单位
          {
            label: this.$t('cip.index.appIndex.theme.unitCode'),
            prop: "unitCode",
            align: 'center',
            width: 100,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_unit",
          },
          //时间维度
          {
            label: this.$t(`cip.index.omission.timeDimension`),
            prop: "timeDimension",
            align: 'center',
            width: 120,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          },
          //指标级别
          {
            label: this.$t(`cip.index.omission.indexLevel`),
            prop: "indexLevel",
            align: 'center',
            width: 120,
            value: 'y',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_level",
          },
          {
            label: this.$t(`cip.index.omission.isCache`),
            prop: "isCache",
            align: 'center',
            width: 120,
            type: 'select',
            // dicData: [
            //   {
            //     label: '是',
            //     value: 1
            //   },
            //   {
            //     label: '否',
            //     value: 0
            //   },
            // ]
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: 'number',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
          }
        ]
      }
    },
  },
  methods: {
    getSelectionList() {
      return this.$refs.gridLayOut.selectionList
    },
    treeNodeClick(node) {
      this.node = node;
      const { isTheme, id } = node;
      this.themeId = id;
      this.isTheme = isTheme;

      this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, this.$refs.gridHeadLayout.searchForm);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.crudLoading = true;
      this.query.themeId = this.themeId;
      Object.assign(this.query, this.dataFormExt)
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.dataTotal = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
          this.$nextTick(() => {
            this.$refs.gridHeadLayout.searchForm = p1.query
          })

        })
        .catch(() => {
          this.crudLoading = true;
        });
    },
    gridHeadSearch(query) {
      this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, query)
    },
    gridHeadEmpty(query) {
      this.themeId = null
      this.query.themeId = null
      this.$refs.tree.$refs.commonTree.setCurrentKey(null)
      this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, query)
    },
  },
  created() {
    getTreeList()
      .then((result) => {
        this.treeData = result.data.data;
        this.treeLoading = false;

        this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, this.$refs.gridHeadLayout.searchForm);
      })
      .catch((err) => {
        this.treeLoading = true;
      });
  },
}
</script>

<style scoped>

</style>
