<template>
  <basic-container>
    <!--    <avue-skeleton :loading="waiting"-->
    <!--                   avatar-->
    <!--                   :rows="8">-->
    <!--      <avue-affix id="avue-view"-->
    <!--                  :offset-top="114">-->
    <div class="header">
      <avue-title :value="process.processDefinitionName"></avue-title>
<!--      <div v-if="process.status != 'todo'" style="display: flex">-->
<!--        <div style="width: 60px;text-align: right;line-height: 28px">主题：</div>-->
<!--        <avue-select v-model="theme"-->
<!--                     :clearable="false"-->
<!--                     :dic="themeList"-->
<!--                     size="mini"></avue-select>-->
<!--      </div>-->
    </div>
    <div class="main">
      <el-tabs v-model="activeName" style="background-color: #FFFFFF; padding: 12px" type="card">
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadFirst`)"
                     name="first">
          <el-card shadow="never" style="border-bottom: 0">
            <div id="printBody"
                 :class="process.status != 'todo' ? `wf-theme-${theme}`: ''">
              <avue-form v-if="summaryOption && ((summaryOption.column && summaryOption.column.length > 0) || (summaryOption.group && summaryOption.group.length > 0))"
                         ref="summaryForm"
                         v-model="form"
                         :option="summaryOption"
                         :upload-preview="handleUploadPreview"
                         style="margin-bottom: 20px;">
              </avue-form>
              <avue-form v-if="option && ((option.column && option.column.length > 0) || (option.group && option.group.length > 0))"
                         ref="form"
                         v-model="form"
                         :defaults.sync="defaults"
                         :option="option"
                         :upload-preview="handleUploadPreview">
              </avue-form>
            </div>
          </el-card>
          <el-card v-if="process.status == 'todo'" shadow="never"
                   style="border-top: 0">
            <wf-examine-form ref="examineForm"
                             :comment.sync="comment"
                             :process="process"
                             @user-select="handleUserSelect"></wf-examine-form>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadSecond`)"
                     name="second">
          <el-card shadow="never"
          >
            <wf-flow :flow="flow"></wf-flow>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadThird`)"
                     name="third">
          <template v-if="activeName == 'third'">
            <el-card shadow="never"
            >
              <wf-design ref="bpmn"
                         :options="bpmnOption"
                         style="height: 500px;"></wf-design>
            </el-card>
          </template>
        </el-tab-pane>
      </el-tabs>

    </div>
    <!-- 底部按钮 -->
    <div style="flex: 1">
      <wf-button :button-list="buttonList"
                 :comment="comment"
                 :loading="submitLoading"
                 :process="process"
                 @examine="handleExamine"
                 @print="handlePrint"
                 @rollback="handleRollbackTask"
                 @terminate="handleTerminateProcess"
                 @withdraw="handleWithdrawTask"
                 @user-select="handleUserSelect"></wf-button>
    </div>


    <!--      </avue-affix>-->
    <!--    </avue-skeleton>-->


    <!-- 人员选择弹窗 -->
    <user-select ref="user-select"
                 :check-type="checkType"
                 :default-checked="defaultChecked"
                 @onConfirm="handleUserSelectConfirm"></user-select>
  </basic-container>
</template>

<script>
import WfExamineForm from './examForm.vue'
import WfButton from './button.vue'
import WfFlow from './flow.vue'
import userSelect from './user-select'

import exForm from '../../mixins/ex-form'
import theme from '../../mixins/theme'

export default {
  mixins: [exForm, theme],
  components: {userSelect, WfExamineForm, WfButton, WfFlow},
  watch: {
    '$route.params.params': {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const {taskId, processInsId} = param
          if (taskId && processInsId) this.getDetail(taskId, processInsId)
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      activeName: 'first',
      defaults: {},
      form: {},
      option: {},
      vars: [], // 需要提交的字段
      submitLoading: false, // 提交时按钮loading
      summaryOption: {}, // 汇总表单option
    }
  },
  methods: {
    // 获取任务详情
    getDetail(taskId, processInsId) {
      this.getTaskDetail(taskId, processInsId).then(res => {
        const {process, form} = res
        const {variables, status} = process

        let {allForm, taskForm, formList} = form
        if (formList && formList.length > 0) {
          const options = {
            menuBtn: false,
            detail: true,
            group: []
          }
          formList.forEach(f => {
            const {content, taskName, taskKey} = f
            const {option} = this.handleResolveOption(eval('(' + content + ')'), taskForm, 'done')
            options.group.push({
              label: taskName || taskKey,
              collapse: allForm ? false : true,
              column: option.column
            })
          })
          this.summaryOption = options
        }
        if (allForm) {
          const {option, vars} = this.handleResolveOption(eval('(' + allForm + ')'), taskForm, status)
          option.menuBtn = false
          for (let key in variables) {
            if (!variables[key]) delete variables[key]
          }
          if (option.column && process.variables && process.variables.serialNumber) {
            option.column.unshift({
              label: this.$t('cip.desk.workflow.field.SerialNumber'),
              prop: 'serialNumber',
              span: 24,
              detail: true,
            })
          }
          console.log("option", option)
          this.option = option
          this.vars = vars
        }
        this.form = variables
        this.waiting = false
      })
    },
    handleResolveOption(option, taskForm, status) {
      const {column, group} = option
      let vars = []
      if (status != 'todo') { // 已办，删除字段默认值
        option.detail = true
        if (column && column.length > 0) { // 处理column
          column.forEach(col => this.handleResolveEvent(col))
        }

        if (group && group.length > 0) { // 处理group
          group.forEach(gro => {
            if (gro.column && gro.column.length > 0) {
              gro.column.forEach(col => this.handleResolveEvent(col))
            }
          })
        }
      } else {
        const columnFilter = this.filterAvueColumn(column, taskForm)
        const columnArr = columnFilter.column
        vars = columnFilter.vars || []

        const groupArr = []
        if (group && group.length > 0) { // 处理group
          group.forEach(gro => {
            const groupFilter = this.filterAvueColumn(gro.column, taskForm)
            gro.column = groupFilter.column
            vars = vars.concat(groupFilter.vars)
            if (gro.column.length > 0) groupArr.push(gro)
          })
        }
        option.column = columnArr
        option.group = groupArr
      }
      return {option, vars}
    },
    handleResolveEvent(col) {
      const _this = this
      delete col.value
      let event = ['change', 'blur', 'click', 'focus']
      event.forEach(e => {
        if (col[e]) col[e] = eval((col[e] + '').replace(/this/g, '_this'))
      })
      if (col.event) Object.keys(col.event).forEach(key => col.event[key] = eval((col.event[key] + '').replace(/this/g, '_this')))

      if (col.type == 'dynamic') col.children.column.forEach(cc => _this.handleResolveEvent(cc))
    },
    // 审核
    handleExamine(pass) {
      this.submitLoading = true
      const {form, summaryForm} = this.$refs
      if (form) {
        this.$refs.form.validate((valid, done, msg) => {
          if (valid) {
            const variables = {}
            this.vars.forEach(v => {
              if (v != 'comment' && this.form[v]) variables[v] = this.form[v]
            })

            this.handleCompleteTask(pass, variables).then(() => {
              this.$message.success("处理成功")
              this.handleCloseTag('/plugin/workflow/process/todo')
            }).catch(() => {
              if (typeof done == 'function') done()
              this.submitLoading = false
            })
          } else {
            done()
            this.submitLoading = false
            if (msg) {
              const key = Object.keys(msg)[0]
              const rules = msg[key]
              this.$message.error(rules.map(r => r.message).join(' | '))
            }
          }
        })
      } else if (summaryForm) {
        this.handleCompleteTask(pass, {}).then(() => {
          this.$message.success("处理成功")
          this.handleCloseTag('/plugin/workflow/process/todo')
        }).catch(() => {
          this.submitLoading = false
        })
      } else this.$message.error('找不到需要提交的表单')
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .bjs-powered-by {
  display: none;
}
.header {
  //width: 100%;
  height: 50px;
  flex: 1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 0;
}

.main {
  flex: 1;
}

.avue-affix ::v-deep {
  position: static;
}
</style>
