var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$t(`cip.desk.workflow.title.indexHead1Title`),
        },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.rowView,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-detail": _vm.rowTrackPending,
          "row-flow": _vm.handleFlow,
        },
      }),
      _vm.bpmnVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                customClass: "wf-dialog",
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }