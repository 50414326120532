<template>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="应急组织" name="emergencyOrganization">
        <emergencyOrganization ref="emergencyOrganization" @uploadImgSuccess="uploadImgSuccess"></emergencyOrganization>
      </el-tab-pane>
      <!-- <el-tab-pane label="处置程序" name="disposalProcedures">
        <disposalProcedures ref="disposalProcedures"></disposalProcedures>
      </el-tab-pane>
      <el-tab-pane label="处置措施" name="disposalMeasures">
        <disposalMeasures ref="disposalMeasures"></disposalMeasures>
      </el-tab-pane> -->
      <el-tab-pane label="应急物资" name="emergencySupplies">
        <emergencySupplies ref="emergencySupplies"></emergencySupplies>
      </el-tab-pane>
      <el-tab-pane label="演练记录" name="exerciseRecords">
        <exerciseRecords ref="exerciseRecords"></exerciseRecords>
      </el-tab-pane>
    </el-tabs>
</template>
<script>
import emergencyOrganization from "./emergencyOrganization.vue";
import disposalProcedures from "./disposalProcedures.vue";
import disposalMeasures from "./disposalMeasures.vue";
import emergencySupplies from "./emergencySupplies.vue";
import exerciseRecords from "./exerciseRecordsList.vue";
export default {
  components: {
    emergencyOrganization,
    disposalProcedures,
    disposalMeasures,
    emergencySupplies,
    exerciseRecords
  },
  data() {
    return {
      activeName: "emergencyOrganization",
      planId:'',
      pageDisabled:false,
      erStatus: "PREPARE", // 默认草稿
      orgImgUrl:'', //默认空
      addForm:{}
    };
  },
  methods: {
    // 初始化
    init(id,pageDisabled,erStatus,orgImgUrl,addForm){
      this.planId = id
      this.pageDisabled = pageDisabled
      this.erStatus = erStatus;
      this.orgImgUrl = orgImgUrl
      this.addForm = addForm
      this.$refs.emergencyOrganization.init(this.planId,pageDisabled,erStatus,orgImgUrl)
      // this.$refs.disposalProcedures.init(this.planId,pageDisabled,erStatus)
      // this.$refs.disposalMeasures.init(this.planId,pageDisabled,erStatus)
      this.$refs.emergencySupplies.init(this.planId,pageDisabled,erStatus)
      this.$refs.exerciseRecords.init(this.planId,pageDisabled,erStatus,this.orgImgUrl,this.addForm.endDate)
    },
    handleClick(tab, event) {
      this.$refs[this.activeName].init(this.planId,this.pageDisabled,this.erStatus,this.orgImgUrl,this.addForm.endDate)
    },
    uploadImgSuccess(url){
      this.orgImgUrl = url
    }
  },
};
</script>
