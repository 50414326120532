var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showAddIndexWithCategoryDialog
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title: _vm.$t(`cip.index.app.category.listHeader`),
                modal: false,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.showAddIndexWithCategoryDialog,
                width: "60%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showAddIndexWithCategoryDialog = $event
                },
              },
            },
            [
              _c("div", { staticClass: "positionabs" }, [
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: {
                    click: function ($event) {
                      return _vm.fullClick()
                    },
                  },
                }),
              ]),
              _c("avue-form", {
                ref: "formLayout",
                attrs: { option: _vm.option },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
              _c("queryIndexManageWithCategory", {
                ref: "queryIndexManageWithCategory",
                attrs: { "shou-index-manage": true },
              }),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showAddIndexWithCategoryDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.celBtn`)))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.defBtn`)))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }