var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fieldFrom" },
    [
      _vm._m(0),
      _c(
        "el-table",
        { attrs: { data: _vm.drawingList, size: "mini" } },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              width: "50",
              label: "序号",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "filedName", label: "名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "输入名称" },
                      model: {
                        value: scope.row.filedName,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "filedName", $$v)
                        },
                        expression: "scope.row.filedName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "filedId", label: "字段" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "输入字段" },
                      model: {
                        value: scope.row.filedId,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "filedId", $$v)
                        },
                        expression: "scope.row.filedId",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "jnpfKey", label: "控件类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "选择控件类型", clearable: "" },
                        model: {
                          value: scope.row.jnpfKey,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "jnpfKey", $$v)
                          },
                          expression: "scope.row.jnpfKey",
                        },
                      },
                      _vm._l(_vm.componentList, function (group) {
                        return _c(
                          "el-option-group",
                          {
                            key: group.id,
                            attrs: {
                              label:
                                group.fullName +
                                "【" +
                                group.children.length +
                                "】",
                            },
                          },
                          _vm._l(group.children, function (item) {
                            return _c("el-option", {
                              key: item.__config__.jnpfKey,
                              attrs: {
                                label: item.__config__.label,
                                value: item.__config__.jnpfKey,
                              },
                            })
                          }),
                          1
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "required",
              label: "必填",
              width: "50",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: { checked: scope.row.required },
                      on: {
                        change: function ($event) {
                          scope.row.required
                            ? (scope.row.required = false)
                            : (scope.row.required = true)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "multiple",
              label: "多选",
              width: "50",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: { checked: scope.row.multiple },
                      on: {
                        change: function ($event) {
                          scope.row.multiple
                            ? (scope.row.multiple = false)
                            : (scope.row.multiple = true)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "SNOWS-table-delBtn",
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDel(scope.$index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "table-actions",
          on: {
            click: function ($event) {
              return _vm.addHandle()
            },
          },
        },
        [
          _c("el-button", { attrs: { type: "text", icon: "el-icon-plus" } }, [
            _vm._v("新增字段"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "SNOWS-common-title" }, [
      _c("h2", [_vm._v("字段设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }