var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title":
            this.$t("cip.index.atomIndex.composite") +
            this.$t("cip.index.atomIndex.drillDown") +
            `[${_vm.compositeParams["time"]}]`,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          page: _vm.page,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
        },
        on: {
          "update:tableData": function ($event) {
            _vm.tableData = $event
          },
          "update:table-data": function ($event) {
            _vm.tableData = $event
          },
          "tree-load": _vm.treeLoad,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }