<template>
  <el-container>
    <!-- <tree-layout ref="treeLayout" style="margin-right:5px;" :tree-options="treeOptions" @tree-node-click="treeNodeClick"></tree-layout> -->
    <CommonTree
        :treeData="treeData"
        :searchTitle="searchTitle"
        :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)"
        :isShowdig="false"
        :showCheckbox="false"
        :defaultProps="defaultProps"
        @getNodeClick="treeNodeClick"
    />
    <el-main>
      <grid-layout
        ref="gridLayout"
        :data-total="dataTotal"
        @page-size-change="onLoad"
        @page-current-change="onLoad"
        @page-refresh-change="onLoad"
        :tableOptions="tableOptions"
        :tableData.sync="tableDataComp"
        :tableLoading="tableLoading"
        :gridRowBtn="gridRowBtn"
        @row-check="rowCheck"
      >
        <template #customBtn="{row}">
          <el-button @click="rowCheck(row)" :disabled="row.$disabledBtn" size="small" type="text">
            {{ $t(`cip.cmn.btn.defBtn`) }}
          </el-button>
        </template>
      </grid-layout>
    </el-main>
  </el-container>
</template>

<script>
import TreeLayout from "@/views/components/layout/tree-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import { getDeptTree } from "@/api/system/dept";
import {selectUser} from "@/api/system/user";
import {mapGetters} from "vuex";
import CommonTree from "@/views/components/com_tree/index";

import {IS_ENABLE} from "@/util/dictConstant";

export default {
  name: "designPerson",
  components: {
    TreeLayout,
    GridLayout,
    CommonTree,
  },
  props: {
    memberTableData: {
      type: Array
    },
    headForm: {
      type: Object
    }
  },
  watch: {
    memberTableData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit("update:memberTableData", val)
      }
    }
  },
  data() {
    return {
      allMemberData: [],
      treeDeptId: 0,
      treeData: [],
      // treeOptions: {
      //   addBtn: false,
      //   defaultExpandAll: true,
      //   nodeKey: 'id',
      //   lazy: true,
      //   treeLoad: function (node, resolve) {
      //     const parentId = (node.level === 0) ? 0 : node.data.id;
      //     getDeptLazyTree(parentId).then(res => {
      //       resolve(res.data.data.map(item => {
      //         return {
      //           ...item,
      //           leaf: !item.hasChildren
      //         }
      //       }))
      //     });
      //   },
      //   menu: false,
      //   size: 'mini',
      //   props: {
      //     labelText: this.$t("cip.plat.sys.user.field.title"),
      //     label: 'title',
      //     value: 'value',
      //     children: 'children'
      //   },
      //   formOption: {
      //     labelWidth: 100,
      //     column: [{
      //       label: this.$t("cip.plat.sys.user.field.customize"),
      //       prop: 'test'
      //     }],
      //   },
      // },
      searchTitle: 'title',
      defaultProps: {
        label: "title",
        value: "key"
      },
      dataTotal: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableLoading: true,
      tableData: [],
      gridRowBtn: [],
      tableOptions: {
        menuWidth: 120,
        selection: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
          },
          {
            label: this.$t("cip.plat.sys.user.field.roleName"),
            prop: "roleName",
          },
          /*{
            label: this.$t("sinoma.system.user.phone"),
            prop: "phone",
            align: 'center',
          },*/
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      "userInfo"
    ]),
    tableDataComp() {
      let memberTableCodeData = this.memberTableData.map(e => e.userCode)
      let allMemberCodeData = this.allMemberData.map(e => e.userCode);
      return this.tableData
        .map(e => ({
          ...e,
          $disabledBtn: memberTableCodeData.includes(e.id),
        }))
    }
  },
  methods: {
    rowCheck(row) {
      /*if (!this.headForm.id) {
        return this.$message.warning(this.$t('cip.cmn.msg.warning.tipDetermineNoSaveHead'));
      }*/
      this.memberTableData.push({
        $cellEdit: true,
        userCode: row.id,
        userName: row.realName,
        deptName: row.deptName,
        roleName: row.roleName,
        account:row.account,
        email:row.email,
        phone:row.phone,
        isLeader: IS_ENABLE.N,
      })
    },
    getTreeData() {
      getDeptTree(this.userInfo.tenant_id).then((res) => {
        this.treeData = res.data.data
      })
    },
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      selectUser(page.currentPage, page.pageSize, params, this.treeDeptId).then(res => {
        const data = res.data.data;
        this.dataTotal = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
  },
  mounted() {
    this.onLoad(this.page, {});
    this.getTreeData();
  },
}
</script>

<style scoped>

</style>
