var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", { attrs: { "head-title": "附件下载" } }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row, index }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.rowDownload(row)
                              },
                            },
                          },
                          [_vm._v("\n            下载\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.bpmnVisible,
            "append-to-body": "",
            "destroy-on-close": "",
            title: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
            width: "70%",
            "custom-class": "wf-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bpmnVisible = $event
            },
          },
        },
        [
          _c("wf-design", {
            ref: "bpmn",
            staticStyle: { height: "60vh" },
            attrs: { options: _vm.bpmnOption },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(`cip.plat.wfOps.process.title.processProgressTitle`),
            visible: _vm.circulationVisible,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.circulationVisible = $event
            },
          },
        },
        [_c("wf-flow", { attrs: { flow: _vm.flow } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }