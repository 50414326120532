var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableDataComp, size: "medium" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "80",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelCode",
                  label: "通道编号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelName",
                  label: "通道名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelType",
                  label: "通道类型",
                  align: "center",
                  formatter: _vm.dictFormat,
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: scope.row.$disabledBtn,
                              size: "small",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.rowCheck(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }