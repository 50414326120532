var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "appId",
                    prop: "appId",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t("cip.plat.message.channel.msg.appId"),
                      "show-word-limit": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.appId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appId", $$v)
                      },
                      expression: "formData.appId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "secret",
                    prop: "secret",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.secret"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.secret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "secret", $$v)
                      },
                      expression: "formData.secret",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "templateId",
                    prop: "templateId",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.templateId"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.templateId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "templateId", $$v)
                      },
                      expression: "formData.templateId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }