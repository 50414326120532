<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.plat.monitor.log.error.title.indexHeadTitle')"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {getErrorLogs as getDetail} from "@/api/logs";


export default {
  name: "apiEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.monitor.log.error.field.serviceId'),
          prop: 'serviceId',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.serviceId`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.serverHost'),
          prop: 'serverHost',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.serverHost`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.serverIp'),
          prop: 'serverIp',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.serverIp`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.env'),
          prop: 'env',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.env`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.method'),
          prop: 'method',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.method`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.requestUri'),
          prop: 'requestUri',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.requestUri`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.createTime'),
          prop: 'createTime',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.createTime`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.userAgent'),
          prop: 'userAgent',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.userAgent`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.params'),
          prop: 'params',
          labelWidth: 130,
          span: 16,
          type: "textarea",
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.params`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.monitor.log.error.field.stackTrace'),
          prop: 'stackTrace',
          labelWidth: 130,
          type: "textarea",
          span: 16,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.monitor.log.error.field.stackTrace`),
          readonly: !['add', 'edit'].includes(this.type),
        },

      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
