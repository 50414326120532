<template>
  <basic-container>
    <head-layout :head-title="$t(`cip.plat.resource.oss.title.indexHeadTitle`)" :head-btn-options="headBtnOptions"
                 v-on="onHeadEvent"/>
    <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-on="onSearchEvent"/>
    <avue-crud :option="option" class="ossBox" :table-loading="loading" :data="data" :page.sync="page"
               :permission="permissionList" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
               @row-del="rowDel" :before-open="beforeOpen" @search-change="searchChange" @search-reset="searchReset"
               @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
               @refresh-change="refreshChange" @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger" size="small" plain v-if="permission.oss_delete" @click="handleDelete">
          {{ $t('cip.cmn.btn.delBtn') }}
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" v-if="userInfo.role_name.includes('administrator')"
                   @click="handleDebug(scope.row)">{{ $t('cip.plat.resource.oss.btn.debugBtn') }}
        </el-button>
        <el-button type="text" size="small" v-if="permission.oss_enable" @click.stop="handleEnable(scope.row)">
          {{ $t('cip.cmn.btn.enable') }}
        </el-button>
      </template>
      <!-- <template slot-scope="{row}" slot="status">
          <el-tag>{{row.statusName}}</el-tag>
      </template>
      <template slot-scope="{row}" slot="category">
          <el-tag>{{row.categoryName}}</el-tag>
      </template> -->
    </avue-crud>
    <common-dialog
      :dialogTitle="$t(`cip.plat.resource.oss.title.debugHeadTitle`)"
      v-if="box"
      width="550px"
      :show-btn="false"
      @cancel="box = false">
      <avue-form ref="form" :option="debugOption" v-model="debugForm" @submit="handleSubmit"/>
    </common-dialog>
  </basic-container>
</template>

<script>
import CommonDialog from "@/components/CommonDialog";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getList, getDetail, add, update, remove, enable} from "@/api/resource/oss";
import {mapGetters} from "vuex";
import func from "@/util/func";

export default {
  components: {
    CommonDialog,
  },
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      box: false,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      searchColumns: [
        {
          label: this.$t('cip.plat.resource.oss.field.classify'),
          prop: "category",
          type: "select",
          span: 4,
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.resource.oss.field.classify'),
          dicUrl: "/api/sinoma-system/dict/dictionary?code=oss",
          props: {
            label: "dictValue",
            value: "dictKey"
          }
        },
        {
          prop: "ossCode",
          search: true,
          span: 4,
          type: "input",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.resource.oss.field.ossCode'),
        },
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + " accessKey",
          prop: "accessKey",
          span: 4,
          type: "input",
          search: true
        }
      ],
      option: {
        height: 'auto',
        calcHeight: 30,
        tip: false,
        searchShow: false,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        menuWidth: 350,
        labelWidth: 100,
        dialogWidth: 880,
        dialogClickModal: false,
        header: false,
        column: [
          {
            label: this.$t('cip.plat.resource.oss.field.classify'),
            type: "select",
            value: 1,
            span: 24,
            width: 100,
            searchLabelWidth: 50,
            row: true,
            align: "center",
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict/dictionary?code=oss",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            slot: true,
            prop: "category",
            search: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.resource.oss.field.classify'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.oss.field.ossCode'),
            prop: "ossCode",
            span: 24,
            width: 120,
            align: "left",
            overHidden: true,
            search: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.oss.field.classify'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.oss.field.endpoint'),
            prop: "endpoint",
            span: 24,
            align: "left",
            overHidden: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.oss.field.endpoint'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.oss.field.bucketName'),
            prop: "bucketName",
            span: 24,
            width: 200,
            align: "left",
            overHidden: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.oss.field.bucketName'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.oss.field.accessKey'),
            prop: "accessKey",
            span: 24,
            search: true,
            width: 200,
            align: "left",
            overHidden: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.oss.field.accessKey'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.oss.field.secretKey'),
            prop: "secretKey",
            span: 24,
            width: 200,
            align: "left",
            overHidden: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.resource.oss.field.secretKey'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('cip.plat.resource.oss.field.appId'),
            prop: "appId",
            span: 24,
            hide: true,
            display: false,
            width: 200,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.resource.oss.field.region'),
            prop: "region",
            span: 24,
            hide: true,
            display: false,
            width: 200,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.resource.oss.field.status'),
            prop: "status",
            span: 24,
            width: 80,
            align: "center",
            slot: true,
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=enable_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
          },
          {
            label: this.$t('cip.plat.resource.oss.field.remark'),
            prop: "remark",
            span: 24,
            hide: true,
          },
        ]
      },
      data: [],
      debugForm: {
        code: '',
      },
      debugOption: {
        submitText: this.$t('cip.cmn.btn.submitBtn'),
        column: [
          {
            label: this.$t('cip.plat.resource.oss.field.ossCode'),
            prop: "code",
            disabled: true,
            span: 24,
          },
          {
            label: this.$t('cip.plat.resource.oss.field.backgroundUrl'),
            prop: "backgroundUrl",
            type: 'upload',
            listType: 'picture-img',
            dataType: 'string',
            action: '/api/sinoma-resource/oss/endpoint/put-file',
            propsHttp: {
              res: 'data',
              url: 'link',
            },
            span: 24,
          },
        ]
      }
    };
  },
  watch: {
    'form.category'() {
      const category = func.toInt(this.form.category);
      this.$refs.crud.option.column.filter(item => {
        if (item.prop === "appId") {
          item.display = category === 4;
        }
        if (item.prop === "region") {
          item.display = category === 4;
        }
      });
    },
    'debugForm.code'() {
      const column = this.findObject(this.debugOption.column, "backgroundUrl");
      column.action = `/api/sinoma-resource/oss/endpoint/put-file?code=${this.debugForm.code}`;
    }
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.oss_add),
        viewBtn: this.vaildData(this.permission.oss_view),
        delBtn: this.vaildData(this.permission.oss_delete),
        editBtn: this.vaildData(this.permission.oss_edit)
      };
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.oss_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.oss_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-romve",
          type: "button",
          icon: ""
        });
      }
      return result
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    onSearchEvent() {
      return {
        'grid-head-search': this.searchChange,
        'grid-head-empty': this.searchReset
      }
    },
    onHeadEvent() {
      return {
        'head-add': this.clickHeadAdd,
        'head-romve': this.handleDelete
      }
    }
  },
  mounted() {
    document.getElementsByClassName("avue-crud__menu").item(0).remove();
  },
  methods: {
    clickHeadAdd() {
      this.$refs.crud.rowAdd();
    },
    rowSave(row, done, loading) {
      this.$loading();
      add(row).then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t('cip.cmn.msg.success.operateSuccess'),
        });
        this.$loading().close();
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row, index, done, loading) {
      this.$loading();
      update(row).then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t('cip.cmn.msg.success.operateSuccess'),
        });
        this.$loading().close();
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowDel(row) {
      this.$confirm(this.$t(`cip.cmn.msg.warning.delWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    handleEnable(row) {
      this.$confirm(this.$t('cip.plat.resource.oss.msg.enable'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return enable(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleDebug(row) {
      this.box = true;
      this.debugForm.code = row.ossCode;
      this.debugForm.backgroundUrl = '';
    },
    handleSubmit(form, done) {
      this.$message({
        type: "success",
        message: this.$t('cip.plat.resource.oss.msg.img') + `[${form.backgroundUrl}]`
      });
      done();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data.data;
        });
      }
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-button + .el-button {
      margin-left: 10px;
}
::v-deep .ossBox .el-button span {
  margin: 0 !important;
}

::v-deep .ossBox .cell .el-button {
  width: 24px;
}

::v-deep .ossBox .cell .el-button i {
  display: none;
}
</style>
