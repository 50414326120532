var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t(`cip.desk.workflow.title.indexHeadTodo`),
                name: "first",
              },
            },
            [_vm.activeName == "first" ? _c("Todo") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t(`cip.desk.workflow.title.indexHead1Title`),
                name: "second",
              },
            },
            [_vm.activeName == "second" ? _c("Send") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.desk.workflow.title.indexHeadMyDone"),
                name: "third",
              },
            },
            [_vm.activeName == "third" ? _c("MyDone") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t(`cip.desk.workflow.title.indexHeadDone`),
                name: "fourth",
              },
            },
            [_vm.activeName == "fourth" ? _c("Done") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "待签事务", name: "fifth" } },
            [_vm.activeName == "fifth" ? _c("Claim") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t(`cip.desk.workflow.title.indexHeadCopy`),
                name: "sixth",
              },
            },
            [_vm.activeName == "sixth" ? _c("Copy") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }