var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("avue-tree", {
    staticStyle: {
      "border-right": "1px solid #cccccc",
      "background-color": "#ffffff",
      width: "280px",
    },
    attrs: { option: _vm.option, data: _vm.data },
    on: { "node-click": _vm.handleNodeClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }