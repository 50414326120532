var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "应急组织", name: "emergencyOrganization" } },
        [
          _c("emergencyOrganization", {
            ref: "emergencyOrganization",
            on: { uploadImgSuccess: _vm.uploadImgSuccess },
          }),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "应急物资", name: "emergencySupplies" } },
        [_c("emergencySupplies", { ref: "emergencySupplies" })],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "演练记录", name: "exerciseRecords" } },
        [_c("exerciseRecords", { ref: "exerciseRecords" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }