var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "appKey",
                    prop: "appKey",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.appKey"
                      ),
                      "show-word-limit": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.appKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appKey", $$v)
                      },
                      expression: "formData.appKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "appSecret",
                    prop: "appSecret",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.appSecret"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.appSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appSecret", $$v)
                      },
                      expression: "formData.appSecret",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "agentId",
                    prop: "agentId",
                  },
                },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.plat.message.channel.msg.agentId"
                      ),
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.agentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "agentId", $$v)
                      },
                      expression: "formData.agentId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }