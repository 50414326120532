<template>
  <!-- 应急物资 -->
  <div style="position: relative">
    <div>
      <head-layout
        head-title=""
        :showIcon="false"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-delete="handleDelete"
      ></head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="currentChange"
        @page-size-change="sizeChange"
      >
        <template #customBtn="{ row }" v-if="!this.pageDisabled">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            >移除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <!-- <suppliesDialog ref="suppliesDialog"></suppliesDialog> -->
    <suppliesDSelect ref="suppliesDSelect" @callback="callback"></suppliesDSelect>
    <el-dialog
      :visible.sync="editDialogVisible"
      title="应急物资编辑"
      :before-close="handleCancel"
    >
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="物资名称" prop="materialName">
          <el-input
            v-model="form.materialName"
            maxlength="100"
            placeholder="请输入物资名称"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="quantity">
          <el-input
            v-model="form.quantity"
            maxlength="100"
            placeholder="请输入数量"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="位置" prop="location">
          <el-input
            v-model="form.location"
            maxlength="100"
            placeholder="请输入位置"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
      
      <script>
import {
  erplanmaterialPage,
  erplanmaterialSubmit,
  erplanmaterialRemove,
} from "@/api/contingency/emergencyPlan";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import suppliesDialog from "@/views/business/contingency/emergencyPlan/components/suppliesDialog";
import suppliesDSelect from "@/views/business/contingency/emergencyPlan/components/suppliesDSelect";
export default {
  components: {
    GridLayout,
    HeadLayout,
    suppliesDialog,
    suppliesDSelect
  },
  data() {
    return {
      form: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableData: [],
      planId: "", // 计划id
      pageDisabled: false, // 默认不禁用  当查看页面点击进入或者提交后则禁用
      editDialogVisible: false,
      form: {
        materialName: "",
        quantity: "",
        location: "",
      },
      rules: {
        materialName: [
          {
            required: true,
            message: "请输入物资名称",
            trigger: "blur",
          },
        ],
        quantity: [
          {
            required: true,
            message: "请输入数量",
            trigger: "blur",
          },
        ],
        location: [
          {
            required: true,
            message: "请输入位置",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (!this.pageDisabled) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "移除",
          emit: "head-delete",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOption() {
      let obj = {};
      obj.index = true
      obj.column = [
        // {
        //   label: "序号",
        //   prop: "seqNum",
        //   align: "center",
        //   width: 200,
        // },
        {
          label: "物资名称",
          prop: "materialName",
          align: "center",
          overHidden:true
        },
        {
          label: "数量",
          prop: "quantity",
          align: "center",
        },
        {
          label: "位置",
          prop: "location",
          align: "center",
          overHidden:true
        },
      ];
      if (!this.pageDisabled) {
        obj.selection = true;
        obj.menu = true;
      } else {
        obj.selection = false;
        obj.menu = false;
      }
      return obj;
    },
  },
  mounted() {},
  methods: {
    // 初始化该组件
    init(id,pageDisabled) {
      console.log('aaa',pageDisabled)
      this.planId = id;
      this.pageDisabled = pageDisabled;
      this.onLoad(this.page, { planId: this.planId });
    },
    //   新增
    handleAdd() {
      // this.$refs.suppliesDialog.init("add", { planId: this.planId });
      this.$refs.suppliesDSelect.init(this.planId)
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    // 全局删除
    handleDelete() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erplanmaterialRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page, { planId: this.planId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 编辑
    rowEdit(row) {
      // this.$refs.suppliesDialog.init("edit", { id: row.id });
      this.form = row
      this.editDialogVisible = true;
    },
    // 单个删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erplanmaterialRemove(row.id);
        })
        .then(() => {
          this.onLoad(this.page, { planId: this.planId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 页码--第几页
    currentChange(val) {
      this.page.currentPage = val.currentPage;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 页码--多少页
    sizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      erplanmaterialPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {})
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    callback(){
      this.page.currentPage = 1
      this.onLoad(this.page, { planId: this.planId });
    },
    handleCancel() {
      this.$refs.form.resetFields();
      this.dialogLoading = false;
      this.editDialogVisible = false;
    },

    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          erplanmaterialSubmit(this.form).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.handleCancel();
              this.callback()
            }
          });
        }
      });
    },
  },
};
</script>
      
      <style>
</style>
      