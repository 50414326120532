var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            ref: "CommonTree",
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: _vm.$t(`cip.index.app.category.treeHeader`),
              isShowdig: true,
              showCheckbox: false,
              "node-key": "id",
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(`cip.index.app.category.listHeader`),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAdd,
                },
              }),
              _c("queryIndexManageWithCategory", {
                ref: "queryIndexManageWithCategory",
                attrs: {
                  dataFormExt: _vm.dataFormExt,
                  test: _vm.test,
                  "sys-category-id": _vm.sysCategoryId,
                },
                on: {
                  "update:dataFormExt": function ($event) {
                    _vm.dataFormExt = $event
                  },
                  "update:data-form-ext": function ($event) {
                    _vm.dataFormExt = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("addIndexManageWithCategoryWindow", {
        ref: "addIndexManageWithCategoryWindow",
        on: { saveExt: _vm.saveExt },
      }),
      _vm.showTreeDialog
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title: _vm.$t(`cip.index.app.category.treeHeader`),
                modal: false,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.showTreeDialog,
                width: "60%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showTreeDialog = $event
                },
              },
            },
            [
              _c("div", { staticClass: "positionabs" }, [
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: {
                    click: function ($event) {
                      return _vm.fullClick()
                    },
                  },
                }),
              ]),
              _c("avue-form", {
                ref: "formLayout",
                attrs: { option: _vm.treeDialogOption },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showTreeDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.celBtn`)))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.treeNodeSave },
                    },
                    [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.defBtn`)))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }