<template>
  <!-- 演练记录 -->
  <div style="position: relative">
    <div>
      <head-layout
        head-title=""
        :showIcon="false"
        :head-btn-options="headBtnOptions"
        @head-prepare-plan="headPreparePlan"
      ></head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="currentChange"
        @page-size-change="sizeChange"
      >
        <template #customBtn="{ row }" v-if="!this.pageDisabled">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            >移除
          </el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>
      
      <script>
import { erexercisePage } from "@/api/contingency/emergencyDrill";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  name: "exerciseRecordsList",
  data() {
    return {
      form: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableData: [],
      planId: "", // 计划id
      pageDisabled: false, // 默认不禁用  当查看页面点击进入或者提交后则禁用
      erStatus: "PREPARE", // 默认草稿
      endDate: "", // 限制截至日期
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      let result = false; // 默认没有过期
      if (this.endDate) {
        const oDate1 = new Date(this.endDate);
        const oDate2 = Date.now();
        if (oDate1.getTime() > oDate2) {
          result = false; //第一个大,意味着过期时间还没到
        } else {
          result = true; //第二个大 过期
        }
      }
      if (this.erStatus == "FINISHED" && !result) {
        buttonBtn.push({
          label: "编制计划",
          emit: "head-prepare-plan",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOption() {
      let obj = {};
      (obj.selection = false), (obj.linklabel = "exeCode");
      obj.column = [
        {
          label: "演练计划编号",
          prop: "exeCode",
          align: "center",
          overHidden:true
        },
        {
          label: "计划开始日期",
          prop: "startDate",
          align: "center",
        },
        {
          label: "计划结束日期",
          prop: "endDate",
          align: "center",
        },
        {
          label: "实际演练日期",
          prop: "exeDate",
          align: "center",
        },
      ];
      if (!this.pageDisabled) {
        obj.menu = true;
      } else {
        obj.menu = false;
      }
      return obj;
    },
  },
  mounted() {},
  methods: {
    // 初始化该组件
    init(id, pageDisabled, erStatus, orgImgUrl, endDate) {
      this.planId = id;
      this.pageDisabled = pageDisabled;
      this.erStatus = erStatus;
      this.endDate = endDate;
      this.onLoad(this.page, { planId: this.planId });
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/business/contingency/emergencyDrill/view`,
        query: {
          type: "view",
          id: row.id,
        },
      });
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    //   编制计划
    headPreparePlan() {
      this.$router.push({
        path: `/business/contingency/emergencyDrill/add`,
        query: {
          type: "add",
          id: this.planId,
          preparePlan: true,
        },
      });
    },
    // 页码--第几页
    currentChange(val) {
      this.page.currentPage = val.currentPage;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 页码--多少页
    sizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      params.searchStatus = ["APPROVING", "FINISHED"].join(",");
      erexercisePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {})
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
  },
};
</script>
      
      <style>
</style>
      