<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? ($t('cip.plat.message.template.title.editHeadTitle')) : (this.type == 'detail' ? ($t('cip.plat.message.template.title.viewHeadTitle')) : $t('cip.plat.message.template.title.addHeadTitle'))"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
      <el-tabs type="border-card"
               v-model="activeName" @tab-click="onloadparm">
        <el-tab-pane :label="$t('cip.plat.message.template.parameter.title.indexHeadTitle')" name="templateParam">
          <template-param ref="templateParam" :headForm.sync="dataForm" :type="type"></template-param>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.message.template.parties.title.indexHeadTitle')" name="templateParties">
          <template-parties ref="templateParties" :headForm.sync="dataForm" :type="type" :disabled="false">
          </template-parties>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.message.template.rule.title.indexHeadTitle')" name="templateRule">
          <template-rule ref="templateRule" :headForm.sync="dataForm" :type="type">
          </template-rule>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.message.template.channel.title.indexHeadTitle')" name="templateChannel">
          <template-channel ref="templateChannel" :headForm.sync="dataForm" :type="type">
          </template-channel>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from '@/util/ServerNameEnum';
import {saveOrUpdate, selectById} from "@/api/message/msgtemplate";

const CONTROLLER_NAME = "msgTypeItem";

let baseUrl = '/api/sinoma-system/dict/dictionary?code='
let bizUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";

import templateParam from "@/views/message/template/common/templateParam"
import templateParties from "@/views/message/template/common/templateParties"
import templateRule from "@/views/message/template/common/templateRule"
import templateChannel from "@/views/message/template/common/templateChannel"

export default {
  name: "itemEdit",
  components: {
    FormLayout,
    HeadLayout,
    templateParam,
    templateParties,
    templateRule,
    templateChannel,
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
      activeName: 'templateParam',
      isProductVisiable: false,
      nodeData: {},// index页分类信息
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t(`cip.cmn.btn.saveBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: this.$t(`cip.cmn.btn.saveBackBtn`),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
            btnOptType: 'saveBack',
          }
        );
      }
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let this_ = this;
      return [
        {
          label: this.$t(`cip.plat.message.template.field.templateCode`),
          labelWidth: 130,
          prop: "templateCode",
          readonly: !['add'].includes(this.type),
          span: 8,
          maxlength: 40,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.message.template.field.templateCode`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.templateCode`),
            trigger: "blur"
          },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t(`cip.plat.message.template.field.templateName`),
          labelWidth: 130,
          prop: "templateName",
          span: 8,
          readonly: !['edit', 'add'].includes(this.type),
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.message.template.field.templateName`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.templateName`),
            trigger: "blur"
          },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t(`cip.plat.message.template.field.status`),
          labelWidth: 130,
          prop: "status",
          type: 'radio',
          disabled: !['edit', 'add'].includes(this.type),
          readonly: !['edit', 'add'].includes(this.type),
          value: 1,
          // dicData: [
          //   {
          //     label: this.$t(`cip.plat.message.template.field.Enabled`),
          //     value: 1
          //   },
          //   {
          //     label: this.$t(`cip.plat.message.template.field.notEnabled`),
          //     value: 0
          //   }
          // ],
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=EnableOrNot",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.status`),
            trigger: "blur"
          }],
        },
        {
          label: this.$t(`cip.plat.message.template.field.templateType`),
          labelWidth: 130,
          clearable: true,
          dicUrl: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectTree`,
          prop: "msgTypeCode",
          type: 'tree',
          span: 8,
          disabled: !['edit', 'add'].includes(this.type),
          placeholder: this.$t(`cip.plat.message.template.field.templateType`),
          readonly: !['edit', 'add'].includes(this.type),
          props: {
            label: 'msgTypeName',
            value: 'msgTypeCode',
            key: 'msgTypeCode',
          },
          filterable: true,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.templateType`),
            trigger: "blur"
          }],
          nodeClick: (node) => {
            this.$refs.formLayout.dataForm.msgTypeCode = node.msgTypeCode;
            this.$refs.formLayout.dataForm.templateTypeName = node.msgTypeName;
          },
        },

        {
          label: this.$t(`cip.plat.message.template.field.templateMode`),
          labelWidth: 130,
          prop: "templateMode",
          type: 'radio',
          disabled: !['edit', 'add'].includes(this.type),
          value: "001",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_model`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.templateMode`),
            trigger: "blur"
          }],
          control: (val) => {
            if (val == "001") {
              this.dataForm.templateCronExpr = "";
              this.$refs.templateParties.dataForm.sendFromType = 1;
              this.$refs.templateParties.dataForm.receiveFromType = 1;
              this.$refs.templateParties.changeMould(false);
              return {
                templateCronExpr: {
                  display: false
                },
                jobCron: {
                  display: true,
                }
              }
            } else {
              this.dataForm.jobCron = ""
              this.$refs.templateParties.changeMould(true);
              return {
                templateCronExpr: {
                  display: true,
                },
                jobCron: {
                  display: false,
                }
              }
            }

          }
        },
        {
          label: this.$t(`cip.plat.message.template.field.listen`),
          labelWidth: 140,
          prop: "templateCronExpr",
          id: "templateCronExpr2",
          readonly: !['edit', 'add'].includes(this.type),
          placeholder: this.$t(`cip.plat.message.template.field.listen`),
          maxlength: 20,
          showWordLimit: true,
          display: false,
          span: 8,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.listen`),
            trigger: "blur"
          },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t(`cip.plat.message.template.field.cronExpressions`),
          prop: 'jobCron',
          labelWidth: 140,
          span: 8,
          formslot: true,
          disabled: !['edit', 'add'].includes(this.type),
          placeholder: this.$t(`cip.plat.message.template.field.cronExpressions`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.message.template.field.cronExpressions'),
              pattern: "^\\s*($|#|\\w+\\s*=|(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[01]?\\d|2[0-3])(?:(?:-|\\/|\\,)(?:[01]?\\d|2[0-3]))?(?:,(?:[01]?\\d|2[0-3])(?:(?:-|\\/|\\,)(?:[01]?\\d|2[0-3]))?)*)\\s+(\\?|\\*|(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|\\/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?(?:,(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|\\/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?)*)\\s+(\\?|\\*|(?:[1-9]|1[012])(?:(?:-|\\/|\\,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|\\/|\\,)(?:[1-9]|1[012]))?(?:L|W)?)*|\\?|\\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\\s+(\\?|\\*|(?:[0-6])(?:(?:-|\\/|\\,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|\\/|\\,|#)(?:[0-6]))?(?:L)?)*|\\?|\\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\\s)+(\\?|\\*|(?:|\\d{4})(?:(?:-|\\/|\\,)(?:|\\d{4}))?(?:,(?:|\\d{4})(?:(?:-|\\/|\\,)(?:|\\d{4}))?)*))$",
              trigger: "change"
            },
          ],
        },
        {
          label: this.$t(`cip.plat.message.template.field.TemplateDescription`),
          labelWidth: 130,
          prop: 'templateDescribe',
          type: 'textarea',
          placeholder: this.$t(`cip.plat.message.template.field.TemplateDescription`),
          cell: true,
          readonly: !['edit', 'add'].includes(this.type),
          overHidden: true,
          maxlength: 200,
          showWordLimit: true,
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.TemplateDescription`),
            trigger: "blur"
          }],
        },
        {
          label: this.$t(`cip.plat.message.template.field.appUrl`),
          labelWidth: 130,
          prop: 'appUrl',
          type: 'textarea',
          placeholder: this.$t(`cip.plat.message.template.field.appUrl`),
          cell: true,
          readonly: !['edit', 'add'].includes(this.type),
          overHidden: true,
          maxlength: 200,
          showWordLimit: true,
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.appUrl`),
            trigger: "blur"
          }],
        },

        {
          label: this.$t(`cip.plat.message.template.field.pcUrl`),
          labelWidth: 130,
          prop: 'pcUrl',
          type: 'textarea',
          placeholder: this.$t(`cip.plat.message.template.field.pcUrl`),
          cell: true,
          readonly: !['edit', 'add'].includes(this.type),
          overHidden: true,
          maxlength: 200,
          showWordLimit: true,
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.message.template.field.pcUrl`),
            trigger: "blur"
          }],
        },
      ]
    },
  },
  watch: {
    isProductVisiable(val) {
      if (!this.$refs.formLayout) {
        return
      }
      let processCode = this.findObject(this.$refs.formLayout.option.column, "processCode")
      let processName = this.findObject(this.$refs.formLayout.option.column, "processName")
      if (processCode && processName) {
        if (val) {
          processCode.rules.splice(0, processCode.rules.length)
          processCode.rules.push({
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.md.item.field.processCode'),
            trigger: "blur"
          })
          processName.rules.splice(0, processName.rules.length)
          processName.rules.push({
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.md.item.field.processName'),
            trigger: "blur"
          })
        } else {
          processName.rules.splice(0, processName.rules.length)
          processCode.rules.splice(0, processCode.rules.length)
          this.$refs.formLayout.$refs.form.clearValidate(["processCode", "processName"])
        }
      }
    }
  },
  created() {
    let {data, pageType, node} = this.$route.query;
    this.nodeData = node;

    this.type = pageType;
    if (['detail', 'edit'].includes(this.type)) {
      this.dataForm.id = data.id
      this.initData()
    } else {
      // 新增
      this.dataForm.msgTypeCode = this.nodeData.msgTypeCode;
      this.dataForm.msgTypeName = this.nodeData.msgTypeName;
    }
  },
  mounted() {
    // if(['detail'].includes(this.type)){
    //   this.$refs.formLayout.$refs.form.allDisabled = true;
    // }
  },
  methods: {
    initData() {
      selectById(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;//
        if (data.templateMode == "001") {
          this.dataForm.jobCron = data.templateCronExpr;
        }
        this.dataForm.msgTypeCode = data.templateType;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = this.$refs.formLayout.dataForm;
          if (data.templateMode == "001") {
            data.templateCronExpr = data.jobCron;
          }
          saveOrUpdate({
            ...data,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
                this.$refs.templateParam.onLoad({pageSize: 10, currentPage: 1});

              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    onloadparm() {
      if (this.activeName == 'templateParam') {
        this.$refs.templateParam.searchReset();
      } else if (this.activeName == "templateParties") {
        this.$refs.templateParties.initData();
        this.$refs.templateParties.onloadparms();
      } else if (this.activeName == 'templateChannel') {
        this.$refs.templateChannel.gridHeadEmpty();
      }
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
