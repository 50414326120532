<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.index.app.category.indexCode`)"
          @head-add-tabs="headAdd"
          @head-remove="headRemove"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      :grid-row-btn="gridRowBtn"
      @gird-handle-select-click="selectionChange"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-remove="rowRemove"
    >
    </grid-layout>
    <AppIndexWindow ref="appIndexWindow" @confirmExt="getSelectionIndexList"></AppIndexWindow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import AppIndexWindow from "@/views/business/index/appIndex/common/AppIndexWindow";
import {selectLinePage} from "@/api/index/appIndexManage";

export default {
  name: "indexCode",
  props: {
    type: {
      default: undefined
    },
    headForm: {
      default: {}
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return []
    },
    tableOptions() {
      return {
        linklabel: "indexCode",
        index: true,
        indexLabel: '序号',
        align: 'center',
        labelWidth: 120,
        selection: true,
        column: [
          {
            label: this.$t('cip.index.atomIndex.indexCode'),
            prop: "indexCode",
            width: 450
          },
          {
            label: this.$t('cip.index.atomIndex.indexName'),
            prop: "indexName",
          },
          {
            label: this.$t('cip.index.atomIndex.confType'),
            prop: "confType",
            align: 'center',
            width: 100,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_conf_type",
          },
          //指标单位
          {
            // label: this.$t('cip.index.atomIndex.indexType'),
            label: this.$t('cip.index.atomIndex.unitCode'),
            prop: "unitCode",
            align: 'center',
            width: 100,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_unit",
          },
          //时间维度
          {
            // label: this.$t('cip.index.atomIndex.indexType'),
            label: this.$t('cip.index.omission.timeDimension'),
            prop: "timeDimension",
            align: 'center',
            width: 140,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          },
          //指标级别
          {
            // label: this.$t('cip.index.atomIndex.indexType'),
            label: this.$t('cip.index.omission.indexLevel'),
            prop: "indexLevel",
            align: 'center',
            width: 120,
            value: 'y',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_level",
          },
        ]
      }
    },
    gridHeadBtn() {
      return []
    },
    gridRowBtn() {
      if (['add', 'edit'].includes(this.type)) {
        return [
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "row-remove",
            type: "button",
            icon: ""
          }
        ]
      } else {
        return [];
      }

    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result;
    }
  },
  components: {
    AppIndexWindow,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      selectionList: [],
      query: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  methods: {
    // 获得index指标,判断重复数据
    getSelectionIndexList(selectionList) {
      const _this = this;
      let selectionListCopy = JSON.parse(JSON.stringify(selectionList));
      selectionListCopy.forEach(row => {
        let flag = _this.tableData.some(bean => row.indexCode === bean.indexCode);
        if (!flag) {
          row.id = null;
          this.tableData.push(row);
        }
      });
      this.page.total = this.tableData.length;
    },
    headAdd() {
      this.$refs.appIndexWindow.openDialog({
        timeDimension: this.headForm.timeDimension,
        // 不包含复合指标
        // notContainConfType: "COMPOSITE_INDEX",
        // 缓存的指标
        isCache: 1,
        // 禁止选择当前指标
        notContainId: this.headForm.id,
      })
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          this.tableData = this.tableData.filter(x => selectionList.every(y => y.indexCode !== x.indexCode));
        }).catch(() => {
      })
    },
    rowRemove(row, index) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          this.tableData.splice(index, 1)
        })
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.tableLoading = false;
      this.query.headerId = this.headForm.id;
      selectLinePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
          this.selectionClear();
        })
        .catch(() => {
          this.tableLoading = true;
        });
    },
    getSaveInfo() {
      return this.tableData;
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  }
};
</script>
<style>
</style>
