<template>
  <div>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import {selectTheme,getDimensionValue} from "@/api/index/indexManage";
import {findNodeByDic} from "@/util/util";
export default {
  name: "formItem",
  components: {
    FormLayout
  },
  data(){
    return {
    }
  },
  props:['dataForm','index','themeId'],
  watch:{
    'dataForm':{
      deep:true,
      immediate:true,
      handler(val) {
        this.$emit('itemForm',{index:this.index,val:val})
        this.dataForm = val
      }
    }
  },
  computed:{
    formColumn() {
      let this_ = this;
      return [
        {
          label: this.$t('cip.index.scope.connectedRelation'),
          prop: 'connectedRelation',
          type: 'select',
          span: 6,
          filterable: true,
          display: false,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=connected_relation",
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.scope.connectedRelation'),
              trigger: 'change'
            }
          ]
        },
        {
          label: this.$t('cip.index.scope.dimension'),
          labelWidth: 130,
          prop: "dimension",
          type: 'select',
          filterable: true,
          span: 6,
          props: {
            label: "fieldName",
            value: "fieldName"
          },
          // dicData: '',
          placeholder: this.$t(`cip.index.scope.dimension`),
          // change: (data) => {
          //   let {value} = data;
          //   let dicData = this.$refs.formLayout.$refs.form.DIC['dimension'];
          //   let node = findNodeByDic(dicData, value, 'fieldName');
          //   if (node) {
          //     this.$refs.formLayout.dataForm.tableName=node.tableName;
          //   } else {
          //     this.$refs.formLayout.dataForm.tableName=''
          //   }
          // },
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.scope.dimension'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.index.scope.computeSign'),
          labelWidth: 130,
          prop: "computeSign",
          placeholder: this.$t(`cip.index.scope.computeSign`),
          maxlength: 20,
          showWordLimit: true,
          filterable: true,
          type: 'select',
          span: 6,
          filterable: true,
          display: false,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=compute_sign",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.scope.computeSign'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.index.scope.value'),
          labelWidth: 130,
          prop: "value",
          placeholder: this.$t(`cip.index.scope.value`),
          filterable: true,
          maxlength: 20,
          showWordLimit: true,
          type: 'select',
          dicData: '',
          props: {
            label: "dimension",
            value: "dimension"
          },
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.scope.value'),
            trigger: "blur"
          }],
          span: 6
        },
      ]
    },
  },
  created() {

  },
  mounted() {

    this.initCuboidIdData()
  },
  methods: {
    initCuboidIdData(params = {}) {
      // if (this.themeId!==''){
      //   params.themeId = this.themeId;
      //   selectTheme(params).then(res => {
      //     const {data} = res.data;
      //     this.$refs.formLayout.column[0].dicData = data;
      //   })
      // }
      if(this.dataForm.tableName!=''&&this.dataForm.dimension!='') {
        params.tableName = this.dataForm.tableName;
        params.fieldName = this.dataForm.dimension + ' as dimension';
        getDimensionValue(params).then(res => {
          const {data} = res.data;
          this.$refs.formLayout.column[3].dicData = data;
        })
      }
      if(this.dataForm.disabled){
       this.$refs.formLayout.column.map(item=>{
        item.disabled=true
       })
      }



    },
  }
}
</script>

<style scoped>

</style>
