export default {
  data() {
    return {
      customFields: [{
        title: '业务字段',
        list: [{
          label: '人员选择',
          component: 'wf-user-select',
          span: 24,
          params: {
            checkType: 'checkbox'
          },
          icon: 'icon-oa',
          display: true
        }]
      }, {
        title: '通用配置字段',
        list: [{
          label: '上传',
          type: 'upload',
          propsHttp: {
            res: 'data',
            url: 'link',
            name: 'originalName'
          },
          action: '/api/sinoma-resource/oss/endpoint/put-file',
          display: true,
          span: 24,
          icon: 'icon-upload',
          showFileList: true,
          multiple: true,
          limit: 10,
          headersConfig: [],
          dataConfig: []
        }, {
          type: 'select',
          label: '下拉选择器',
          icon: 'icon-select',
          dicData: [],
          dicUrl: '/api/sinoma-system/dict/dictionary?code=',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicOption: 'remote',
          span: 24,
          display: true,
        }, {
          type: 'ueditor',
          component: 'avue-ueditor',
          label: '富文本',
          icon: 'icon-richtext',
          span: 24,
          display: true,
          options: {
            action: '/api/sinoma-resource/oss/endpoint/put-file',
            oss: '',
            props: {
              res: 'data',
              url: 'link',
            },
            ali: {},
            qiniu: {}
          },
        }]
      }]
    }
  }
}
