var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emergencyPlan",
      staticStyle: { "background-color": "#ffffff" },
    },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": _vm.headSave,
          "head-submit": _vm.headSubmit,
          "head-import": _vm.headImport,
          "head-export": _vm.headExport,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: {
                option: _vm.formOptions,
                "upload-preview": _vm.handlePreview,
                "upload-after": _vm.uploadAfter,
                "upload-error": _vm.uploadError,
                "upload-exceed": _vm.uploadExceed,
                "upload-before": _vm.uploadBefore,
              },
              model: {
                value: _vm.addForm,
                callback: function ($$v) {
                  _vm.addForm = $$v
                },
                expression: "addForm",
              },
            },
            [
              _c(
                "template",
                { slot: "planCode" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请输入预案编号",
                        disabled:
                          _vm.addForm.isAutomatic ||
                          _vm.pageDisabled ||
                          _vm.addForm.id,
                      },
                      model: {
                        value: _vm.addForm.planCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "planCode", $$v)
                        },
                        expression: "addForm.planCode",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "append" }, slot: "append" },
                        [
                          _vm._v("\n            自动生成\n            "),
                          _c("el-switch", {
                            attrs: {
                              disabled: _vm.pageDisabled || _vm.addForm.id,
                              "active-color": "#13ce66",
                            },
                            on: { change: _vm.handleSwitch },
                            model: {
                              value: _vm.addForm.isAutomatic,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "isAutomatic", $$v)
                              },
                              expression: "addForm.isAutomatic",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("tabsPane", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.addForm.id,
                expression: "addForm.id",
              },
            ],
            ref: "tabsPane",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }