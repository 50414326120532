<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t(`cip.plat.message.sendRecord.title.viewHeadTitle`)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
      <el-tabs type="border-card"
               v-model="activeName"
               @tab-click="handleClick"
      >
        <el-tab-pane :label="$t('cip.plat.message.template.parameter.title.indexHeadTitle')" name="templateParam">
          <template-param ref="templateParam" :headForm.sync="headForm" :type="type"></template-param>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.message.template.parties.title.indexHeadTitle')" name="templateParties">
          <template-parties ref="templateParties" :headForm.sync="headForm" :type="type" :disabled="true">
          </template-parties>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.message.template.rule.title.indexHeadTitle')" name="templateRule" >
          <template-rule ref="templateRule" :headForm.sync="headForm" :type="type">
          </template-rule>
        </el-tab-pane>
<!--        <el-tab-pane :label="$t('cip.plat.message.template.channel.title.indexHeadTitle')" name="templateChannel" >
          <template-channel ref="templateChannel" :headForm.sync="headForm" :type="type">
          </template-channel>
        </el-tab-pane>-->
        <el-tab-pane :label="$t('cip.plat.message.sendRecord.sendRecord.title.indexHeadTitle')" name="messageDetai">
          <message-detai ref="messageDetai" :headForm.sync="dataForm" :type="type">
          </message-detai>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from '@/util/ServerNameEnum';
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import templateParam from "@/views/message/template/common/templateParam";
import templateParties from "@/views/message/template/common/templateParties";
import templateRule from "@/views/message/template/common/templateRule";
//import templateChannel from "@/views/message/template/common/templateChannel";
import messageDetai from "@/views/message/sendInfo/common/messageDetai";
import {getSelectById} from "@/api/message/sendInfo";


export default {
  name: "msgSendInfoView",
  components: {
    FormLayout,
    HeadLayout,
    templateParam,
    templateParties,
    templateRule,
    //templateChannel,
    messageDetai
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
      headForm: {},
      activeName: 'messageDetai',
      nodeData: {},// index页分类信息
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let this_ = this;
      return [
        {
          label: this.$t('cip.plat.message.sendRecord.field.msgCode'),
          labelWidth: 130,
          prop: "msgCode",
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.msgCode`),
          span: 8
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.sendStatus'),
          labelWidth: 130,
          prop: "sendStatus",
          span: 8,
          type: 'select',
          readonly: true,
          disabled: true,
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_status`,
          props: {
            label: "dictValue",
            value: "dictKey"
          }
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.msgType'),
          labelWidth: 130,
          prop: "msgType",
          type: 'tree',
          dataType: 'string',
          span: 8,
          disabled: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.msgType`),
          props: {
            label: 'msgTypeName',
            value: 'msgTypeCode'
          },
          dicUrl: `${ServerNameEnum.SERVER_MESSAGE_}/msgTypeItem/selectTree`
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.templateName'),
          labelWidth: 130,
          prop: "templateName",
          span: 8,
          readonly: true,
          maxlength: 20,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.templateName`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.sendModel'),
          prop: 'sendModel',
          labelWidth: 130,
          span: 8,
          type: 'radio',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_model`,
          disabled: true,
          control: (val) => {
            if (val == "001") {
              this_.$refs.templateParties.dataForm.sendFromType = 1;
              this_.$refs.templateParties.dataForm.receiveFromType = 1;
              this.$refs.templateParties.changeMould(false);
            } else {
              this.$refs.templateParties.changeMould(true);
            }
          }
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.templateCronExpr'),
          labelWidth: 130,
          prop: "templateCronExpr",
          span: 8,
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.templateCronExpr`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.sendNum'),
          labelWidth: 130,
          prop: "sendNum",
          span: 8,
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.sendNum`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.successMsgNum'),
          labelWidth: 130,
          prop: "successMsgNum",
          span: 8,
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.successMsgNum`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.failMsgNum'),
          labelWidth: 130,
          prop: "failMsgNum",
          span: 8,
          readonly: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.failMsgNum`)
        }
      ]
    },
  },
  watch: {

  },
  created() {
    let {id, pageType,node, templateId} = this.$route.query;
    this.nodeData = node;
    this.dataForm.id = id
    this.headForm.id = templateId;
    this.initData()
  },
  mounted() {
  },
  methods: {
    initData() {
      getSelectById(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        this.headForm = data;
        this.headForm.id = data.templateId;
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleClick() {
      if (this.activeName == 'templateParam') {
        this.$refs.templateParam.searchReset();
      } else if (this.activeName == "templateParties") {
        this.$refs.templateParties.initData();
        this.$refs.templateParties.onloadparms();
      } else if (this.activeName == 'templateChannel') {
        this.$refs.templateChannel.gridHeadEmpty();
      } else if (this.activeName == 'messageDetai') {
        this.$refs.messageDetai.gridHeadEmpty();
      }
    }
  }
}
</script>

<style scoped>

</style>
