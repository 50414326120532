<template>
  <el-dialog
    :close-on-click-modal="false"
    v-dialog-drag
    :title="title"
    :visible.sync="visible"
    class="avue-dialog avue-dialog--top"
    :width="width"
    :append-to-body="true"
  >
    <el-container>
<!--      <tree-layout-->
<!--        ref="treeLayout"-->
<!--        :tree-options="treeOptions"-->
<!--        :treeData="treeData"-->
<!--        @tree-node-click="treeNodeClick"-->
<!--        style="height: 620px;overflow: auto"-->
<!--      ></tree-layout>-->
      <el-main>
        <grid-head-layout
          ref="searchForm"
          :search-columns="searchColumns"
          @grid-head-search="searchChange"
          @grid-head-empty="searchReset"
        ></grid-head-layout>
        <grid-layout
          v-if="visible"
          ref="gridLayout"
          class="treeTable"
          :data-total="dataTotal"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          @page-size-change="onLoad"
          @page-current-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="girdHandleSelectClick"
        >
          <template
            #customBtn="{row}">
            <el-button
              :disabled="disabledList.includes(row[disabledCode])"
              type="text"
              size="small"
              @click="rowSelect(row)">
              {{ $t('cip.cmn.btn.defBtn') }}
            </el-button>
          </template>
        </grid-layout>
      </el-main>
      <el-card v-if="echo" class="box-card" style="width: 200px">
        <div slot="header" class="clearfix">
          <span>{{ $t("cip.business.components.common.treeTableDialog.msg.selected") }}</span>
        </div>
        <div
          v-for="sel in selectionList"
          :key="sel[rowKey]">
          <el-tag
            closable
            @close="closeSel(sel)"
            style="margin: 5px"
          >{{ sel[rowName] }}
          </el-tag>
        </div>
      </el-card>
    </el-container>
    <div v-if="!singleSelection" class="avue-dialog__footer">
      <el-button :loading="tableLoading" size="small" @click="close">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
      <el-button :loading="tableLoading" size="small" @click="submit" type="primary">
        {{ $t('cip.cmn.btn.defBtn') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import GridLayout from "@/views/components/layout/grid-layout";
import TreeLayout from "@/views/components/layout/tree-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  name: "mdItemDialog",
  components: {
    GridLayout,
    TreeLayout,
  },
  props: {
    width: {
      default: '60%',
      type: String
    },
    echo: {
      default: false,
      type: Boolean,
    },
    echoList: {
      default: [],
      type: Array,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      default: 'dialog',
      type: Boolean,
    },
    singleSelection: {
      default: false,
      type: Boolean,
    },
    reserveSelection: {
      default: false,
      type: Boolean,
    },
    selectPage: {
      type: Function,
      default: () => {
        this.$message.error("ERROR")
      }
    },
    tree: {
      type: Function,
      default: () => {
        this.$message.error("ERROR")
      }
    },
    rowKey: {
      default: 'id',
      type: String,
    },
    rowName: {
      default: 'name',
      type: String,
    },
    column: {
      default: [],
      type: Array
    },
    searchColumns: {
      default: [],
      type: Array
    },
    treeKey: {
      default: 'id',
      type: String,
    },
    treeKeyToList: {
      default: undefined,
      type: String,
    },
    treeProps: {
      default: () => ({}),
      type: Object,
    },
    treeQuery: {
      default: () => ({parentId: 0}),
      type: Object,
    },
    selectable: {
      type: Function,
      default: () => {
        return true
      },
    },
    disabledList: {
      type: Array,
      default: () => ([])
    },
    disabledCode: {
      type: String,
      default: 'id'
    },
    queryData: {
      type: Function,
      default: null
    },
    queryClose: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      selectionList: [],
      query: {},
      treeCode: undefined,
      treeData: [],
      dataTotal: 0,
      tableLoading: false,
      tableOptions: {
        selection: !this.singleSelection,
        menu: this.singleSelection,
        rowKey: this.rowKey,
        reserveSelection: this.reserveSelection,
        height: 300,
        selectable: this.selectable,
        column: this.column
      },
      tableData: [],
      page: {
        pageSize: 20,
        currentPage: 1,
      },
    }
  },
  computed: {
    relevanceKey() {
      if (this.treeKeyToList) {
        return this.treeKeyToList
      }
      return this.treeKey
    },
    treeOptions() {
      return {
        defaultExpandAll: true,
        addBtn: false,
        nodeKey: this.treeKey,
        size: 'small',
        menu: false,
        lazy: false,
        props: this.treeProps,
      };
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.$emit("update:visible", val)
      }
    },
    echoList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit("echoList:visible", val)
      }
    },
  },
  methods: {
    // 从右边的tag取消选中
    closeSel(row) {
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t(`cip.cmn.btn.defBtn`),
        cancelButtonText: this.$t(`cip.cmn.btn.celBtn`),
        type: "warning"
      }).then(() => {
        // 使用toggleRowSelection取消勾选会有bug，多次跳页后无法取消勾选
        let sel = this.selectionList.filter(e => e[this.rowKey] !== row[this.rowKey]);
        this.$refs.gridLayout.selectionClear()
        this.$refs.gridLayout.getGrid().toggleSelection(sel)
      }).catch(() => {
      })
    },
    girdHandleSelectClick(selectionList) {
      this.selectionList = selectionList;
    },
    initTree() {
      this.tree(this.treeQuery).then(res => {
        const {data} = res.data;
        this.treeData = data;
      });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.$refs.searchForm.searchForm.itemCatCode = node[this.relevanceKey];
      this.treeCode = node[this.relevanceKey];
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1},{netCode:node.netCode,deviceCode:node.deviceCode});
    },
    searchReset(params) {
      this.$refs.searchForm.searchForm.itemCatCode = '';
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    searchChange(params) {
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      // params[this.relevanceKey] = this.treeCode
      let searchForm = {}
      if (this.$refs.searchForm) {
        searchForm = this.$refs.searchForm.searchForm;
      }
      let param = Object.assign(this.query, params, searchForm)
      param.extItemCatCode = param.itemCatCode
      this.selectPage(
        page.currentPage,
        page.pageSize,
        param
      ).then(async res => {
        const data = res.data.data;
        let total = this.dataTotal;
        this.dataTotal = data.total;
        this.$refs.gridLayout.page.total = data.total;
        if (this.echo) {
          // 如果是total的话，已选中的数据就会失效,所以在下一次total不为0的时候需要重新选中
          let selectionList = this.$refs.gridLayout.selectionList;
          await this.$nextTick()
          if (total === 0 && selectionList.length > 0) {
            selectionList.forEach(e => this.$refs.gridLayout.getGrid().toggleRowSelection(e, true))
          }
        }
        this.tableData = data.records
        this.tableLoading = false;
      });
    },
    async openDialog(query = {}) {
      this.visible = true;
      this.query = query;
      this.treeCode = undefined
      // 是否有搜索条件
      if (this.$refs.searchForm) {
        this.$refs.searchForm.searchForm = {};
      }
      await this.$nextTick()
      //是否回显
      if (this.echo) {
        this.echoList.forEach(e => {
          this.$refs.gridLayout.getGrid().toggleRowSelection(e, true)
        })
      }
      this.initTree()
      this.onLoad(this.page)
    },
    rowSelect(row) {
      this.tableLoading = true
      this.$emit('select-data', row, (visible = true) => {
        this.tableLoading = false
        this.visible = !visible
      });
    },
    submit() {
      this.tableLoading = true
      let selection = this.$refs.gridLayout.selectionList;
      this.$emit('select-data', selection, (visible = true) => {
        this.tableLoading = false
        this.visible = !visible
      });
    },
    close() {
      this.$emit('close-dialog', () => {
        this.visible = false
      })
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .avue-crud .el-table {
  height: calc(100vh - 420px) !important;
  max-height: calc(100vh - 420px) !important;
}
</style>
