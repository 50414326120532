var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c("div", { staticClass: "SNOWS-preview-main" }, [
      _vm.showTitle
        ? _c(
            "div",
            { staticClass: "SNOWS-common-page-header" },
            [
              _c("el-page-header", {
                attrs: { content: "新建流程" },
                on: { back: _vm.goBack },
              }),
              _c(
                "div",
                { staticClass: "options" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancelButton")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "flow-tabs",
              staticStyle: { height: "100%" },
              attrs: { "tab-position": "left" },
              model: {
                value: _vm.category,
                callback: function ($$v) {
                  _vm.category = $$v
                },
                expression: "category",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部流程", name: "" } }),
              _vm._l(_vm.categoryList, function (item) {
                return _c("el-tab-pane", {
                  key: item.id,
                  attrs: { label: item.fullName, name: item.id },
                })
              }),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "SNOWS-common-search-box",
                      attrs: { gutter: 16 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "关键词" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入关键词查询",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.search()
                                      },
                                    },
                                    model: {
                                      value: _vm.keyword,
                                      callback: function ($$v) {
                                        _vm.keyword = $$v
                                      },
                                      expression: "keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.search()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("common.search"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { icon: "el-icon-refresh-right" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.reset")) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value:
                            _vm.listLoading && _vm.listQuery.currentPage == 1,
                          expression: "listLoading && listQuery.currentPage==1",
                        },
                      ],
                      ref: "infiniteBody",
                      staticClass: "list",
                    },
                    [
                      _vm.list.length
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            _vm._l(_vm.list, function (item, i) {
                              return _c(
                                "el-col",
                                {
                                  key: i,
                                  staticClass: "item",
                                  attrs: { span: 6 },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.jump(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-card",
                                    { attrs: { shadow: "hover" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "box-icon",
                                          style: {
                                            backgroundColor:
                                              item.iconBackground || "#008cff",
                                          },
                                        },
                                        [_c("i", { class: item.icon })]
                                      ),
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v(_vm._s(item.fullName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c("el-empty", {
                            attrs: {
                              description: "暂无数据",
                              "image-size": 120,
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }