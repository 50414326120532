var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-container",
        [
          _c("wf-category", {
            on: {
              "node-click": _vm.nodeClick,
              "list-change": function ($event) {
                _vm.findObject(_vm.option.column, "categoryId").dicData = $event
              },
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    "cip.plat.wfDesign.model.title.indexHeadTitle"
                  ),
                },
                on: {
                  "head-add": function ($event) {
                    return _vm.handleDesign({})
                  },
                  "head-category": _vm.handleChangeCategory,
                  "head-message": _vm.handleChangeMessage,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.option,
                  "table-data": _vm.data,
                  "table-loading": _vm.loading,
                  "data-total": _vm.page.total,
                  "grid-row-btn": _vm.gridRowBtn,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-sj": _vm.rowSj,
                  "row-bs": _vm.rowBs,
                  "row-qx": _vm.rowQx,
                  commandClick: _vm.commandClick,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.categoryVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                dialogTitle: _vm.$t(
                  "cip.plat.wfDesign.model.title.arrangeHeadTitle"
                ),
                width: "30%",
              },
              on: {
                cancel: function ($event) {
                  _vm.categoryVisible = false
                },
                confirm: _vm.deploySubmit,
              },
            },
            [
              _c("avue-form", {
                ref: "deploySubmit",
                attrs: {
                  option: {
                    menuBtn: false,
                    column: [
                      {
                        type: "tree",
                        label: this.$t(
                          `cip.plat.wfDesign.model.field.category`
                        ),
                        span: 24,
                        props: { label: "name", value: "id" },
                        prop: "category",
                        dicUrl: "/api/sinoma-workflow/design/category/tree",
                        required: true,
                        rules: [
                          {
                            required: true,
                            message:
                              this.$t(`cip.cmn.rule.selectWarning`) +
                              this.$t(`cip.plat.wfDesign.model.field.category`),
                          },
                        ],
                      },
                    ],
                  },
                },
                on: { submit: _vm.handleDeploySubmit },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.iconVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                dialogTitle: _vm.$t(
                  "cip.plat.wfDesign.model.title.uploadIconHeadTitle"
                ),
                width: "30%",
              },
              on: {
                cancel: function ($event) {
                  _vm.iconVisible = false
                },
                confirm: _vm.iconSubmit,
              },
            },
            [
              _c("avue-form", {
                ref: "iconSubmit",
                attrs: {
                  option: {
                    menuBtn: false,
                    column: [
                      {
                        label: this.$t(`cip.plat.wfDesign.model.field.icon`),
                        type: "upload",
                        propsHttp: {
                          res: "data",
                          url: "link",
                          name: "originalName",
                        },
                        tip: this.$t(
                          `cip.plat.wfDesign.model.msg.suggestedSize`
                        ),
                        action: "/api/sinoma-resource/oss/endpoint/put-file",
                        display: true,
                        showFileList: true,
                        prop: "icon",
                        listType: "picture-img",
                        required: true,
                        rules: [
                          {
                            required: true,
                            message: this.$t(
                              `cip.plat.wfDesign.model.msg.requireIcon`
                            ),
                          },
                        ],
                        accept: "image/*",
                      },
                    ],
                  },
                },
                on: { submit: _vm.handleIconSubmit },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.messageVisible
        ? _c(
            "CommonDialog",
            {
              attrs: { dialogTitle: "选择消息模板", width: "30%" },
              on: {
                cancel: function ($event) {
                  _vm.messageVisible = false
                },
                confirm: _vm.messageSubmit,
              },
            },
            [
              _vm.messageVisible
                ? _c("avue-form", {
                    ref: "messageSubmit",
                    attrs: {
                      option: {
                        menuBtn: false,
                        column: [
                          {
                            type: "tree",
                            label: "消息模板",
                            span: 24,
                            props: {
                              label: "templateName",
                              value: "templateCode",
                            },
                            prop: "messageTemplateCode",
                            dicUrl:
                              "/api/sinoma-message/msgTemplate/selectPage?current=1&size=99999",
                            dicFormatter: (res) => {
                              return res.data.records
                            },
                            required: false,
                          },
                        ],
                      },
                    },
                    on: { submit: _vm.handleMessageSubmit },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("user-option", {
        ref: "userOption",
        attrs: { "user-option": _vm.userOption },
        on: { submit: _vm.handleScopeSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }