<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t(`cip.plat.message.sendRecord.title.addHeadTitle`)"
      @head-cancel="headCancel"
      @head-save="headSave()"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>


import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {send} from "@/api/message/sendInfo";


export default {
  name: "msgSendInfo",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {}
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push(
        {
          label: this.$t(`cip.cmn.btn.saveBtn`),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: 'save',
        }
      );
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let this_ = this;
      return [
        {
          label: this.$t('cip.plat.message.sendRecord.field.messageTemplateId'),
          labelWidth: 130,
          prop: "messageTemplateId",
          span: 8,
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.templateCode'),
          labelWidth: 130,
          prop: "messageTemplateCode",
          span: 8,
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.msgForm'),
          labelWidth: 130,
          type: 'select',
          prop: "msgForm",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=message_form`,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.msgForm`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.titleParam'),
          labelWidth: 130,
          prop: "titleParam",
          span: 24,
          type: 'textarea',
          row: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.titleParam`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.contentParam'),
          labelWidth: 130,
          prop: "contentParam",
          span: 24,
          type: 'textarea',
          row: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.contentParam`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.sendParam'),
          labelWidth: 130,
          prop: "sendParams",
          span: 24,
          type: 'textarea',
          row: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.sendParam`)
        },
        {
          label: this.$t('cip.plat.message.sendRecord.field.receiveParam'),
          labelWidth: 130,
          prop: "receiveParams",
          span: 24,
          type: 'textarea',
          row: true,
          placeholder: this.$t(`cip.plat.message.sendRecord.field.receiveParam`)
        }
      ]
    },
  },
  watch: {},
  created() {
    let {id, pageType, node, templateId} = this.$route.query;
    this.nodeData = node;
    this.type = pageType;
    this.initData()
  },
  mounted() {
  },
  methods: {
    initData() {
    },
    headSave() {
      const params = this.$refs.formLayout.dataForm, messageParam = {}, sendParam = {}, receiveParam = {};
      params.code = "send";
      if (params.titleParam) {
        messageParam.titleParam = eval('(' + params.titleParam + ')');
      }
      if (params.contentParam) {
        messageParam.contentParam = eval('(' + params.contentParam + ')');
      }
      if (params.sendParams) {
        messageParam.sendParam = eval('(' + params.sendParams + ')');
      }
      if (params.receiveParams) {
        messageParam.receiveParam = eval('(' + params.receiveParams + ')');
      }
      messageParam.msgForm = params.msgForm;
      params.messageParam = messageParam;
      send(this.$refs.formLayout.dataForm).then(res => {
        const {msg, data} = res.data;
        if (res.data.code == 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        } else {
          this.$message.success(msg);
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
