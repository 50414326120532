<template>
  <el-dialog ref="wf-dialog"
             custom-class="wf-dialog"
             :visible.sync="visible"
             title="表单搜索条件"
             width="60%"
             append-to-body
             v-dialogdrag>
    <avue-form v-model="form"
               :option="option"
               @submit="handleSubmit"></avue-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'wf-search',
  props: {
    value: Array,
    default: () => {
      return []
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          const arr = []
          this.deepClone(val).split(',').forEach(s => {
            const row = s.split(":")
            arr.push({
              column: row[0],
              condition: row[1],
              value: row[2]
            })
          })
          this.form.search = arr
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      visible: false,
      form: {},
      option: {
        border: true,
        submitText: '确定',
        column: [
          {
            label: '',
            labelWidth: 0,
            span: 24,
            prop: 'search',
            type: 'dynamic',
            children: {
              align: 'center',
              column: [
                {
                  label: '字段',
                  prop: 'column'
                },
                {
                  label: '条件',
                  prop: 'condition',
                  type: 'select',
                  dicData: [{
                    label: '等于',
                    value: 'equal'
                  }, {
                    label: '不等于',
                    value: 'notEqual'
                  }, {
                    label: '包含',
                    value: 'like'
                  }, {
                    label: '存在',
                    value: 'exists'
                  }, {
                    label: '不存在',
                    value: 'notExists'
                  }, {
                    label: '大于',
                    value: 'greaterThan'
                  }, {
                    label: '小于',
                    value: 'lessThan'
                  }, {
                    label: '大于等于',
                    value: 'greaterThanOrEqual'
                  }, {
                    label: '小于等于',
                    value: 'lessThanOrEqual'
                  }],
                  placeholder: '条件，存在/不存在无需填值',
                  change: ({value, row}) => {
                    if (['exists', 'notExists'].includes(value)) row.value = ''
                  }
                },
                {
                  label: '值',
                  prop: 'value'
                }
              ]
            }
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit(form, done) {
      const { search } = form
      if (search && search.length > 0) {
        const arr = []
        search.forEach(s => {
          const { column, condition, value } = s
          if (column && (['exists', 'notExists'].includes(condition) || (condition && value))) arr.push(`${column}:${condition}:${value}`)
        })
        this.$emit('input', arr.join(","))
      }
      this.visible = false
      done()
    }
  }
}
</script>

<style lang="scss">
.wf-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  .el-dialog__body {
    flex: 1;
    overflow: auto;
  }
}
</style>