<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="应急组织" name="emergencyOrganization">
        <emergencyOrganization
          ref="emergencyOrganization"
          @uploadImgSuccess="uploadImgSuccess"
        ></emergencyOrganization>
      </el-tab-pane>
      <!-- <el-tab-pane label="处置程序" name="disposalProcedures">
        <disposalProcedures ref="disposalProcedures"></disposalProcedures>
      </el-tab-pane>
      <el-tab-pane label="处置措施" name="disposalMeasures">
        <disposalMeasures ref="disposalMeasures"></disposalMeasures>
      </el-tab-pane> -->
      <el-tab-pane label="应急物资" name="emergencySupplies">
        <emergencySupplies ref="emergencySupplies"></emergencySupplies>
      </el-tab-pane>
      <el-tab-pane
        label="执行结果"
        name="executionResults"
        v-if="erStatus != 'PREPARE'"
      >
        <executionResults ref="executionResults"></executionResults>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import emergencyOrganization from "./emergencyOrganization.vue";
import disposalProcedures from "./disposalProcedures.vue";
import disposalMeasures from "./disposalMeasures.vue";
import emergencySupplies from "./emergencySupplies.vue";
import executionResults from "./executionResults.vue";
export default {
  components: {
    emergencyOrganization,
    disposalProcedures,
    disposalMeasures,
    emergencySupplies,
    executionResults,
  },
  data() {
    return {
      activeName: "emergencyOrganization",
      exeId: "",
      pageDisabled: false,
      erStatus: "PREPARE", // 默认草稿
      orgImgUrl: "", //默认空
    };
  },
  methods: {
    // 初始化
    init(id, pageDisabled, erStatus, orgImgUrl) {
      this.exeId = id;
      this.pageDisabled = pageDisabled;
      this.erStatus = erStatus;
      this.orgImgUrl = orgImgUrl;
      this.$refs.emergencyOrganization.init(
        this.exeId,
        pageDisabled,
        erStatus,
        orgImgUrl
      );
      // this.$refs.disposalProcedures.init(this.exeId, pageDisabled, erStatus);
      // this.$refs.disposalMeasures.init(this.exeId, pageDisabled, erStatus);
      this.$refs.emergencySupplies.init(this.exeId, pageDisabled, erStatus);
      if (erStatus != "PREPARE" && this.$refs.executionResults) {
        this.$refs.executionResults.init(this.exeId, pageDisabled, erStatus);
      }
    },
    handleClick(tab, event) {
      this.$refs[this.activeName].init(
        this.exeId,
        this.pageDisabled,
        this.erStatus,
        this.orgImgUrl
      );
    },
    uploadImgSuccess(url) {
      this.orgImgUrl = url;
    },
  },
};
</script>