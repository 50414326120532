var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "wf-dialog",
      attrs: {
        "custom-class": "wf-dialog",
        visible: _vm.visible,
        title: _vm.$t(
          "cip.plat.workflow.components.userOption.title.indexHeadTitle"
        ),
        width: "60%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.visible
        ? _c(
            "el-table",
            {
              staticClass: "avue-crud",
              attrs: { data: _vm.data, border: "", size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "header-align": "center",
                  width: "80",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("el-button", {
                            attrs: {
                              circle: "",
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-plus",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.data.push({})
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function ({ $index }) {
                        return [
                          _c("el-button", {
                            attrs: {
                              circle: "",
                              type: "danger",
                              size: "mini",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.data.splice($index, 1)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1747691334
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "cip.plat.workflow.components.userOption.field.type"
                  ),
                  prop: "type",
                  align: "center",
                  "header-align": "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row, $index }) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "mini",
                                placeholder: _vm.$t(
                                  "cip.plat.workflow.components.userOption.field.type"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleTypeChange($index)
                                },
                              },
                              model: {
                                value: row.type,
                                callback: function ($$v) {
                                  _vm.$set(row, "type", $$v)
                                },
                                expression: "row.type",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  disabled: Boolean(
                                    _vm.data.find((d) => d.type == item.value)
                                  ),
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2852318465
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "cip.plat.workflow.components.userOption.field.value"
                  ),
                  prop: "text",
                  align: "center",
                  "header-align": "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row, $index }) {
                        return [
                          row.type == "user"
                            ? [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    placeholder: _vm.$t(
                                      "cip.plat.workflow.components.userOption.field.user"
                                    ),
                                    readonly: "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSelect(
                                        $index,
                                        "user-select"
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("el-button", {
                                              attrs: { icon: "el-icon-plus" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: row.text,
                                    callback: function ($$v) {
                                      _vm.$set(row, "text", $$v)
                                    },
                                    expression: "row.text",
                                  },
                                }),
                              ]
                            : row.type == "role"
                            ? [
                                _c("avue-input-tree", {
                                  ref: `role_${$index}`,
                                  attrs: {
                                    size: "mini",
                                    dataType: "string",
                                    multiple: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "cip.plat.workflow.components.userOption.field.role"
                                    ),
                                    dic: _vm.roleList,
                                    props: _vm.roleProps || {
                                      label: "roleName",
                                      value: "id",
                                    },
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        $index,
                                        `role_${$index}`
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            : row.type == "dept"
                            ? [
                                _c("avue-input-tree", {
                                  ref: `dept_${$index}`,
                                  attrs: {
                                    size: "mini",
                                    dataType: "string",
                                    multiple: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "cip.plat.workflow.components.userOption.field.dept"
                                    ),
                                    dic: _vm.deptList,
                                    props: _vm.deptProps || {
                                      label: "deptName",
                                      value: "id",
                                    },
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        $index,
                                        `dept_${$index}`
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            : row.type == "post"
                            ? [
                                _c("avue-input-tree", {
                                  ref: `post_${$index}`,
                                  attrs: {
                                    size: "mini",
                                    dataType: "string",
                                    multiple: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "cip.plat.workflow.components.userOption.field.post"
                                    ),
                                    dic: _vm.postList,
                                    props: _vm.postProps || {
                                      label: "postName",
                                      value: "id",
                                    },
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        $index,
                                        `post_${$index}`
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            : row.type == "platform"
                            ? [
                                _c("avue-input-tree", {
                                  ref: `platform_${$index}`,
                                  attrs: {
                                    size: "mini",
                                    dataType: "string",
                                    multiple: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "cip.plat.workflow.components.userOption.field.platform"
                                    ),
                                    dic: _vm.platformList,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        $index,
                                        `platform_${$index}`
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3873193055
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("user-select", {
        ref: "user-select",
        attrs: {
          "check-type": "checkbox",
          "user-url": _vm.userUrl,
          "custom-option": _vm.customOption,
          "default-checked": _vm.defaultChecked,
        },
        on: { onConfirm: _vm.handleSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }