var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("tree-table-dialog", {
        ref: "tableDialog",
        attrs: {
          "row-key": "key",
          disabledCode: "title",
          "disabled-list": _vm.disabledList,
          "tree-key": "id",
          title: _vm.dialogTitle,
          column: _vm.column,
          "single-selection": _vm.singleSelection,
          "search-columns": _vm.searchColumns,
          "select-page": _vm.selectPage,
          tree: _vm.tree,
          "tree-props": _vm.treeProps,
          "tree-query": _vm.treeQuery,
          selectable: _vm.selectable,
          "reserve-selection": _vm.reserveSelection,
        },
        on: { "close-dialog": _vm.closeDialog, "select-data": _vm.selectData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }